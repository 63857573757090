import { useEffect } from 'react';
import { parse } from 'path-to-regexp';
import { mainStore } from '../stores/MainStore';
import { useRouteMatch } from 'react-router-dom';

const usePageAnalytics = () => {
  const match = useRouteMatch();
  useEffect(() => {
    const { 0: pagePath } = parse(match.path);

    if (typeof pagePath !== 'string') {
      return;
    }

    mainStore.sendToRN('firebaseAnalytics', {
      name: 'screen_view',
      params: {
        source: pagePath,
        url: match.url,
      },
    });
  }, [match.url]);
};

export default usePageAnalytics;
