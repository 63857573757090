import { observer } from 'mobx-react-lite';
import { REQUEST_SMS_TIMER } from '../constants';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { SmsRequestTimerProps } from '../interface';

const SmsRequestTimer = observer(({ onTimerEnd, timeoutThreshold = REQUEST_SMS_TIMER }: SmsRequestTimerProps) => {
  const { t } = useTranslation();
  const [seconds, setSeconds] = useState(timeoutThreshold);

  useEffect(() => {
    if (seconds <= 0) return;
    const timeout = setTimeout(() => {
      const secondsLeft = seconds - 1;
      if (secondsLeft <= 0) {
        onTimerEnd();
        return;
      }
      setSeconds(secondsLeft);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [seconds, onTimerEnd]);

  return t('authPage:canRequestIn', { sec: seconds.toString() });
});

export default SmsRequestTimer;
