import { Environment } from '../../stores/MainStore';

const courierTrackingUrl = window.location.origin.replace('frontend', 'frontend-courier-tracking');

export const COURIER_TRACKING_URL: Record<Environment, string> = {
  development: courierTrackingUrl,
  staging: courierTrackingUrl,
  stress: 'https://courier-tracking-stress.jiffy-team.com/',
  production: process.env.REACT_APP_API_ROOT ? process.env.REACT_APP_API_ROOT.replace(
    'api2.', 'courier-tracking.') : courierTrackingUrl,
};
