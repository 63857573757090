import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { BadgesPlateProps } from '../interfaces';
import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';

export default observer((props: BadgesPlateProps): ReactElement => {
  const { onClear, onBadgeClick, source, title, badges, badgesColors = [] } = props;

  const { t } = useTranslation();

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="fs-16 lh-24">{title}</div>
        {onClear && (
          <div className="fs-14 ml-14 c-blue" onClick={onClear}>
            {t('clear')}
          </div>
        )}

      </div>
      <div className="mx-n6 mb-20 d-flex flex-wrap">
        {badges.map((item, i) => {
          const colorClass = badgesColors[i];

          return (
            <div
              className={htmlClasses(
                `px-12 lh-30 mt-12 mx-6 br-8  badge-search fs-14 text-nowrap text-truncate overflow-hidden w-max-100p`,
                colorClass ? colorClass : 'c-bg-bg',
              )}
              onClick={() => onBadgeClick(item, source)}
              key={`${item}_${i}`}
            >
              {item}
            </div>
          );
        })}
      </div>
    </>
  );
});
