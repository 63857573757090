import { RequestAPI } from './Requests';
import { mainStore } from '../stores/MainStore';
import { Address } from './DeliveryAddress';
import { ENDPOINT } from './constants';

export interface SubscriptionFlag {
  name: 'personalised' | 'discounts' | 'third_party';
  email?: boolean;
  push?: boolean;
}

export interface UpdatePersonalFields {
  first_name?: string | null;
  middle_name?: string | null;
  last_name?: string | null;
  phone?: string;
  date_of_birth?: string | null;
  email?: string | null;
  age_restriction?: boolean;
  app_rating?: boolean;
}

interface PersonalAddress {
  street_address_1: string;
  street_address_2: string;
  city: string;
  postcode: string;
  customer_id: string;
  country: string;
  place_id: string;
  latitude: number;
  longitude: number;
  id: string;
}

export interface RequestCodeResponse {
  body: string;
  isDeleted?: boolean;
  to: string;
  err: null;
}

export interface VerifyCodeResponse {
  customer: Omit<Customer, 'address' | 'subscription' | 'customer_type'>;
  addresses: Address[];
  verified: boolean;
  access_token: string;
  refresh_token: string;
}

interface UpdatePersonalDataResponse {
  customer?: Omit<Customer, 'app_rating' | 'type'>[];
}

interface CustomerType {
  code: 'User' | 'Staff';
  created_at: string;
  description: string;
  id: number;
  name: 'User' | 'Staff';
  privileges: Record<string, unknown>;
  updated_at: string;
}

export interface Customer {
  address: PersonalAddress[];
  date_of_birth: string | null;
  default_address_id: string | null;
  email: string | null;
  first_name: string | null;
  id: string;
  last_login: string | null;
  last_name: string | null;
  middle_name: string | null;
  phone: string | null;
  subscription: Subscription[];
  age_restriction: boolean | null;
  app_rating: boolean;
  freeDeliveryDaysLeft: number;
  type: CustomerType | null;
  customer_type: CustomerType | null;
}

interface Subscription {
  channel_data: null;
  created_at: string;
  customer_id: string;
  deleted_at: string | null;
  device_id: string;
  id: string;
  updated_at: string;
  subscription_flags: SubscriptionFlag[];
}

interface Device {
  created_at: string;
  customer_id: string;
  deleted_at: string | null;
  email: string | null;
  email_player_id: string | null;
  id: string;
  last_session: string;
  name: string | null;
  player_id: string | null;
  push_token: string | null;
  updated_at: string;
  subscriptions: Subscription | null;
}

export interface BonusesTransaction {
  id: number;
  customer_id: string;
  order_id: string;
  reason: 'order_delivered' | 'applied_to_basket' | 'refund' | 'promo';
  amount: number;
  created_at: string;
  updated_at: string;
  type: 'charge' | 'add';
}

interface CustomerRequestsInterface {
  requestCode(data: {
    phone_number: string,
    device_id: string,
    recaptcha_token: string
  }): Promise<RequestCodeResponse>;

  verifyCode(data: {
    phone_number: string;
    otp: string;
    device_id: string;
    restore?: boolean;
  }): Promise<VerifyCodeResponse>;

  logout(data: { refresh_token: string; customer_id: string }): Promise<void>;

  deleteProfile(): Promise<void>;

  getPersonalData(customerID: string): Promise<Omit<Customer, 'app_rating' | 'type'>[]>;

  updatePersonalData(
    customerID: string,
    data: UpdatePersonalFields,
  ): Promise<UpdatePersonalDataResponse>;

  updateDeviceData(
    deviceID: string,
    data: {
      name: string;
      email: string;
      subscription_flags: SubscriptionFlag[];
    },
  ): Promise<{ device: Device }>;

  updateSubscription(
    deviceID: string,
    data: {
      subscription_flags: SubscriptionFlag[];
    },
  ): Promise<{ subscription: Subscription }>;

  refreshToken(data: {
    refresh_token: string;
    customer_id: string;
  }): Promise<{ access_token: string; refresh_token: string; }>;

  requestBonuses(): Promise<number>;

  requestBonusesTransactions(data: {
    'pagination[page]': number;
    'pagination[size]': number;
  }): Promise<{ items: BonusesTransaction[], pagination: { total: number } }>;
}

export const CustomerRequests: CustomerRequestsInterface = {
  requestCode: (data) =>
    RequestAPI.get(ENDPOINT.customer.code.request, data),

  verifyCode: (data) =>
    RequestAPI.post(ENDPOINT.customer.code.verify + mainStore.convertObjToGet(data)),

  logout: (data) =>
    RequestAPI.post(ENDPOINT.customer.logout, data),

  deleteProfile: () =>
    RequestAPI.delete(ENDPOINT.customer.personalData.delete),

  getPersonalData: (customerID) =>
    RequestAPI.get(ENDPOINT.customer.personalData.request + customerID),

  updatePersonalData: (customerID, data) =>
    RequestAPI.post(ENDPOINT.customer.personalData.update + customerID, data),

  updateDeviceData: (deviceID, data) =>
    RequestAPI.post(ENDPOINT.customer.deviceData.update + deviceID, data),

  updateSubscription: (deviceID, data) =>
    RequestAPI.post(ENDPOINT.customer.subscription.update + deviceID, data),

  refreshToken: (data) =>
    RequestAPI.refresh(ENDPOINT.customer.token.refresh, data),

  requestBonuses: () =>
    RequestAPI.get(ENDPOINT.bonuses.balance),

  requestBonusesTransactions: (data) =>
    RequestAPI.get(ENDPOINT.bonuses.history, data),
};
