import { observer } from 'mobx-react-lite';
import { useEffect, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { mainStore } from '../stores/MainStore';

type ScrollRestorationProps = {
  pageName: string;
  layoutClass?: string;
};

export default observer(({ pageName, layoutClass = 'scroll-layout' }: ScrollRestorationProps) => {
  const history = useHistory();
  const { state } = useLocation<{ isBackwarding?: boolean }>();

  useEffect(() => {
    const elLayout = document.querySelector<HTMLElement>('.' + layoutClass);
    if (!elLayout) return;
    if (history.action === 'POP' || state?.isBackwarding)
      elLayout.scrollTop = mainStore.pageScrollY[pageName] ?? 0;
    else elLayout.scrollTop = 0;
    mainStore.setPageScrollY(pageName, 0);
  }, [pageName, history.action, layoutClass, state?.isBackwarding]);

  useLayoutEffect(() => {
    return () =>
      mainStore.setPageScrollY(pageName, document.querySelector('.' + layoutClass)?.scrollTop || 0);
  }, [pageName, layoutClass]);

  return <></>;
});
