export enum ZonesColor {
  open = '#ff4314',
  openedSoon = '#c849f2',
  closed = '#82869e',
}

export const DELIVERY_ZONES_STYLE: Record<number, Record<'fillOpacity' | 'strokeOpacity', number>> = {
  1: {
    fillOpacity: 0.10,
    strokeOpacity: 0.45,
  },
  2: {
    fillOpacity: 0.15,
    strokeOpacity: 0.72,
  },
  3: {
    fillOpacity: 0.10,
    strokeOpacity: 0.38,
  },
  100: {
    fillOpacity: 0.10,
    strokeOpacity: 0.38,
  },
};

export const MAP_GEOLOCATION_ZOOM = 18;
export const MAP_DEBOUNCE_TIME = 600; // ms
export const RN_ANSWER_TIMEOUT = 15_000; // ms
