import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Popover from './Popover';
import { mainStore } from '../stores/MainStore';
import { orderStore } from '../stores/OrderStore';
import { REMAIN_MINUTES_BEFORE_CLOSE_POPOVER } from '../config';
import { useLocation } from 'react-router-dom';

export default observer(() => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!orderStore.etaCalculation) return;
    const serverTime = orderStore.etaCalculation.meta.serverTime.replace(' ', 'T');
    const closing = orderStore.etaCalculation.warehouse.availability.closing;
    let popoverAlreadyShowed = false;

    if (mainStore.lastDateShowSoonClosePopover) {
      if (
        (+new Date(serverTime) - +new Date(mainStore.lastDateShowSoonClosePopover)) / 1000 / 60 <
        REMAIN_MINUTES_BEFORE_CLOSE_POPOVER
      ) {
        popoverAlreadyShowed = true;
      }
    }

    if (!popoverAlreadyShowed) {
      const closeTime = closing.split(':');
      const curTime = serverTime.split('T')[1].split(':');
      const max = parseInt(closeTime[0], 10) * 60 + parseInt(closeTime[1], 10);
      const cur = parseInt(curTime[0], 10) * 60 + parseInt(curTime[1], 10);
      if (cur < max && max - cur <= REMAIN_MINUTES_BEFORE_CLOSE_POPOVER) {
        mainStore.setIsSoonClosingPopover(true);
        mainStore.setLastDateShowSoonClosePopover(serverTime);
      }
    }
  }, []);

  const handleDismiss = () => {
    mainStore.setIsSoonClosingPopover(false);
  };

  return !pathname.match(/^\/stories/g) ? (
    <Popover isShow={mainStore.isSoonClosingPopover} onBackdropDismiss={handleDismiss}>
      <div className="  fs-21 c-black">
        {t('shopClosedPopover:closeInMinutes', { time: REMAIN_MINUTES_BEFORE_CLOSE_POPOVER })}
      </div>
      <div className="fs-16 lh-22 mt-4">
        {t('shopClosedPopover:grabYourGroceries')}
      </div>
      <div className="d-flex align-items-center mt-24">
        <div
          className="button _primary w-100p fs-16 lh-22 "
          onClick={handleDismiss}
        >
          {t('shopClosedPopover:closingTime')}
        </div>
      </div>
    </Popover>
  ) : <></>;
});
