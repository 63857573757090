import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ProductItem from '../components/ProductItem';
import ScrollRestoration from '../components/ScrollRestoration';
import { SyncSubcategory, scrollTo } from '../UI';
import { mainStore } from '../stores/MainStore';
import { catalogStore } from '../stores/CatalogStore';
import htmlClasses from 'html-classes';
import { Category } from '../types/Category/interface';

type SubcategoryListProps = {
  subcategoryList: Category[];
};

type ProductGroupProps = {
  subcategoryList: Category[];
  source: string;
};

const SubcategoryList = observer(({ subcategoryList }: SubcategoryListProps) => {
  const sendAnalytic = (catId: number, source: string) => {
    mainStore.sendToRN('analytics', {
      name: 'Catalog: category clicked',
      params: {
        category: catId,
        source: source,
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'catalog_category_clicked',
      params: {
        category: catId,
        source: source,
      },
    });
  };

  return (
    <>
      {subcategoryList.map((item) => (
        <div
          className='badge _subcategory'
          key={item.id}
          data-id={item.id}
          onClick={() => sendAnalytic(item.id, 'category')}
        >
          {item.name}
        </div>
      ))}
    </>
  );
});

const ProductGroup = observer(
  ({ subcategoryList, source }: ProductGroupProps) => {
    const { t } = useTranslation();
    const handleShowNotFindProductPopover = () => mainStore.setIsNotFindProductPopover(true);
    const handleScrollTop = () => scrollTo('scroll-layout', 0, 200);

    return (
      <>
        {subcategoryList.map((item) => (
          <div className='product-list' data-id={item.id} key={item.id}>
            <div className='product-list__header d-flex align-items-end justify-content-between c-bg-white pb-12'>
              <div className='fw-500 fw-possibly-bold'>
                {item.name}
                <span className='c-tc7 mis-8 flex-shrink-0'>{item.products.length || 0}</span>
              </div>
            </div>
            <div className='d-flex mx-n4 flex-wrap'>
              {item.products.map((product) => (
                <div className='w-50p px-4 pb-8 flex-shrink-0' key={product.id}>
                  <ProductItem product={product} source={source} />
                </div>
              ))}
            </div>
          </div>
        ))}
        {subcategoryList.length > 0 && (
          <>
            <div
              className='button _bordered w-100p mt-10'
              onClick={handleShowNotFindProductPopover}
            >
              {t('categoryPage:canNotFindItems')}
            </div>
            <div className='button _bordered w-100p mt-10' onClick={handleScrollTop}>
              {t('backToTop')}
            </div>
          </>
        )}
      </>
    );
  },
);

export default observer(() => {
  const history = useHistory();
  const { t } = useTranslation();
  const { state } =
    useLocation<{ subCategories: Category[]; categoryName: string; categoryId: string }>();
  const refSubcategyList = useRef<HTMLDivElement>(null);
  const refScrollLayout = useRef<HTMLDivElement>(null);
  const [categoryName, setCategoryName] = useState('');
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const handleSearchLink = () => {
    catalogStore.setSearchQuery('');
    mainStore.sendToRN('analytics', {
      name: 'Catalog: search clicked',
      params: {
        source: 'category',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'catalog_search_clicked',
      params: {
        source: 'category',
      },
    });
  };

  useEffect(() => {
    setSubCategories(state.subCategories || []);
    setCategoryName(state.categoryName || t('discounts'));
    mainStore.sendToRN('analytics', {
      name: 'Catalog: Discounts visited',
      params: {
        category: state.categoryId,
        source: 'category',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'catalog_discounts_visited',
      params: {
        category: state.categoryId,
        source: 'category',
      },
    });
    //eslint-disable-next-line
  }, [state.subCategories]);

  useEffect(() => {
    if (!subCategories.length) return;
    const syncSubcategory = new SyncSubcategory(refScrollLayout.current, refSubcategyList.current);
    return () => syncSubcategory.destroy();
    //eslint-disable-next-line
  }, [subCategories]);

  return (
    <>
      {subCategories.length && <ScrollRestoration pageName='discounts' />}
      <div className='d-flex h-50 flex-shrink-0 align-items-center'>
        <div
          className='icon icon-rtl icon-arrow-back d-flex flex-center s-50 fs-20 c-black'
          onClick={() => (history.length === 1 ? history.push('/') : history.goBack())}
        />
        <h3 className='text-center w-100p text-truncate'>{categoryName}</h3>
        <Link
          className='icon icon-rtl icon-search d-flex flex-center s-50 fs-20'
          to={{ pathname: '/search', state: { source: 'category' } }}
          onClick={handleSearchLink}
        />
      </div>
      <div
        className='text-nowrap overflow-auto px-16 py-12 flex-shrink-0 hide-scroll-bar'
        ref={refSubcategyList}
      >
        <SubcategoryList subcategoryList={subCategories} />
      </div>
      <div
        className={htmlClasses('scroll-layout h-100p px-24', {
          'overflow-hidden': !subCategories.length,
        })}
        ref={refScrollLayout}
      >
        <ProductGroup
          subcategoryList={subCategories}
          source='discounts_slider'
        />
        <div className='h-24' />
      </div>
    </>
  );
});
