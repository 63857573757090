import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import ExternalLink from '../../../components/ExternalLink';
import React from 'react';
import { company } from '../../../company/Company';

const TermsAndConditions = observer(() => {
  const { t } = useTranslation();

  return (
    <>
      <div className='mt-35 text-center fs-12 lh-16 c-dgray w-max-310 mx-auto'>
        <Trans i18nKey='authPage:terms'>
          By continuing, you accept our
          <ExternalLink
            className='c-dgray text-underline'
            to={{ pathname: '/inject', state: { page: 'terms' } }}
            href={company.config.links.terms}
          >
            Terms and Conditions
          </ExternalLink>
          and
          <ExternalLink
            className='c-dgray text-underline'
            to={{ pathname: '/inject', state: { page: 'privacy' } }}
            href={company.config.links.privacy}
          >
            Privacy policy
          </ExternalLink>
          .
        </Trans>
      </div>

      <div className='text-center fs-12 lh-16 c-dgray w-max-310 mx-auto mt-5'>
        {t('authPage:personalData')}{' '}
        <Trans i18nKey='authPage:moreInfo'>
          For more information
          <ExternalLink
            className='c-dgray text-underline'
            to={{ pathname: '/inject', state: { page: 'privacy' } }}
            href={company.config.links.privacy}
          >
            see here
          </ExternalLink>
          .
        </Trans>
        <p>
          <Trans i18nKey='authPage:protectedByReCAPTCHA'>
            <ExternalLink
              className='c-dgray text-underline'
              href='https://www.google.com/intl/en/policies/privacy/'
            >
              privacy
            </ExternalLink>

            <ExternalLink
              className='c-dgray text-underline'
              href='https://www.google.com/intl/en/policies/terms/'
            >
              terms
            </ExternalLink>
          </Trans>
        </p>
      </div>
    </>
  );
});

export default TermsAndConditions;
