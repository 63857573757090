/**
 * @prettier
 */

import { RequestAPI } from './Requests';
import { ENDPOINT } from './constants';

export const GeocoderRequests = {
  reverseGeocode: ({ lat, lng }: GeoCoordinates) =>
    RequestAPI.get(ENDPOINT.geocoder.reverseGeocode, { lat, long: lng }),
};
