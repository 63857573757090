import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

type FCButtonProps = {
  title?: string;
  className?: string;
};

export default observer(({ title = 'FreshChat', className }: FCButtonProps) => {
  const handleOpenFC = () => {
    window.fcWidget?.show();
    window.fcWidget?.open();
  };

  useEffect(() => {
    return () => {
      window.fcWidget?.hide();
      window.fcWidget?.close();
    };
  }, []);

  return (
    <div className={className} onClick={handleOpenFC}>
      {title}
    </div>
  );
});
