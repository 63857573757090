import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Popover from './Popover';
import { mainStore } from '../stores/MainStore';
import { orderStore } from '../stores/OrderStore';

export default observer(() => {
  const { t } = useTranslation();
  const handleDismiss = () => {
    mainStore.setIsShowGiftOutStockPopover(false);
    orderStore.setActiveGift(null);
  };

  return (
    <Popover isShow={mainStore.isShowGiftOutStockPopover} onBackdropDismiss={handleDismiss}>
      <div className="fs-21 fw-500">{t('cartPage:noMoreGift')}</div>
      <div className="cart-item d-flex py-16 overflow-hidden position-relative c-bg-tf7 px-24 mt-20 mx-n24">
        <div
          className="w-44 flex-shrink-0 img-contain position-relative"
          style={{
            backgroundImage:
              orderStore.activeGift?.preview_image_thumb || orderStore.activeGift?.preview_image
                ? `url(${
                  orderStore.activeGift.preview_image_thumb || orderStore.activeGift.preview_image
                })`
                : 'none',
          }}
        />
        <div className="px-8 w-100p fs-12 lh-16 align-self-center">
          {orderStore.activeGift?.name || ''}
        </div>
      </div>
      <div className="button _primary w-100p mt-32" onClick={handleDismiss}>
        {t('understood')}
      </div>
    </Popover>
  );
});
