import { Customer } from './Customer';
import { RequestAPI } from './Requests';
import { ENDPOINT } from './constants';
import { ETADeliveryMethodType, ETAPaymentMethod } from './ETA';

export type NewOrderCartItem = Omit<OrderCartItem,
  'id' | 'order_id' | 'created_at' | 'updated_at' | 'status' | 'actual_quantity'
>;

export type NewOrderCartItemBundle = {
  id: number,
  requested_quantity: number,
};

export type OrderCartItemStatus =
  | 'ordered'
  | 'reserved'
  | 'reserved_partially'
  | 'picked_partially'
  | 'missing'
  | 'picked'
  | 'out_of_stock';

export type OrderPublicStatus =
  | 'accepted'
  | 'picking'
  | 'ready_to_ship'
  | 'ready_for_pickup'
  | 'in_delivery'
  | 'delivered'
  | 'failed'
  | 'cancelled';

export type OrderStatus =
  | 'created'
  | 'reserved'
  | 'confirmed'
  | 'delivery_created'
  | 'wh_created'
  | 'picking'
  | 'ready_to_ship'
  | 'in_delivery'
  | 'delivered'
  | 'delivered_partially'
  | 'finished'
  | 'cancelled'
  | 'delivery_failed';

export type PaymentSystem = 'stripe' | 'myfatoorah';

export interface OrderCartItem {
  id: string;
  order_id: string;
  created_at: string;
  updated_at: string;
  status: OrderCartItemStatus;
  sku: string;
  requested_quantity: number;
  actual_quantity: number | null;
  weight: number;
  base_price: number;
  discount_amount: number;
  paid_price: number;
  product_id: string;
  name: string;
  image: string;
  price_per_unit: string;
  promocode_discount_amount: number;
  paid_bonuses: number;
  promo_required_quantity?: number | null;
  promo_quantity_discount_price?: number | null;
  bundle_id?: string | null;
}

export interface OrderCartBundleItem {
  id: string,
  order_id: string,
  created_at: string,
  catalog_bundle_id: string,
  name: string,
  image: string,
  quantity: number;
  company_id: string,
  price: number;
}

export interface OrderAddress {
  id: string;
  order_id: string;
  city: string;
  country_id: string;
  region: string;
  address_1: string | null;
  address_2: string | null;
  latitude: number | null;
  longitude: number | null;
  notes: string | null;
  instructions: string[] | null;
  comment: string | null;
  zip: string;
}

interface OrderSla {
  start_after: string;
  ship_by: string;
  deliver_by_min: string;
  deliver_by_max: string;
}

interface Recipient {
  id: string;
  order_id: string;
  full_name: string;
  email: string;
  phone: string;
}

interface OrderDelivery {
  tracking?: {
    pickup?: string | null;
    dropoff?: string | null;
  };
}

interface OrderPicking {
  id: string;
  picker_id: null;
  oms_order_id: string;
  transfer_id: number;
  transfer_type: string;
  odoo_request_id: string;
  warehouse_code: string;
  sla_start_after: string;
  sla_ship_by: string;
  startedAt: null;
  completedAt: null;
  status: string;
  createdAt: string;
  updatedAt: string;
  picker: null;
}

interface OrderPayment {
  order_id: string;
  status: string;
  created_at: string;
}

export interface OrderShort {
  id: string;
  status: OrderStatus;
  created_at: string;
  updated_at: string;
  customer_id: string;
  delivery_id: null;
  payment_id: null;
  order_id: string;
  is_imported: boolean;
  warehouse_code: string;
  currency: string;
  warehouse_id: string;
  has_feedback: boolean;
  delivery_state: null;
  items: OrderCartItem[];
  bundles: OrderCartBundleItem[];
  receipt_url: string | null;
  address: OrderAddress | null;
  recipient: Recipient | null;
  public_status: OrderPublicStatus;
  sla: OrderSla;
  promocode: string | null;
  base_total: number | null;
  promocode_discount: number | null;
  discount_total: number | null;
  paid_total: number | null;
  delivery_price: number | null;
  is_delivery_free: boolean | null;
  save_card: boolean;
  service_fee: number;
  cancel_reason: 'CANCEL_BY_USER' | null;
  delivery_method: ETADeliveryMethodType;
  distance_to_warehouse: number;
  warehouse_location_latitude: number;
  warehouse_location_longitude: number;
  received_bonuses: number;
  payment_method: ETAPaymentMethod;
  tax_percent?: number,
  tax_amount?: number,

  itemsCount: {
    actual: number;
    requested: number;
  };
}

export interface Order extends OrderShort {
  delivery: OrderDelivery;
  picking: OrderPicking;
  customer: Customer[];
  payment: OrderPayment;
  finishedMoreThanDayAgo: boolean;
  warehouse_address: string;
}

export interface Payment {
  card: {
    brand: string;
    checks: {
      address_line1_check: 'pass' | null;
      address_postal_code_check: 'pass' | null;
      cvc_check: 'pass' | null;
    };
    country: string;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    last4: string;
  };
  id: string;
  metadata: {
    is_default: string;
  };
}

export interface GiftMechanism {
  id: string;
  cms_id: number;
  name: string;
  minimum_order_amount: number;
  gift_items: GiftItem[];
}

export interface GiftItem {
  id: number;
  name: string;
  code: string;
  price?: number;
  preview_image: string;
  preview_image_thumb: string;
}

export interface FillFeedbackRequest {
  rating: number;
  delivery_rating: number;
  product_variety_rating: number;
  product_quality_rating: number;
  comment: string;
}

export interface NewOrderResponse {
  errors?: boolean | {
    message?: string;
    payload?: {
      sku: string;
      warehouse_code: string;
    };
  }[];
  data?: {
    payload: {
      err_code: string;
    };
  };
  order?: {
    id: string;
    order_id: number;
    partner_order_id: null;
    payment_method: ETAPaymentMethod;
    seller: string;
    duration: {
      min: number;
      max: number;
    }
  };
  payment_intent?: {
    client_secret?: string;
  };
  payment?: {
    id: string;
    myFatoorahPayment: {
      invoiceId?: number;
      paymentURL?: string;
      error?: boolean;
    }
  };
}

export interface OrdersResponse {
  orders: OrderShort[];
  total_count: number;
}

interface OrderResponse {
  order: Order;
}

export interface CheckPromocodeResponse {
  id: number;
  type: 'PERCENTAGE' | 'FIXED' | 'FREE_SHIPPING' | 'BUY_X_GET_Y';
  code: string;
  filters: Unnecessary<Record<string, unknown>>;
  value: number;
  applyToDiscountedItems: boolean;
  minimumPurchase: number;
  minimumQuantity: number;
  usageLimitCustomer: number;
  usageCurrent: number;
  usageLimitAll: number;
  dateRange: [string, string];
  orderNumberRange: [number, number];
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  productDiscountQuantity: number;
  discountTargets: null;
}

interface GiftResponse {
  data?: GiftMechanism;
}

interface CheckPayedTipResponse {
  status?: 'created' | 'received';
  errors?: string[];
}

export type OrderRequestInput = {
  customer_id: string;
  currency: string;
  warehouse_code: string;
  save_card: boolean;
  base_total: number;
  service_fee: number;
  promocode_discount: number;
  discount_total: number;
  paid_total: number;
  paid_bonuses: number;
  delivery_price: number;
  is_delivery_free: boolean;
  promocode: string | null;
  items?: NewOrderCartItem[];
  bundles?: NewOrderCartItemBundle[];
  recipient: Omit<Recipient, 'id' | 'order_id'>;
  address: Omit<OrderAddress, 'id' | 'order_id'>;
  delivery_method: ETADeliveryMethodType;
  gift?: {
    cms_id: number;
    gift_id: number;
  };
  client_source: string;
  should_use_bonuses: boolean;
  tips_amount: number;
  payment_method: ETAPaymentMethod;
  session_id?: string;
  payment_method_id?: number;
}

interface CurrencyResponse {
  data: {
    currency: string;
    digits_after_coma: number;
  };
}

export interface MyfatoorahInit {
  SessionId: string;
  CountryCode: string;
}

export const isOrderCartBundleGuard = (orderCart?: OrderCartItem | OrderCartBundleItem): boolean =>  {
  return Boolean(orderCart && (orderCart as OrderCartBundleItem).catalog_bundle_id !== undefined);
};

interface OrderRequestsInterface {
  newOrder(data: OrderRequestInput, key: string): Promise<NewOrderResponse>;

  orderPaid(orderId: string): Promise<{ status: string }>;

  orderCancel(orderId: string): Promise<{ status: string }>;

  orderIdempotencyCancel(data: { idempotency_key: string }): Promise<{ status: string }>;

  getOrders(data: {
    filter?: {
      customer_id?: string;
      status?: OrderStatus[];
    };
    options?: Record<string, any>;
  }): Promise<OrdersResponse>;

  getOrder(id: string): Promise<OrderResponse>;

  getPayments(): Promise<Payment[]>;

  createIntent(): Promise<Payment>;

  addPayment(data: { payment_method_id: string }): Promise<Payment>;

  deletePayment(data: { payment_method_id: string }): Promise<Payment>;

  setDefaultPayment(data: { payment_method_id: string }): Promise<Payment>;

  newFeedback(data: { rating: number; order_id: string }): Promise<{ id: string }>;

  fillFeedback(feedbackId: string, data: FillFeedbackRequest): Promise<void>;

  checkPromocode(promocode: string): Promise<CheckPromocodeResponse>;

  getGifts(warehouseCode: string): Promise<GiftResponse>;

  payTip(orderId: string, data: {
    amount: number,
    save_card: boolean,
    currency: string,
    email: string
  }): Promise<{ client_secret: string }>;

  checkPayedTip(orderId: string): Promise<CheckPayedTipResponse>;

  getCurrency(): Promise<CurrencyResponse>;

  getPriorityPaymentSystem(): Promise<PaymentSystem>;

  myfatoorahInit(): Promise<MyfatoorahInit>;
}

export const OrderRequests: OrderRequestsInterface = {
  newOrder: (data, key) =>
    RequestAPI.post(ENDPOINT.orders.new, data, {
      headers: {
        'Idempotency-Key': key,
      },
    }),

  orderPaid: (orderId) =>
    RequestAPI.post(ENDPOINT.orders.paid + orderId),

  orderCancel: (orderId) =>
    RequestAPI.post(ENDPOINT.orders.cancelByUser + `/${orderId}`),

  orderIdempotencyCancel: (data) =>
    RequestAPI.post(ENDPOINT.orders.cancelByUser, data),

  getOrders: (data) =>
    RequestAPI.post(ENDPOINT.orders.status, data),

  getOrder: (id) =>
    RequestAPI.get(ENDPOINT.orders.status + `/${id}`),

  getPayments: () =>
    RequestAPI.get(ENDPOINT.orders.paymentMethods.list),

  addPayment: (data) =>
    RequestAPI.post(ENDPOINT.orders.paymentMethods.add, data),

  deletePayment: (data) =>
    RequestAPI.post(ENDPOINT.orders.paymentMethods.remove, data),

  createIntent: () =>
    RequestAPI.post(ENDPOINT.orders.paymentMethods.setupIntent),

  setDefaultPayment: (data) =>
    RequestAPI.post(ENDPOINT.orders.paymentMethods.setDefault, data),

  newFeedback: (data) =>
    RequestAPI.post(ENDPOINT.orders.feedback.create, data),

  fillFeedback: (feedbackId, data) =>
    RequestAPI.post(ENDPOINT.orders.feedback.fill + feedbackId, data),

  checkPromocode: (promocode) =>
    RequestAPI.get(ENDPOINT.promocodes.get + promocode),

  getGifts: (warehouseCode) =>
    RequestAPI.get(ENDPOINT.gifts.getMechanisms + warehouseCode),

  payTip: (orderId, data) =>
    RequestAPI.post(ENDPOINT.tips.pay + orderId, data),

  checkPayedTip: (orderId) =>
    RequestAPI.get(ENDPOINT.tips.check + orderId),

  getCurrency: () =>
    RequestAPI.get(ENDPOINT.orders.getCurrency),

  getPriorityPaymentSystem: () =>
    RequestAPI.get(ENDPOINT.payments.prioritySystem),

  myfatoorahInit: () =>
    RequestAPI.post(ENDPOINT.payments.myfatoorahInitialize),
};
