export const notAuthorizedDetails = [
  {
    firstLine: 'logIn',
    secondLine: 'onlyRegistered',
  },
  {
    firstLine: 'likeCashback',
    secondLine: 'getPointsFor',
  },
  {
    firstLine: 'ofTheBasket',
    secondLine: 'getUpTo',
  },
];

export const authorizedDetails = [
  {
    firstLine: 'shopAsUsual',
    secondLine: 'everySpentWill',
  },
  {
    firstLine: 'getPoints',
    secondLine: 'getPointsFor',
  },
  {
    firstLine: 'saveOnTheNext',
    secondLine: 'saveOnTheNextOrder',
  },
];

export const HAS_BONUSES_DETAILS = [
  {
    firstLine: 'shopForRewards',
    secondLine: 'everySpentWill',
  },
  {
    firstLine: 'getPoints',
    secondLine: 'getPointsFroCollect',
  },
  {
    firstLine: 'saveOnTheNext',
    secondLine: 'coverUpTo',
  },
];
