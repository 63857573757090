import { RequestAPI } from './Requests';
import { ENDPOINT } from './constants';
import { ETADeliveryMethodType } from './ETA';

export interface ProductAPI {
  categoryId: number;
  categoryName: string;
  commodityGroup: { name: ProductCommodityGroupAPI };
  description: string | null;
  discountPrice: Nullable<NumberInt>;
  id: number;
  images: Nullable<ImageAPI[]>;
  isFavourite: boolean;
  name: string;
  offerProperties: Nullable<ProductPropertiesAPI[]>;
  offers: ProductOfferAPI[],
  parentCategoryId: number;
  parentCategoryName: string;
  previewImage: ImageAPI;
  previewImageThumb: ImageAPI;
  price: Nullable<NumberInt>;
  sellable: number;
  productType: string;
}

export interface ProductOfferAPI {
  active: boolean;
  code: string; // SKU
  description: string | null;
  discountPrice: Nullable<NumberInt>;
  id: number;
  images: Nullable<ImageAPI[]>;
  minBasketDiscountPrice: number | null;
  minBasketDiscountQuantity: number | null;
  name: string;
  offerGeneratorProperties: unknown;
  price: Nullable<NumberInt>;
  productId: number;
  properties: ProductPropertiesAPI[];
  sellable: number;
  bundleQuantity?: number;
}

export type ProductPropertiesKeyAPI =
  | 'RRP'
  | 'abv'
  | 'age_restriction'
  | 'alcohol_units'
  | 'allergen_free'
  | 'best_before_text'
  | 'carbohydrates'
  | 'color'
  | 'color_text'
  | 'country_of_origin'
  | 'dairy_free'
  | 'fairtrade'
  | 'fat'
  | 'fat_saturates'
  | 'fibre'
  | 'for_halal'
  | 'for_kosher'
  | 'for_vegan'
  | 'for_vegetarians'
  | 'freezing_suitable'
  | 'gluten_free'
  | 'gmo_free'
  | 'ingredients'
  | 'is_gift'
  | 'is_icecream'
  | 'is_organic_food'
  | 'items_per_outer_retail_pack'
  | 'kcal'
  | 'limited_stock'
  | 'low_fat'
  | 'manufacturer'
  | 'may_contain_nuts'
  | 'no_added_sugar'
  | 'other_nutrition_info'
  | 'other_parameters'
  | 'out_of_circulation'
  | 'own_bakery'
  | 'own_kitchen'
  | 'own_trademark'
  | 'price_lock_tag'
  | 'product_size'
  | 'promo_quantity_discount_price'
  | 'promo_required_quantity'
  | 'proteins'
  | 'safery_allergen'
  | 'salt'
  | 'size'
  | 'storage'
  | 'sugar'
  | 'sugar_free'
  | 'tesco_price_match'
  | 'type_of_packaging'
  | 'uom'
  | 'with_soya'
  | 'with_sulphites'

export type ProductPropertiesTypeAPI =
  | 'checkboxGroup'
  | 'color'
  | 'colorList'
  | 'date'
  | 'dateTime'
  | 'file'
  | 'image'
  | 'number'
  | 'radioGroup'
  | 'selector'
  | 'sizeList'
  | 'tagList'
  | 'text'
  | 'textarea'
  | 'time'
  | 'toggle'

export type ProductPropertiesAPI = {
  additionalPropertyId: Unnecessary<number>;
  choices: Unnecessary;
  code: ProductPropertiesKeyAPI;
  isOfferGenerator: boolean;
  name: Unnecessary<string>;
  type: ProductPropertiesTypeAPI;
  value?: boolean | string | number;
  values: string | string[];
}

export enum ProductCommodityGroupAPI {
  GROCERY = 'GROCERY',
  ALCOHOL = 'ALCOHOL',
  TOBACCO = 'TOBACCO',
  PHARMA = 'PHARMA',
  CHEMICAL = 'CHEMICAL',
  FROZEN = 'FROZEN',
  OWN_KITCHEN = 'OWN_KITCHEN',
  CLOTHING = 'CLOTHING',
  SHOES = 'SHOES',
  ACCESSORIES = 'ACCESSORIES',
}

type ProductImageAPI = { path?: string; }

type ImageAPI = { url: string | null; }

export interface CategoryAPI {
  active: boolean;
  advertisingPlaceImage: Unnecessary<string>;
  backgroundColor: string | null;
  code: Unnecessary<string>;
  createdAt: Unnecessary<string>;
  deepLink: Unnecessary<string>;
  description: string;
  externalId: Unnecessary<string | null>;
  fontColor: string | null;
  id: number;
  images: Unnecessary<ImageAPI[]>;
  name: string;
  parentId: number | null;
  previewImage: ImageAPI;
  previewImageThumb: Unnecessary<string>;
  previewText: Unnecessary<string>;
  products?: ProductAPI[];
  properties: Unnecessary<null>;
  slug: Unnecessary<string>;
  sorting: Unnecessary<number>;
  status: Unnecessary<string>;
  tileSize: number;
  updatedAt: Unnecessary<string>;
  warehouseBlacklist: Unnecessary<[]>;
}

export interface CategoryListAPI {
  category: CategoryAPI;
  children?: CategoryListAPI[];
}

export interface SearchCatsResponse {
  name: string;
  id: string;
  parentCatName?: string | null;
  parentCatID?: string | null;
}

export interface SearchResultsResponse {
  data: {
    categories: SearchCatsResponse[];
    products: ProductAPI[];
    totalCount: number;
    suggestions?: unknown[];
  };
}

export interface Banner {
  active: boolean;
  code: string;
  coupon_group: Record<string, unknown>;
  created_at: string;
  date_begin: string;
  date_end: null;
  deep_link: string;
  deleted_at: null;
  description: string;
  display_mode: string;
  hidden: boolean;
  id: number;
  images: unknown;
  name: string;
  offer: number[];
  preview_image: ProductImageAPI | null;
  preview_text: string;
  schedule: Record<string, unknown>;
  slug: string;
  sort_order: number;
  tile_size: number;
  type: string;
  updated_at: string;
}

interface ProductsResponse {
  data: CategoryListAPI;
}

interface BannerProductsResponse {
  data: {
    active: boolean;
    hidden: boolean;
    name: string;
    id: number;
    offer?: ProductAPI[];
  };
}

interface ProductResponse {
  data: ProductAPI;
  error?: boolean;
  message?: string;
}

export interface SearchHistoryResponse {
  data: { search_phrase: string | null }[];
}

interface CategoriesResponse {
  data: CategoryListAPI[];
}

interface BannersResponse {
  data: Banner[];
}

export interface ProductRequest {
  warehouseCode: string;
  deviceId: string;
  customerId?: string;
  id?: string;
  deepLink?: string;
  isSafeRequest?: boolean;
}

export type RecommendProductsSource = 'cart' | 'card' | 'search' | 'purchased_items';

export type CartCalculationItemRequest = {
  sku: string;
  requested_quantity: number;
};

export type CartBundlesCalculationItemRequest = {
  id: number;
  requested_quantity: number;
};

export type CartBundlesCalculationItemResponse = {
  id: number;
  requested_quantity: number;
  items?: CartCalculationItemResponse[];
};

export interface CartCalculationRequest {
  warehouse_code: string;
  seller: string;
  delivery_method: ETADeliveryMethodType;
  items?: CartCalculationItemRequest[];
  bundles?: CartBundlesCalculationItemRequest[],
  address: {
    latitude: number;
    longitude: number;
  };
  should_use_bonuses: boolean;
  promocode?: string;
  tips_amount: number;
}

export type CartCalculationItemResponse = {
  actual_quantity: NumberInt;
  image: string;
  is_gift: boolean;
  name: string;
  paid_bonuses: NumberInt;
  parent_category_id: number;
  product_id: number;
  promo_quantity_discount_price: NumberInt;
  promo_required_quantity: number;
  requested_quantity: number;
  sku: string;
  weight: number;
  sellable: number;
  base_price: NumberInt;
  discount_amount: NumberInt;
  promocode_discount_amount: NumberInt;
  paid_price: NumberInt;
  is_discount_forbidden: boolean;
  discount_price: NumberInt;
  items?: CartCalculationItemResponse[];
  bundle_id?: string;
};

export type CartCalculationBundleResponse = {
  id: number;
  requested_quantity: number;
  price: number;
  images: Nullable<ImageAPI[]>;
  items?: CartCalculationItemResponse[];
  name: string;
};

export interface CartCalculationResponse {
  warehouse_code: string;
  delivery_method: ETADeliveryMethodType;
  delivery_fee: number;
  seller: string;
  items: CartCalculationItemResponse[];
  bundles: CartCalculationBundleResponse[];
  bonuses_to_spend: number;
  address: {
    latitude: number;
    longitude: number;
  };
  promocode: string | null;
  promocodeError: string | null;
  base_total: number;
  discount_total: number;
  promocode_discount: number;
  is_first_order: boolean;
  paid_bonuses: number;
  paid_total: number;
  is_delivery_free: boolean;
  received_bonuses: number;
  base_received_bonuses: number;
  service_fee: number;
  tax_percent: number,
  tax_amount: number,
  delivery_info: {
    base_price: number;
    discount_amount: number;
    paid_bonuses: number
    paid_price: number;
    promocode_discount_amount: number;
  };
}

interface CatalogRequestsInterface {
  calculateCart(data: CartCalculationRequest): Promise<CartCalculationResponse>;

  getCategories(data: {
    warehouseCode: string;
    isSafeRequest?: boolean;
  }): Promise<CategoriesResponse>;

  getBanners(data: { warehouse_code: string }): Promise<BannersResponse>;

  getProducts(categoryId: string, data: {
    warehouseCode: string;
    categoryId?: string;
    deepLink?: string;
    isSafeRequest?: boolean;
  }): Promise<ProductsResponse>;

  getBannerProducts(data: {
    warehouse_code: string;
    id?: string;
    deep_link?: string;
    isSafeRequest?: boolean;
  }): Promise<BannerProductsResponse>;

  getProduct(productId: string, data: ProductRequest): Promise<ProductResponse>;

  getSearch(data: {
    q: string;
    warehouse_code: string;
    limit: number;
    device_id: string;
    isSafeRequest?: boolean;
  }): Promise<SearchResultsResponse>;

  getSearchHistory(data: { device_id: string }): Promise<SearchHistoryResponse>;

  deleteSearchHistory(data: { device_id: string }): Promise<any>;

  getBestsellers(data: {
    warehouse_code: string;
    isSafeRequest?: boolean;
  }): Promise<{ data: ProductAPI[] }>;

  getRecommendProducts(
    warehouse_code: string,
    data: {
      sku: string[];
      source: RecommendProductsSource;
      isSafeRequest?: boolean;
    },
  ): Promise<{ data: ProductAPI[] }>;
}

const getCurrentLang = () =>
  JSON.parse(localStorage.getItem('UserStore') || '{}').language?.split('-')[0] || '';

export const CatalogRequests: CatalogRequestsInterface = {
  calculateCart: (data) =>
    RequestAPI.post(ENDPOINT.orders.calculate, data),

  getCategories: (data) =>
    RequestAPI.get(ENDPOINT.catalog.category.list, data, { headers: { 'x-lang': getCurrentLang() } }),

  getBanners: (data) =>
    RequestAPI.get(ENDPOINT.catalog.banner.list, data),

  getProducts: (categoryId, data) =>
    RequestAPI.get(ENDPOINT.catalog.product.listByCategory + categoryId, data, { headers: { 'x-lang': getCurrentLang() } }),

  getBannerProducts: (data) =>
    RequestAPI.post(ENDPOINT.catalog.product.listByBanner, data, { headers: { 'x-lang': getCurrentLang() } }),

  getProduct: (productId, data) =>
    RequestAPI.get(ENDPOINT.catalog.product.request + productId, data, { headers: { 'x-lang': getCurrentLang() } }),

  getSearch: (data) =>
    RequestAPI.get(ENDPOINT.catalog.search.request, data, { headers: { 'x-lang': getCurrentLang() } }),

  getSearchHistory: (data) =>
    RequestAPI.get(ENDPOINT.catalog.search.history.list, data, { headers: { 'x-lang': getCurrentLang() } }),

  deleteSearchHistory: (data) =>
    RequestAPI.post(ENDPOINT.catalog.search.history.delete, data, { headers: { 'x-lang': getCurrentLang() } }),

  getBestsellers: (data) =>
    RequestAPI.get(ENDPOINT.catalog.product.bestsellers, data),

  getRecommendProducts: (warehouse_code, data) =>
    RequestAPI.post(ENDPOINT.catalog.product.recommendations + warehouse_code, data, { headers: { 'x-lang': getCurrentLang() } }),
};
