import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import CartBoard from './CartBoard/CartBoard';
import PopoverOutStock from './PopoverOutStock';
import ScrollToTop from './ScrollToTop';
import PopoverZeroCart from './PopoverZeroCart';
import PopoverAgeRestriction from './PopoverAgeRestriction';
import PopoverNotFindProduct from './PopoverNotFindProduct';
import PopoverOutdatedApp from './PopoverOutdatedApp';
import PopoverRateOrder from './PopoverRateOrder';
import PopoverFreeDelivery from './PopoverFreeDelivery';
import MainMenu from '../pages/MainMenu';
import PopoverGiftOutStock from './PopoverGiftOutStock';
import PopoverGiftAlreadyReceived from './PopoverGiftAlreadyReceived';
import PopoverChangeAddress from './PopoverChangeAddress';
import PopoverPayTip from './PopoverPayTip';
import PopoverSoonClosing from './PopoverSoonClosing';
import PopoverShopIsClosed from './PopoverShopIsClosed';

type ContentLayoutProps = {
  children: ReactNode;
};

export default observer(({ children }: ContentLayoutProps) => {
  return (
    <div className="content-layout">
      <ScrollToTop />
      {children}
      <CartBoard />
      <PopoverOutStock />
      <PopoverGiftOutStock />
      <PopoverGiftAlreadyReceived />
      <PopoverZeroCart />
      <PopoverAgeRestriction />
      <PopoverNotFindProduct />
      <PopoverOutdatedApp />
      <PopoverRateOrder />
      <PopoverPayTip />
      <PopoverFreeDelivery />
      <PopoverChangeAddress />
      <PopoverSoonClosing />
      <PopoverShopIsClosed />
      <MainMenu />
    </div>
  );
});
