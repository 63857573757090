import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Popover from './Popover';
import { mainStore } from '../stores/MainStore';
import IllustrationNeedUpdate from '../assets/img/need_update.svg';
import { company } from '../company/Company';

export default observer(() => {
  const { t } = useTranslation();
  const onDismiss = () => mainStore.setIsOutdatedAppPopover(false);

  return (
    <Popover isShow={mainStore.isOutdatedAppPopover} onBackdropDismiss={onDismiss}>
      <img className="h-130 d-block mx-auto mb-24" src={IllustrationNeedUpdate} alt="" />
      <div className="text-center mb-24 fs-21 lh-30">
        {t('errors:outdatedApp')}
      </div>
      <div className="d-flex align-items-center justify-content-end mx-n4">
        <button
          className="button _secondary w-100p mx-4 flex-shrink-1"
          onClick={onDismiss}
        >
          {t('later')}
        </button>
        <a
          className="button _primary w-100p mx-4 flex-shrink-1"
          href={mainStore.appPlatform ? company.config.appStoreUrl[mainStore.appPlatform] : undefined}
          onClick={onDismiss}
        >
          {t('update')}
        </a>
      </div>
    </Popover>
  );
});
