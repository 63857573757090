import axios, { AxiosResponse } from 'axios';
import { GOOGLE_API_KEY, GOOGLE_MAP_API_ROOT, REQUEST_TIMEOUT } from '../config';
import { mainStore } from '../stores/MainStore';

type ResultType =
  | 'street_address'
  | 'route'
  | 'intersection'
  | 'political'
  | 'country'
  | 'administrative_area_level_1'
  | 'administrative_area_level_2'
  | 'administrative_area_level_3'
  | 'administrative_area_level_4'
  | 'administrative_area_level_5'
  | 'colloquial_area'
  | 'locality'
  | 'neighborhood'
  | 'premise'
  | 'subpremise'
  | 'plus_code'
  | 'postal_code'
  | 'natural_feature'
  | 'airport'
  | 'park'
  | 'point_of_interest';

type LocationType = 'ROOFTOP' | 'RANGE_INTERPOLATED' | 'GEOMETRIC_CENTER' | 'APPROXIMATE';

export type AddressComponentType =
  | 'street_number'
  | 'route'
  | 'postal_town'
  | 'administrative_area_level_1'
  | 'administrative_area_level_2'
  | 'political'
  | 'country'
  | 'postal_code'
  | 'premise'
  | 'neighborhood'
  | 'locality'
  | 'plus_code';

export type AddressComponent = {
  long_name: string;
  short_name: string;
  types: AddressComponentType[];
};

type GeocodingResponse = {
  results: {
    address_components: AddressComponent[];
    formatted_address: string;
    geometry: {
      location: GeoCoordinates;
      location_type: LocationType;
      viewport: {
        northeast: GeoCoordinates;
        southwest: GeoCoordinates;
      };
    };
    place_id: string;
    plus_code: {
      compound_code: string;
      global_code: string;
    };
    types: ResultType[];
  }[];
  status:
    | 'OK'
    | 'ZERO_RESULTS'
    | 'OVER_QUERY_LIMIT'
    | 'REQUEST_DENIED'
    | 'INVALID_REQUEST'
    | 'UNKNOWN_ERROR';
};

const axiosInstance = axios.create({
  baseURL: GOOGLE_MAP_API_ROOT,
  timeout: REQUEST_TIMEOUT,
});

const responseBody = ({ data }: AxiosResponse) => data;

type GoogleMapGeocodeReguests = {
  geocodingByCoordinates(data: {
    latlng: string;
    result_type?: ResultType;
    location_type?: LocationType;
  }): Promise<GeocodingResponse>;
};

export const GoogleMapGeocode: GoogleMapGeocodeReguests = {
  geocodingByCoordinates: (data) =>
    axiosInstance
      .get(
        'geocode/json' +
        mainStore.convertObjToGet({
          ...data,
          language: 'en-GB',
          key: GOOGLE_API_KEY,
        }),
      )
      .then(responseBody),
};
