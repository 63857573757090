import { observer } from 'mobx-react-lite';
import { catalogStore } from '../../../stores/CatalogStore';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { userStore } from '../../../stores/UserStore';
import { company } from '../../../company/Company';
import Icon from '../../../components/ui/Icon/Icon';

export const JiffyPointsCashbackWidget = observer(() => {
  const { t } = useTranslation();
  const { totalCartPrice } = catalogStore;

  if (!userStore.isAuthorized || company.isDisableLoyaltyProgram || !totalCartPrice) {
    return null;
  }

  const { isFirstOrder, earnedBonusesPence, baseEarnedBonusesPence } = totalCartPrice;

  return (
    <div className="d-flex align-items-start ff-manrope">
      <div className="d-flex flex-direction-column">
        <div className="c-text lh-20 fs-14">{t('cartPage:willReturnAs')}</div>
        {isFirstOrder && (
          <div className="c-corange fs-10 lh-14">{t('cartPage:extraEarnRate')}</div>
        )}
      </div>

      <div className="ml-auto d-flex">
        <Icon type={company.config.points?.icon || 'coins'} size={19} className="d-flex flex-center mr-7" />

        {!isFirstOrder ? (
          <div className="c-black fs-14 lh-20">{earnedBonusesPence}</div>
        ) : (
          <>
            {(baseEarnedBonusesPence < earnedBonusesPence && baseEarnedBonusesPence > 0) && (
              <s className="mr-4 c-mgray lh-20">{baseEarnedBonusesPence}</s>
            )}
            <span className="c-corange fs-14  lh-20">{earnedBonusesPence}</span>
          </>
        )}
      </div>
    </div>
  );
});
