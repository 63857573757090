import { observer } from 'mobx-react-lite';
import { catalogStore } from '../../../../stores/CatalogStore';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { userStore } from '../../../../stores/UserStore';
import { orderStore } from '../../../../stores/OrderStore';
import { FeeStatus } from '../constants';
import { FeesStatusesProps } from '../interface';
import { mainStore } from '../../../../stores/MainStore';
import { company } from '../../../../company/Company';

const FeesStatuses = observer((props: FeesStatusesProps) => {
  const { deliveryFeeStatus, serviceFeeStatus, minimalOrderFeeStatus } = props;
  const { t } = useTranslation();

  if (minimalOrderFeeStatus === FeeStatus.Default) {
    return (
      <div className="fs-11 flex-shrink-0">
        {t(
          'cartBoard:minimalOrderInfo',
          {
            feeAmount: orderStore.currencySymbol + mainStore.convertPenceToPounds(
              company.config.minimalOrderFeeThreshold,
            ),
          },
        )}
      </div>
    );
  }

  if (minimalOrderFeeStatus === FeeStatus.NeedsToFree) {
    return (
      <div className="fs-11 flex-shrink-0 c-dorange">
        {t(
          'cartBoard:spendToFreeMinimalOrder',
          { amountLeft: orderStore.currencySymbol + catalogStore.leftUntilFreeMinimalOrderFee[0] },
        )}
      </div>
    );
  }

  if (deliveryFeeStatus === FeeStatus.NeedsToFree) {
    return (
      <div className="fs-11 flex-shrink-0 c-dorange">
        {t(
          'cartBoard:spendToFreeDelivery',
          { amountLeft: orderStore.currencySymbol + catalogStore.leftUntilFreeDelivery[0] },
        )}
      </div>
    );
  }

  if (serviceFeeStatus === FeeStatus.NeedsToFree) {
    return (
      <div className="fs-11 flex-shrink-0 c-dorange">
        {t(
          'cartBoard:spendToFreeService',
          { amountLeft: orderStore.currencySymbol + catalogStore.leftUntilFreeServiceFee[0] },
        )}
      </div>
    );
  }

  return (
    <section className="fs-11 flex-shrink-0 d-flex align-items-center">
      <div>
        {deliveryFeeStatus === FeeStatus.Default &&
          t('cartBoard:emptyDeliveryFeeInfoUpTo', {
            feeAmount: mainStore.addCurrencySymbol(
              orderStore.fee.shippingPounds),
          })}

        {deliveryFeeStatus === FeeStatus.FreeDays &&
          t('cartBoard:freeDeliveryDaysLeftWithFreeService', {
            count: userStore.personalData.freeDeliveryDaysLeft,
          })}

        {deliveryFeeStatus === FeeStatus.Free && t('cartBoard:freeDelivery')}
      </div>

      {serviceFeeStatus !== FeeStatus.Hide && (
        <>
          <div className="ml-4 mr-4">•</div>

          <div>
            {serviceFeeStatus === FeeStatus.Default && (
              <>
                <span className="service-fee-status">
                  {t('cartBoard:emptyServiceFeeInfoUpTo', {
                    feeAmount: mainStore.addCurrencySymbol(
                      orderStore.fee.serviceFeeShippingPounds),
                  })}
                </span>

                <span className="service-fee-status short d-none">
                  {t('cartBoard:emptyServiceFeeInfoSmallScreen', {
                    feeAmount: mainStore.addCurrencySymbol(
                      orderStore.fee.serviceFeeShippingPounds),
                  })}
                </span>
              </>
            )}
            {serviceFeeStatus === FeeStatus.Free && t('cartBoard:freeService')}
          </div>
        </>
      )}
    </section>
  );
});

export default FeesStatuses;
