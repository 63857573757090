import Icon from '../../ui/Icon/Icon';
import { TescoBadgeProps } from '../interface';
import React from 'react';
import { CATALOG_PAGE_SIZE, PRODUCT_PAGE_SIZE } from './constants';

export const TescoBadge = (props: TescoBadgeProps) => {
  const { className, offer, size = 'normal' } = props;

  if (!offer.properties.tesco_price_match) {
    return null;
  }

  return (
    <div className={className}>
      <Icon type="tesco" size={size === 'normal' ? PRODUCT_PAGE_SIZE : CATALOG_PAGE_SIZE} />
    </div>
  );
};
