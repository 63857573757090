import { observer } from 'mobx-react-lite';
import { useLayoutEffect, useState } from 'react';
import { useLocation, useParams, useHistory, Link } from 'react-router-dom';
import { mainStore } from '../stores/MainStore';
import { catalogStore } from '../stores/CatalogStore';
import ScrollRestoration from '../components/ScrollRestoration';
import htmlClasses from 'html-classes';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { scrollTo } from '../UI';
import ProductList from '../components/ProductList';
import { Product } from '../types/Product/interface';

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { bannerId } = useParams<{ bannerId: string }>();
  const { state } = useLocation<{ isDeeplink?: boolean }>();
  const [categoryName, setCategoryName] = useState('');
  const [products, setProducts] = useState<Product[] | null>(null);
  const [isReady, setIsReady] = useState(false);
  const handleSearchLink = () => {
    catalogStore.setSearchQuery('');
    mainStore.sendToRN('analytics', {
      name: 'Catalog: search clicked',
      params: {
        source: 'category',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'catalog_search_clicked',
      params: {
        source: 'category',
      },
    });
  };
  const handleShowNotFindProductPopover = () => mainStore.setIsNotFindProductPopover(true);
  const handleScrollTop = () => scrollTo('scroll-layout', 0, 200);

  useLayoutEffect(() => {
    if (!bannerId) return;
    catalogStore
      .requestBannerProducts(bannerId, state?.isDeeplink || false)
      .then((e) => {
        if (!e) {
          setProducts([]);
          return;
        }
        setCategoryName(e.categoryName);
        setProducts(e.products);
      })
      .catch(() => setProducts([]));
  }, [bannerId, state]);

  return (
    <>
      {products && isReady && <ScrollRestoration pageName='banner' />}
      <div className='d-flex h-50 flex-shrink-0 align-items-center'>
        <div
          className='icon icon-rtl icon-arrow-back d-flex flex-center s-50 fs-20 c-black'
          onClick={() => (history.length === 1 ? history.push('/') : history.goBack())}
        />
        <h3 className='text-center w-100p text-truncate'>{categoryName}</h3>
        <Link
          className='icon icon-rtl icon-search d-flex flex-center s-50 fs-20'
          to={{ pathname: '/search', state: { source: 'category' } }}
          onClick={handleSearchLink}
        />
      </div>
      <div className={htmlClasses('scroll-layout h-100p px-24', { 'overflow-hidden': !products })}>
        {products !== null ? (
          products.length > 0 ? (
            <>
              <ProductList
                productList={products}
                source='banner'
                onReady={() => setIsReady(true)}
              />
              <div
                className='button _bordered fw-500 w-100p mt-22'
                onClick={handleShowNotFindProductPopover}
              >
                {t('categoryPage:canNotFindItems')}
              </div>
              <div className='button _bordered w-100p mt-10' onClick={handleScrollTop}>
                {t('backToTop')}
              </div>
            </>
          ) : (
            <div className='text-center p-28 fs-14 c-gray'>{t('comingSoon')}</div>
          )
        ) : (
          <div className='d-flex mx-n4 flex-wrap'>
            {[1, 2, 3, 4].map((subitem, j) => (
              <div className='w-50p px-4 pb-8 flex-shrink-0' key={j}>
                <Skeleton className='product-item br-8 h-200' />
              </div>
            ))}
          </div>
        )}
        <div className='h-24' />
      </div>
    </>
  );
});
