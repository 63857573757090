import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

type PopoverProps = {
  isShow: boolean;
  children: ReactNode;
  className?: string;
};

export default observer(({ isShow, children, className }: PopoverProps) => {
  const refPopover = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition
      in={isShow}
      timeout={{ enter: 350, exit: 250 }}
      mountOnEnter
      unmountOnExit
      classNames="popover-zoom"
      nodeRef={refPopover}
    >
      <div className={htmlClasses('popover', className)} ref={refPopover}>
        {children}
      </div>
    </CSSTransition>
  );
});
