import { RefObject } from 'react';
import { BLINK_ANIMATION_TIMEOUT, BLINK_LEFT_VALUE } from '../constants';
import { scrollTo } from '../../../UI';
import SessionStorage from '../../../stores/SessionStorage';
import { SESSION_STORAGE } from '../../../config';

export const useSliderBlink = (
  observableElement: RefObject<HTMLDivElement>,
  scrollableElement: RefObject<HTMLDivElement>
) => {

  return {
    setBlinkAction: () => {
      setTimeout(() => {
        scrollTo(scrollableElement.current, BLINK_LEFT_VALUE, BLINK_ANIMATION_TIMEOUT, 0, 'x');

        setTimeout(() => {
          scrollTo(scrollableElement.current, -BLINK_LEFT_VALUE, BLINK_ANIMATION_TIMEOUT, 0, 'x');
          SessionStorage.set(SESSION_STORAGE.BLINK_ACTION_CALLED, true);
        }, BLINK_ANIMATION_TIMEOUT);
      }, BLINK_ANIMATION_TIMEOUT);
    }
  }
}
