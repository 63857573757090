import { observer } from 'mobx-react-lite';
import QRCode, { QRCodeProps } from 'react-qr-code';
import React, { useEffect, useState } from 'react';
import { DARK_MODE_FOREGROUND_COLOR, QR_CODE_SIZE } from './constants';

const QRCodeWidget = observer((props: Pick<QRCodeProps, 'value' | 'className'>) => {
  const { value, className } = props;
  const [fgColor, setFgColor] = useState('black');

  useEffect(() => {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setFgColor(DARK_MODE_FOREGROUND_COLOR);
    }
  }, []);

  return <QRCode role="img" className={className} value={value} fgColor={fgColor} size={QR_CODE_SIZE} />;
});

export default QRCodeWidget;
