import { ITEMS_PER_PAGE_LIMIT } from './constants';
import { Product } from '../../types/Product/interface';

export function removeRepetitions(
  recommendProducts: Product[] = []
): Product[]  {
  if (recommendProducts.length === 0) {
    return [];
  }

  const selectedItems: Product[] = [];
  const paginatedProductIdList: Record<string, boolean> = {};

  for (let productIndex = 0; productIndex < recommendProducts.length; productIndex += 1) {
    const newProduct = recommendProducts[productIndex];

    if (!paginatedProductIdList[newProduct.id]) {
      selectedItems.push(newProduct);
      paginatedProductIdList[newProduct.id] = true;
    }
  }

  return selectedItems;
}


export function getShowMoreItems(
  recommendProducts: Product[] = [],
  paginatedRecommendedProducts: Product[] = []
): Product[]  {
  const fullFetchedListLen = recommendProducts?.length ?? 0;
  const currentPaginatedListLen = paginatedRecommendedProducts?.length ?? 0;

  if (fullFetchedListLen === 0) {
    return [];
  }

  const addNextItemsQuantity = currentPaginatedListLen + ITEMS_PER_PAGE_LIMIT;

  return recommendProducts.slice(
    currentPaginatedListLen,
    addNextItemsQuantity > fullFetchedListLen ? undefined : addNextItemsQuantity
  );
}
