import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

type PopoverProps = {
  isShow: boolean;
  children: ReactNode;
  isBackdrop?: boolean;
  onBackdropDismiss?: () => void;
  className?: string;
};

export default observer(
  ({ isShow, children, isBackdrop = true, onBackdropDismiss, className }: PopoverProps) => {
    const refPopover = useRef<HTMLDivElement>(null);

    return (
      <CSSTransition
        in={isShow}
        timeout={{ enter: 350, exit: 250 }}
        mountOnEnter
        unmountOnExit
        classNames="popover"
        nodeRef={refPopover}
      >
        <div className="popover" ref={refPopover}>
          <div
            className={htmlClasses('popover__bg', { _transparent: !isBackdrop })}
            onClick={onBackdropDismiss}
          />
          <div className={htmlClasses('popover__body', className)}>{children}</div>
        </div>
      </CSSTransition>
    );
  },
);
