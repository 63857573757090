const storage = window.sessionStorage;

const SessionStorage = {
  set: (key: string, value: any) => storage.setItem(key, JSON.stringify(value)),

  get: (key: string) => {
    try {
      const value = storage.getItem(key);
      return value ? JSON.parse(value) : null;
    } catch {
      return null;
    }
  }
}
export default SessionStorage;
