import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';

type InputSwitchProps = {
  checked?: boolean;
  className?: string;
};

export default observer(({ checked, className }: InputSwitchProps) => {
  return <div className={htmlClasses('input-switch', className, { _active: checked })} />;
});
