import { PropsWithChildren } from 'react';

export interface TypographyProps {
  type?: 'normal' | 'medium' | 'bold';
  fontSize?:
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 24
    | 28
    | 32
    | 36
    | 40;
  lineHeight?: 11 | 14 | 16 | 18 | 19 | 20 | 22 | 24 | 25 | 28 | 30 | 36 | 40;
  family?: 'manrope';
}

export const Typography = (props: PropsWithChildren<TypographyProps>) => {
  const { children, fontSize, family, lineHeight, type } = props;
  let classes = '';
  if (fontSize) {
    classes = `fs-${fontSize}`;
  }

  if (family) {
    classes = `${classes} ff-${family}`;
  }

  if (lineHeight) {
    classes = `${classes} lh-${lineHeight}`;
  }

  if (type) {
    let boldSize = 400;
    switch (type) {
      case 'normal':
        boldSize = 400;
        break;
      case 'medium':
        boldSize = 600;
        break;
      case 'bold':
        boldSize = 700;
    }

    classes = `${classes} fw-${boldSize}`;
  }

  return <span className={classes}>{children}</span>;
};
