import Recaptcha from 'react-google-invisible-recaptcha';
import React, { forwardRef, useEffect, useState } from 'react';
import { RecaptchaWidgetProps } from './interface';
import { LOAD_RECAPTCHA_MAX_ATTEMPTS, LOAD_RECAPTCHA_TIMEOUT } from './constants';
import * as Sentry from '@sentry/react';

export const RecaptchaWidget =
  forwardRef<RecaptchaProps, RecaptchaWidgetProps>((props, ref) => {
    const { onLoaded, onResolved, onError, sitekey } = props;
    const [currentStep, setCurrentStep] = useState(1);

    const checkRecaptchaLoaded = (step = 1) => {
      setTimeout(() => {
        try {
          window.GoogleRecaptchaLoaded();
          onLoaded();

          return;
        } catch (e) {
          if (step > LOAD_RECAPTCHA_MAX_ATTEMPTS) {
            onError();
            Sentry.captureMessage('[Recaptcha] Failed Recaptcha init', 'warning');
            return;
          }

          const recaptchaScript = document.getElementById('recaptcha');
          if (recaptchaScript) {
            recaptchaScript.remove();
          }

          setCurrentStep((step) => step + 1);
        }
      }, LOAD_RECAPTCHA_TIMEOUT);
    };

    useEffect(() => {
      checkRecaptchaLoaded(currentStep);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep]);

    return (
      <Recaptcha
        key={currentStep}
        sitekey={sitekey}
        locale="en"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={ref}
        onLoaded={onLoaded}
        onResolved={onResolved}
        onError={onError}
      />
    );
  });
