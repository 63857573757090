import { observer } from 'mobx-react-lite';
import htmlClasses from 'html-classes';
import React from 'react';
import { FeeTrackerProps } from '../interface';

const FeeTracker = observer((props: FeeTrackerProps) => {
  const { isFree, progressWidth } = props;

  return (
    <div className="progress-bar h-3 c-bg-sgray mt-8">
      <div
        className={htmlClasses('progress-bar__bar', isFree ? 'c-bg-lgreen' : 'c-bg-dorange')}
        style={{ width: progressWidth, transition: 'all 0.3s' }}
      />
    </div>
  );
});

export default FeeTracker;
