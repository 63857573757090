import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Popover from './Popover';
import { mainStore } from '../stores/MainStore';
import { orderStore } from '../stores/OrderStore';
import htmlClasses from 'html-classes';
import { catalogStore } from '../stores/CatalogStore';
import Icon from './ui/Icon/Icon';
import { userStore } from '../stores/UserStore';
import { company } from '../company/Company';

export default observer(() => {
  const { t } = useTranslation();
  const handleHideETA = () => mainStore.setIsShowETAPopover(false);
  const handleShowServiceFeePopover = () => mainStore.setIsShowServiceFeePopover(true);

  return (
    <Popover isShow={mainStore.isShowETAPopover} onBackdropDismiss={handleHideETA}>
      <div className="fs-21 c-black">{t('etaPopover:terms')}</div>
      {orderStore.etaCalculation?.warehouse.availability.availableNow ? (
        orderStore.etaCalculation?.highDemand === true && (
          <div className="d-flex align-items-center mt-16 c-red">
            <div className="icon icon-rtl icon-flash s-24 d-flex flex-center mie-12 fs-24" />
            <div className="fs-14 lh-19">
              {orderStore.fee.shipping > 199
                ? t('etaPopover:peakHoursWithPrice')
                : t('etaPopover:peakHours')}
            </div>
          </div>
        )) : (
        <div className="d-flex align-items-center mt-16 c-dgray">
          <div className="icon icon-rtl icon-sleep s-24 d-flex flex-center mr-12 fs-24" />
          <div className="fs-14">{t('etaPopover:storeClosed')}</div>
        </div>
      )}
      <div className="mt-16 br-8 border-linegray p-12  fs-14 lh-19">
        {orderStore.etaCalculation?.warehouse.availability.availableNow && (
          <div className="c-black mb-4">
            {t(
              'etaPopover:courierArriveIn',
              { time: mainStore.formatRange(orderStore.etaCalculation?.duration.range) || '?' },
            )}
          </div>
        )}
        <div
          className={htmlClasses(
            orderStore.etaCalculation?.warehouse.availability.availableNow ? 'c-dgray' : 'c-black')}
        >
          {t('etaPopover:openingHours')}
          {': '}
          {mainStore.formatRange(orderStore.etaCalculation?.warehouse.availability.opening ?? '', {
            end: orderStore.etaCalculation?.warehouse.availability.closing,
          }) || '?'}
        </div>
      </div>

      {catalogStore.isStoreInMinimalOrderList && !userStore.isStaff && !orderStore.isFirstOrder && (
        <div className="mt-16 br-8 border-linegray p-12 ff-manrope fs-14 lh-19">
          <div className="c-black mb-4 d-flex justify-content-between">
            <div className="d-flex flex-center">
              <Icon type="cart" className="mr-8" />
              {t('etaPopover:minimalOrderValue')}
            </div>

            <span>
              {mainStore.addCurrencySymbol(
                mainStore.convertPenceToPounds(company.config.minimalOrderFeeThreshold))}
            </span>
          </div>
          <div className="c-dgray">
            {t('etaPopover:minimalOrderValueDescription')}
          </div>
        </div>
      )}

      <div className="mt-16 d-flex align-items-end justify-content-between c-dgray fs-14">
        <div>{t('cart')}</div>
        <div>{t('etaPopover:deliveryFee')}</div>
      </div>
      <div className="h-1 pe-n my-8 c-bg-linegray" />
      <div className="d-flex align-items-end justify-content-between c-black fs-14">
        <div>{t('from')} <span className='lrt'>{mainStore.addCurrencySymbol('0')}</span></div>
        <div>{mainStore.addCurrencySymbol(orderStore.fee.shippingPounds || 0)}</div>
      </div>
      <div className="d-flex align-items-end justify-content-between c-black fs-14 mt-4">
        <div>
          {t('from')}
          {' '}
          <span className='lrt'>{mainStore.addCurrencySymbol(orderStore.fee.thresholdPounds || 0)}</span>
        </div>
        <div>{t('free')}</div>
      </div>
      {!!orderStore.fee.serviceFeeThreshold && (
        <>
          <div className="h-16" />

          <div className="mt-16 d-flex align-items-end justify-content-between c-dgray fs-14">
            <div>{t('cart')}</div>

            <div className="d-flex align-items-center" onClick={handleShowServiceFeePopover}>
              <div>{t('etaPopover:serviceFee')} </div>

              <div className="ml-4 icon-faq icon icon-rtl c-gray" />
            </div>
          </div>

          <div className="h-1 pe-n my-8 c-bg-linegray" />
          <div className="d-flex align-items-end justify-content-between c-black fs-14">
            <div>{t('from')} <span className='lrt'>{mainStore.addCurrencySymbol(0)}</span></div>
            <div>{mainStore.addCurrencySymbol(orderStore.fee.serviceFeeShippingPounds)}</div>
          </div>
          <div className="d-flex align-items-end justify-content-between c-black fs-14 mt-4">
            <div>
              {t('from')} <span className='lrt'>{mainStore.addCurrencySymbol(orderStore.fee.serviceFeeThresholdPounds)}</span>
            </div>

            <div>{t('none')}</div>
          </div>
        </>
      )}

      <div className="button _primary w-100p mt-24" onClick={handleHideETA}>
        {t('understood')}
      </div>
    </Popover>
  );
});
