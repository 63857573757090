import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { a, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';

type PopoverProps = {
  isShow: boolean;
  children: ReactNode;
  isBackdrop?: boolean;
  onBackdropDismiss?: () => void;
  className?: string;
};

export default observer(
  ({ isShow, children, isBackdrop = true, onBackdropDismiss, className }: PopoverProps) => {
    const refPopover = useRef<HTMLDivElement>(null);
    const [{ y }, set] = useSpring(() => ({ y: 0 }));
    const handleCollapseCover = () => {
      if (onBackdropDismiss) onBackdropDismiss();
    };
    const bind = useDrag(({ last, vxvy: [, vy], movement: [, my], event }) => {
      if (!onBackdropDismiss) return;
      if (event.target && (event.target as HTMLDivElement).closest('.overflow-auto')) return;
      if (my < 0) return;
      if (my >= 100) {
        handleCollapseCover();
        return;
      }
      if (last) {
        if (my > 80 || vy > 0.5) {
          handleCollapseCover();
          return;
        }
        set.start({ y: 0, immediate: false });
      } else set.start({ y: my, immediate: true });
    });

    useEffect(() => {
      set.stop();
      if (isShow) set.start({ y: 0, immediate: true });
      //eslint-disable-next-line
    }, [isShow]);

    return (
      <CSSTransition
        in={isShow}
        timeout={{ enter: 350, exit: 250 }}
        mountOnEnter
        unmountOnExit
        classNames="popover"
        nodeRef={refPopover}
      >
        <div className="popover" ref={refPopover}>
          <div
            className={htmlClasses('popover__bg', { _transparent: !isBackdrop })}
            onClick={onBackdropDismiss}
          />
          <a.div
            {...bind()}
            style={{ y }}
            className={htmlClasses('popover__body _handle', className)}
          >
            {children}
          </a.div>
        </div>
      </CSSTransition>
    );
  },
);
