import i18n from 'i18next';
import {
  Product,
  ProductOffer,
  ConsoleLogArgs,
  ConvertOffersArgs,
  ProductProperties,
  ProductNutrition,
  ProductVariant,
  ProductColor,
  ConvertOffersResult,
  ProductPropertiesValue,
} from './interface';
import {
  ProductAPI,
  ProductOfferAPI,
  ProductCommodityGroupAPI,
  ProductPropertiesKeyAPI,
  ProductPropertiesAPI,
  ProductPropertiesTypeAPI,
} from '../../api/Catalog';
import { CartItem } from '../../stores/CatalogStore';
import { mainStore } from '../../stores/MainStore';

/**
 * Convert ProductAPI to Product type
 * */
export function convertProduct(product: ProductAPI): Product | null {
  const validProduct = validateProductFields(product);
  if (!validProduct) return null;
  const {
    categoryId,
    categoryName,
    commodityGroup,
    description,
    discountPrice,
    id,
    images,
    isFavourite,
    isGrocery,
    name,
    offerProperties = [],
    offers,
    parentCategoryId,
    parentCategoryName,
    previewImage,
    previewImageThumb,
    price,
    sellable,
    productType,
  } = validProduct;
  const productImages = ((images && Array.isArray(images)) ? images : []).map((img) => img?.url || '');
  const productPreviewImageThumb: string = previewImageThumb?.url || previewImage?.url || (productImages.length && productImages[0]) || '';
  const productPreviewImage: string = previewImage?.url || productPreviewImageThumb;
  const properties = parseProperties(offerProperties || []);
  const isBundle = product.productType === 'bundle';
  const { validOffers, colors, sizes } = convertOffers({
    categoryId,
    categoryName,
    commodityGroup: commodityGroup?.name || null,
    isGrocery,
    offers,
    parentCategoryId,
    productDescription: description || '',
    productId: id,
    productImages,
    productPreviewImage,
    productPreviewImageThumb,
    productProperties: properties,
    isBundle,
  });
  if (!validOffers.length) {
    consoleLog({
      type: 'error',
      productId: id,
      source: 'product',
      message: 'Product does not have valid offers (the product ignored)',
    });
    return null;
  }
  const {
    pricePerUnit,
    promoQuantityDiscountPrice,
    promoQuantityDiscountPriceFormatted,
    promoRequiredQuantity,
    properties: validOfferProperties,
  } = validOffers[0];

  return {
    brandDescription: '',
    categoryId,
    categoryName,
    colors,
    commodityGroup: commodityGroup?.name || null,
    description: description || undefined,
    discountPercentage: '',
    discountPrice: discountPrice || 0,
    discountPriceFormatted: mainStore.convertPenceToPounds(discountPrice || 0),
    id,
    isFavourite,
    isGrocery,
    name,
    images: productImages,
    offers: validOffers,
    parentCategoryId,
    parentCategoryName,
    previewImageThumb: productPreviewImageThumb,
    price: price || 0,
    priceFormatted: mainStore.convertPenceToPounds(price || 0),
    pricePerUnit,
    promoQuantityDiscountPrice,
    promoQuantityDiscountPriceFormatted,
    promoRequiredQuantity,
    properties: validOfferProperties,
    sellable,
    sizes,
    productType,
    hasSingleOffer: validOffers.length === 1,
  };
}

/**
 * Precalculate some fields
 * */
export function calcProductOfferFields<T extends (ProductOffer | CartItem)>(offer: T): T {
  offer.priceFormatted = mainStore.convertPenceToPounds(offer.price);
  offer.discountPriceFormatted = mainStore.convertPenceToPounds(offer.discountPrice);
  offer.promoQuantityDiscountPriceFormatted = mainStore.convertPenceToPounds(
    offer.promoQuantityDiscountPrice);
  if (offer.discountPrice) {
    const discount = mainStore.calcDiscountPercentage(offer.price, offer.discountPrice);
    offer.discountPercentage = discount ? `-${discount}%` : '';
  } else offer.discountPercentage = '';
  if (!offer.pricePerUnit) {
    const { product_size, uom } = offer.properties;
    const items_per_outer_retail_pack = parseInt(
      (offer.properties.items_per_outer_retail_pack || '0') as string, 10);
    if (!items_per_outer_retail_pack || !product_size || !uom) {
      offer.pricePerUnit = `1 ${i18n.t('pc')}`;
    } else {
      offer.pricePerUnit = items_per_outer_retail_pack === 1 ? '' : `${items_per_outer_retail_pack}×`;
      offer.pricePerUnit += `${product_size}${(uom as string).includes('pack') ? ' ' : ''}${uom}`;
    }
  }
  /*if (offer.bundle_products && offer.bundle_products.length) {
    offer.bundle_products = offer.bundle_products.map(
      product => this.calcProductFields(product));
  }*/
  return offer;
}

/**
 * Convert ProductOfferAPI to ProductOffer type
 * */
function convertOffers({
  categoryId,
  categoryName,
  commodityGroup,
  isGrocery,
  offers,
  parentCategoryId,
  productDescription,
  productId,
  productImages,
  productPreviewImage,
  productPreviewImageThumb,
  productProperties,
  isBundle,
}: ConvertOffersArgs): ConvertOffersResult {
  let validOffers: ProductOffer[] = [];
  const colors: ProductColor[] = [];
  const availableColors: Record<string, string> = {};
  const availableSizes = new Set<string>();
  const colorsBySizes: Record<string, Record<string, string>> = {};
  offers.forEach((offer, index) => {
    const {
      code: sku = '',
      description,
      discountPrice,
      id,
      minBasketDiscountPrice,
      minBasketDiscountQuantity,
      name = '',
      price,
      properties: props = [],
      sellable = 0,
      bundleQuantity,
      productId: productIdOffer,
    } = offer;
    let properties: ProductProperties;
    if (props.length) {
      properties = { ...productProperties, ...parseProperties(props) };
    } else {
      properties = productProperties;
    }
    if (!isGrocery) {
      const { color, color_text, size } = properties;
      if (index) {
        const isColorsAvailable = Object.keys(availableColors).length;
        if ((size && !availableSizes.size) || (!size && availableSizes.size)) {
          consoleLog({
            type: 'error',
            productId,
            offerId: id,
            sku,
            source: 'offer',
            message: 'Mixed size types within the same product (the offer ignored)',
          });
          return;
        }
        if ((color_text && !isColorsAvailable) || (!color_text && isColorsAvailable)) {
          consoleLog({
            type: 'error',
            productId,
            offerId: id,
            sku,
            source: 'offer',
            message: 'Mixed color types within the same product (the offer ignored)',
          });
          return;
        }
      }
      if (size) {
        availableSizes.add(size as string);
        if (color_text) {
          if (colorsBySizes[size as string]) {
            if (colorsBySizes[size as string][color_text as string]) {
              consoleLog({
                type: 'error',
                productId,
                offerId: id,
                sku,
                source: 'offer',
                message: 'Double color in same size (the offer ignored)',
              });
              return;
            } else {
              colorsBySizes[size as string][color_text as string] = color as string || '';
            }
          } else {
            colorsBySizes[size as string] = {
              [color_text as string]: color as string || '',
            };
          }
        }
      }
      if (color_text && !availableColors[color_text as string]) {
        availableColors[color_text as string] = color as string || '';
      }
    }
    const images = (offer.images || []).map((img) => img?.url || '');
    const previewImage = images[0] || productPreviewImage || '';
    const previewImageThumb = images[0] || productPreviewImageThumb || '';
    if (!images.length && previewImage && !isBundle) images.push(previewImage, ...productImages);
    validOffers.push(calcProductOfferFields({
      attributes: parseAttributes(properties),
      bundle: null,
      categoryId,
      categoryName,
      colors: [],
      commodityGroup,
      description: description || productDescription,
      discountPercentage: '',
      discountPrice: discountPrice || 0,
      discountPriceFormatted: '0',
      id,
      images,
      isGrocery,
      name,
      nutrition: parseNutrition(properties),
      parentCategoryId,
      previewImageThumb,
      price: price || 0,
      priceFormatted: '0',
      pricePerUnit: '',
      productId: productIdOffer || productId,
      promoQuantityDiscountPrice: minBasketDiscountPrice || 0,
      promoQuantityDiscountPriceFormatted: '0',
      promoRequiredQuantity: minBasketDiscountQuantity || 0,
      properties,
      sellable,
      sku,
      variants: parseVariants(properties),
      bundleQuantity,
    }));
  });
  for (const availableColorsKey in availableColors) {
    colors.push({
      name: availableColorsKey,
      code: availableColors[availableColorsKey],
    });
  }
  const sizes: string[] = Array.from(availableSizes);
  if (colors.length && sizes.length) {
    validOffers = validOffers.map((item) => {
      if (!colorsBySizes[item.properties.size as string]) return item;
      const colors: ProductColor[] = [];
      for (const key in colorsBySizes[item.properties.size as string]) {
        colors.push({
          name: key,
          code: colorsBySizes[item.properties.size as string][key],
        });
      }
      return { ...item, colors };
    });
  }
  return { validOffers, colors, sizes };
}

/**
 * Parse formatted properties fields
 * */
function parseProperties(props: ProductPropertiesAPI[]): ProductProperties {
  const properties: ProductProperties = {};

  if (!props.length) return properties;

  const types: ProductPropertiesTypeAPI[] = [
    'checkboxGroup',
    'radioGroup',
    'selector',
    'tagList',
  ];
  for (const prop of props) {
    const { code, value, values = '', type } = prop;
    let val: ProductPropertiesValue = value ?? '';
    if (type === 'toggle' && typeof value === 'string') {
      val = value === 'true';
    }
    if (types.includes(type)) {
      val = values;
    }
    properties[code] = val;
  }

  return properties;
}

/**
 * Parse product nutritions
 * */
function parseNutrition(props: ProductProperties): ProductNutrition[] {
  const nutrition: ProductNutrition[] = [];
  const nutritionFields: {
    RI: number | null;
    maxValue?: number;
    minValue?: number;
    name: ProductPropertiesKeyAPI;
  }[] = [
    {
      name: 'kcal',
      RI: 2000,
    },
    {
      name: 'fat',
      RI: 70,
      minValue: 3,
      maxValue: 17.5,
    },
    {
      name: 'fat_saturates',
      RI: 20,
      minValue: 1.5,
      maxValue: 5,
    },
    {
      name: 'carbohydrates',
      RI: 260,
    },
    {
      name: 'sugar',
      RI: 30,
      minValue: 5,
      maxValue: 22.5,
    },
    {
      name: 'proteins',
      RI: 50,
    },
    {
      name: 'salt',
      RI: 6,
      minValue: 0.3,
      maxValue: 1.5,
    },
    {
      name: 'fibre',
      RI: null,
    },
  ];
  nutritionFields.forEach(({ name, RI, minValue, maxValue }) => {
    if (props[name] === undefined) return;
    const value = mainStore.toFloat(props[name] as number);
    let ratio = '-';
    let className = '';
    if (RI !== null) {
      ratio = Math.round((value / RI) * 100) + ' %';
    }
    if (minValue !== undefined && value <= minValue) {
      className = 'product-info__item--low';
    } else if (maxValue !== undefined && value > maxValue) {
      className = 'product-info__item--high';
    } else if (maxValue !== undefined && minValue !== undefined) {
      className = 'product-info__item--medium';
    }
    nutrition.push({
      name,
      value: value + (name !== 'kcal' ? 'g' : 'kcal'),
      ratio,
      className,
    });
  });
  return nutrition;
}

/**
 * Parse product variants
 * */
function parseVariants(props: ProductProperties): ProductVariant[] {
  const variants: ProductVariant[] = [];
  const variantNames: ProductPropertiesKeyAPI[] = [
    'allergen_free',
    'dairy_free',
    'gluten_free',
    'low_fat',
    'for_vegetarians',
    'is_organic_food',
    'for_halal',
    'gmo_free',
    'age_restriction',
    'sugar_free',
    'for_kosher',
    'for_vegan',
    'fairtrade',
    'freezing_suitable',
    'with_soya',
    'may_contain_nuts',
    'with_sulphites',
    'no_added_sugar',
  ];
  variantNames.forEach((item) => {
    if (!props[item]) return;
    variants.push({
      name: item,
      value: i18n.t('productVariants:' + item),
    });
  });
  return variants;
}

/**
 * Parse product attributes
 * */
function parseAttributes(props: ProductProperties): ProductVariant[] {
  const attributes: ProductVariant[] = [];
  const attributNames: ProductPropertiesKeyAPI[] = [
    'country_of_origin',
    'manufacturer',
    'best_before_text',
    'storage',
    'type_of_packaging',
  ];
  attributNames.forEach((item) => {
    if (props[item] === undefined) return;
    let value: string | boolean = props[item] as string | boolean;
    if (typeof value === 'boolean') {
      value = i18n.t(value ? 'yes' : 'no') as string;
    }
    attributes.push({
      name: item,
      value,
    });
  });
  return attributes;
}

/**
 * Validate required fields in ProductAPI
 * */
function validateProductFields(
  product: PartialRecursively<ProductAPI> & { isGrocery?: boolean },
): ProductAPI & { isGrocery: boolean } | null {
  let isValid = true;
  const { id } = product;
  const consoleLogSample: ConsoleLogArgs = {
    type: 'error',
    message: '',
    productId: id,
    source: 'product',
  };
  if (id === undefined || id === null) {
    isValid = false;
    consoleLog({
      ...consoleLogSample,
      message: 'Missing product ID (the product ignored)',
    });
  }
  if (
    product.productType !== 'bundle' && (
      product.commodityGroup === undefined ||
      product.commodityGroup === null ||
      !product.commodityGroup?.name
    )
  ) {
    isValid = false;
    consoleLog({
      ...consoleLogSample,
      message: 'Missing commodityGroup (the product ignored)',
    });
  } else {
    product.isGrocery = product.commodityGroup?.name ? ![
      ProductCommodityGroupAPI.CLOTHING,
      ProductCommodityGroupAPI.SHOES,
      ProductCommodityGroupAPI.ACCESSORIES,
    ].includes(product.commodityGroup.name)
    : false;
  }
  if (!product.offers?.length) {
    isValid = false;
    consoleLog({
      ...consoleLogSample,
      message: 'Missing offers (the product ignored)',
    });
  }
  if (product.categoryId === undefined || product.categoryId === null) {
    isValid = false;
    consoleLog({
      ...consoleLogSample,
      message: 'Missing category ID (the product ignored)',
    });
  }
  if (!product.categoryName) {
    isValid = false;
    consoleLog({
      ...consoleLogSample,
      message: 'Missing category name (the product ignored)',
    });
  }
  if (product.parentCategoryId === undefined || product.parentCategoryId === null) {
    product.parentCategoryId = 0;
    // TODO uncomment later
    /*isValid = false;
    consoleLog({
      ...consoleLogSample,
      message: 'Missing parent category ID (the product ignored)',
    });*/
  }
  if (!product.parentCategoryName) {
    product.parentCategoryName = '';
    // TODO uncomment later
    /*isValid = false;
    consoleLog({
      ...consoleLogSample,
      message: 'Missing parent category name (the product ignored)',
    });*/
  }
  if (product.sellable === undefined || product.sellable === null) {
    isValid = false;
    consoleLog({
      ...consoleLogSample,
      message: 'Missing sellable (the product ignored)',
    });
  }
  if (!product.name) {
    isValid = false;
    consoleLog({
      ...consoleLogSample,
      message: 'Missing name (the product ignored)',
    });
  }
  if (product.price === undefined || product.price === null || product.price <= 0) {
    isValid = false;
    consoleLog({
      ...consoleLogSample,
      message: 'Missing price (the product ignored)',
    });
  }
  if (product.isFavourite === undefined || product.isFavourite === null) {
    product.isFavourite = false;
    consoleLog({
      ...consoleLogSample,
      type: 'warn',
      message: 'Missing isFavourite flag',
    });
  }
  if (product.sellable && product.sellable < 0) {
    product.sellable = 0;
    consoleLog({
      ...consoleLogSample,
      type: 'warn',
      message: 'Sellable is negative value',
    });
  }
  if (product.previewImage === undefined || product.previewImage === null || !product.previewImage.url) {
    consoleLog({
      ...consoleLogSample,
      type: 'warn',
      message: 'Missing preview image',
    });
  }
  if (product.previewImageThumb === undefined || product.previewImageThumb === null || !product.previewImageThumb.url) {
    consoleLog({
      ...consoleLogSample,
      type: 'warn',
      message: 'Missing preview image thumb',
    });
  }
  if (isValid) {
    product.offers = (product.offers as ProductOfferAPI[]).sort(
      ({ id: a }, { id: b }) => mainStore.sortNumbers(a, b));
    if (product.offers.length > 1 && product.isGrocery) {
      (product.offers as ProductOfferAPI[]).slice(1).forEach((offer) => {
        consoleLog({
          type: 'error',
          productId: id,
          offerId: offer.id,
          sku: offer.code,
          source: 'offer',
          message: 'Product type grocery must contain only one offer (the offer ignored)',
        });
      });
      product.offers = product.offers.slice(0, 1);
    }
    product.offers = (product.offers as ProductOfferAPI[]).filter(
      (offer) => validateOfferFields(offer, id));
    if (!product.offers.length) {
      isValid = false;
      consoleLog({
        ...consoleLogSample,
        message: 'Product does not have valid offers (the product ignored)',
      });
    }
  }
  return isValid ? product as ProductAPI & { isGrocery: boolean } : null;
}

/**
 * Validate required fields in ProductOfferAPI
 * */
function validateOfferFields(
  offer: PartialRecursively<ProductOfferAPI>,
  productId?: number,
): boolean {
  let isValid = true;
  const { id, code } = offer;
  const consoleLogSample: ConsoleLogArgs = {
    type: 'error',
    message: '',
    productId,
    offerId: id,
    sku: code,
    source: 'offer',
  };
  if (id === undefined || id === null) {
    isValid = false;
    consoleLog({
      ...consoleLogSample,
      message: 'Missing offer ID (the offer ignored)',
    });
  }
  if (!offer.code) {
    isValid = false;
    consoleLog({
      ...consoleLogSample,
      message: 'Missing SKU (the offer ignored)',
    });
  }
  if (offer.sellable === undefined || offer.sellable === null) {
    isValid = false;
    consoleLog({
      ...consoleLogSample,
      message: 'Missing sellable (the offer ignored)',
    });
  }
  if (!offer.name) {
    isValid = false;
    consoleLog({
      ...consoleLogSample,
      message: 'Missing name (the offer ignored)',
    });
  }
  if (offer.price === undefined || offer.price === null || offer.price <= 0) {
    isValid = false;
    consoleLog({
      ...consoleLogSample,
      message: 'Missing price (the offer ignored)',
    });
  }
  if (offer.discountPrice !== undefined && offer.discountPrice !== null && offer.discountPrice <= 0) {
    isValid = false;
    consoleLog({
      ...consoleLogSample,
      message: 'Discount price is less than or equal to zero (the offer ignored)',
    });
  }
  if ((offer.discountPrice || 0) >= (offer.price || 0)) {
    isValid = false;
    consoleLog({
      ...consoleLogSample,
      message: 'Discount price is greater than or equal to the price (the offer ignored)',
    });
  }
  if (
    offer.minBasketDiscountQuantity
    && (offer.minBasketDiscountPrice === undefined || offer.minBasketDiscountPrice === null || !offer.minBasketDiscountPrice)
  ) {
    isValid = false;
    consoleLog({
      ...consoleLogSample,
      message: 'promo_required_quantity is present but no promo_quantity_discount_price (the offer ignored)',
    });
  }
  if (offer.images === undefined || offer.images === null || !offer.images.length || !offer.images[0]?.url) {
    consoleLog({
      ...consoleLogSample,
      type: 'warn',
      message: 'Missing images',
    });
  }
  if (offer.sellable && offer.sellable < 0) {
    consoleLog({
      ...consoleLogSample,
      type: 'warn',
      message: 'Sellable is negative value',
    });
  }
  return isValid;
}

/**
 * Function 'mainStore.consoleLog' wrapper for easy use
 * */
function consoleLog({ type, message, offerId, productId, sku, source }: ConsoleLogArgs): void {
  message = `VALIDATE ${source.toUpperCase()} FIELDS - ${message}`;
  if (productId) {
    message += '\n\tproductId: ' + productId;
  }
  if (offerId) {
    message += '\n\tofferId: ' + offerId;
  }
  if (sku) {
    message += '\n\tsku: ' + sku;
  }
  mainStore.consoleLog(message, type);
}
