import { RequestAPI } from './Requests';
import { ProductAPI } from './Catalog';
import { ENDPOINT } from './constants';

export interface FavoritesResponse {
  products?: ProductAPI[];
}

interface PurchasedResponse {
  data: ProductAPI[];
  pagination: {
    total: number;
    page: number;
    size: number;
  };
}

interface FavoritesRequestsInterface {
  getFavorites(data: {
    warehouse_code: string;
    isSafeRequest?: boolean;
  }): Promise<FavoritesResponse>;

  addFavorite(ids: { product_id: number }[]): Promise<{ ok: boolean; errors: boolean }>;

  removeFavorite(id: number): Promise<{ ok: boolean; errors: boolean }>;

  getPurchasedItems(
    warehouseCode: string, pageNumber: number, limit: number): Promise<PurchasedResponse>;
}

export const FavoritesRequest: FavoritesRequestsInterface = {
  getFavorites: (data) =>
    RequestAPI.get(ENDPOINT.favorites.get, data),

  addFavorite: (data) =>
    RequestAPI.post(ENDPOINT.favorites.add, data),

  removeFavorite: (id) =>
    RequestAPI.post(ENDPOINT.favorites.remove + id),

  getPurchasedItems: (warehouseCode, pageNumber, limit) =>
    RequestAPI.get(
      ENDPOINT.orders.boughtItems + `?warehouse_code=${warehouseCode}&page=${pageNumber}&limit=${limit}`),
};
