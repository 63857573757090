import { observer } from 'mobx-react-lite';
import { userStore } from '../../../stores/UserStore';
import { checkoutStore } from '../../../stores/CheckoutStore';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BonusButton from './BonusButton/BonusButton';
import { ButtonType } from './interface';
import Promocode from './Promocode/Promocode';
import Icon from '../../../components/ui/Icon/Icon';
import PointsPopover from './PointsPopover/PointsPopover';
import { company } from '../../../company/Company';

export const JiffyPointsUseBonusesToggler = observer(() => {
  const { t } = useTranslation();
  const [showPointsPopover, setShowPointsPopover] = useState(false);

  const isPointsDisabled = !userStore.isAuthorized || !userStore.bonuses;

  useEffect(() => {
    if (isPointsDisabled) {
      checkoutStore.setUseBonuses(false);
    }
  }, [isPointsDisabled]);

  const onPromoButtonClickHandler = (type: ButtonType) => {
    if (type === 'promo') {
      checkoutStore.setUseBonuses(false);
    }

    if (type === 'points') {
      if (isPointsDisabled) {
        setShowPointsPopover(true);
        return;
      }

      checkoutStore.setUseBonuses(true);
    }
  };

  const onCloseBonusesPopoverHandler = () => {
    setShowPointsPopover(false);
  };

  return (
    <div className="d-flex flex-direction-column mt-24">
      <div className="fw-500 fw-possibly-bold">{t('cartPage:saveWithWidget:caption')}</div>

      <div className="mt-12 d-flex">
        <BonusButton
          isActive={!checkoutStore.useBonuses}
          buttonType="promo"
          onClick={onPromoButtonClickHandler}
        >
          {t('promos')}
        </BonusButton>

        <BonusButton
          isActive={checkoutStore.useBonuses}
          buttonType="points"
          onClick={onPromoButtonClickHandler}
        >
          {t('pointsPage:caption')}
          <Icon className="ml-4" type={isPointsDisabled ? 'faq' : company.config.points?.icon || 'coins'} />

          {!isPointsDisabled && <span data-testid='bonuses'>{userStore.bonuses}</span>}
        </BonusButton>
      </div>

      <div>
        {!checkoutStore.useBonuses && <Promocode />}

        <PointsPopover isShow={showPointsPopover} onDismiss={onCloseBonusesPopoverHandler} />
      </div>
    </div>
  );
});
