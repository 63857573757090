import htmlClasses from 'html-classes';
import { Link } from 'react-router-dom';
import React from 'react';
import { CategoryItemProps } from '../interfaces';
import { FALLBACK_BACKGROUND_COLOR } from './constants';

export const CategoryItem = (props: CategoryItemProps) => {
  const { tileSize, id, previewImagePath, name, backgroundColor, onClick, target, color } = props;

  return (
    <div
      role='listitem'
      className={htmlClasses('category-list__col', {
        _2: tileSize === 2,
        _3: tileSize === 3,
      })}
      id={`category-${id}`}
    >
      <div className="category-item">
        <Link
          className="link-abs category-item__link"
          to={target}
          onClick={() => onClick(id, 'homepage')}
        >
          <div
            className="category-item__img"
            role="img"
            style={{
              backgroundImage: previewImagePath ? `url(${previewImagePath})` : 'none',
              backgroundColor: backgroundColor ? backgroundColor : FALLBACK_BACKGROUND_COLOR,
              color: color ?? 'initial',
            }}
          >
            <div className="category-item-name px-10 py-8">{name}</div>
          </div>
        </Link>
      </div>
    </div>
  );
};
