import { RequestAPI } from './Requests';
import { ENDPOINT } from './constants';

export interface IStorySlideButton {
  action: string;
  x: number;
  y: number;
  text: string;
  paddingX: number;
  paddingY: number;
  fontSize: number;
  borderRadius: number;
  borderColor: string;
  backgroundColor: string;
  color: string;
  id: Unnecessary<string>;
}

interface IStorySlide {
  order: number;
  image: string;
  buttons: IStorySlideButton[];
  id: Unnecessary<string>;
}

interface IStorySchedule {
  id: string;
  day: string;
  timeFrom: string;
  timeTo: string;
}

export interface IStory {
  id: string;
  name: string;
  deepLink: string;
  mainImage: string;
  isStart: boolean;
  duration: number;
  endStory: string;
  order: number;
  items: IStorySlide[];
  blacklistedWarehouses: Unnecessary<string[]>;
  dateStart: Unnecessary<string>;
  dateEnd: Unnecessary<string>;
  active: Unnecessary<boolean>;
  createdAt: Unnecessary<string>;
  updatedAt: Unnecessary<string>;
  schedules: Unnecessary<IStorySchedule[]>;
}

interface StoriesRequestsInterface {
  getAllStories(warehouseCode: string): Promise<IStory[]>;
}

export const StoriesRequest: StoriesRequestsInterface = {
  getAllStories: (warehouseCode) =>
    RequestAPI.get(ENDPOINT.stories.list + warehouseCode),
};
