import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { mainStore } from '../stores/MainStore';

type CounterProps = {
  value: number;
  limit: number;
  onChange: (value: number, action: 'add' | 'remove') => void;
  className?: string;
  height?: number;
  addToCartCaption: string
};

export default observer(
  ({ value, limit, onChange, className, height, addToCartCaption }: CounterProps) => {
    const { t } = useTranslation();
    const handleSetCount = (increment: number) => {
      let newValue = value + increment;
      if (newValue < 0) newValue = 0;
      if (newValue > limit) newValue = limit;
      onChange(newValue, increment > 0 ? 'add' : 'remove');
      mainStore.sendToRN('hapticFeedback', {
        count: 1,
      });
    };

    return (
      <div className={htmlClasses('counter-wrap position-relative', className)}>
        {value > 0 ? (
          <div
            className={htmlClasses(
              'counter position-relative z-1',
              `${height ? 'h-' + height : ''}`,
            )}
          >
            <div className='icon icon-rtl icon-minus counter__icon' onClick={() => handleSetCount(-1)} />
            <div className='counter__val'>{value}</div>
            <div
              className={htmlClasses('icon icon-rtl icon-plus counter__icon', { _disabled: value >= limit })}
              onClick={() => handleSetCount(1)}
            />
          </div>
        ) : limit > 0 ? (
          <div
            className={htmlClasses(
              'counter-add position-relative z-1',
              `${height ? 'h-' + height : ''}`,
            )}
            onClick={() => handleSetCount(1)}
          >
            {addToCartCaption}
          </div>
        ) : (
          <div
            className={htmlClasses('counter-add _sold-out', `${height ? 'h-' + height : ''}`)}
          >
            {t('soldOut')}
          </div>
        )}
      </div>
    );
  },
);
