import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { userStore } from '../../../stores/UserStore';
import { mainStore } from '../../../stores/MainStore';
import pointsImg from '../../../assets/img/jiffy-points-empty.svg';
import { Link } from 'react-router-dom';
import ExternalLink from '../../../components/ExternalLink';
import React from 'react';
import { notAuthorizedDetails, authorizedDetails } from '../constants';
import { orderStore } from '../../../stores/OrderStore';
import { company } from '../../../company/Company';
import Icon from '../../../components/ui/Icon/Icon';

const PointsNotReceived = observer(() => {
  const { t } = useTranslation();
  const goToAuthHandler = () => {
    if (userStore.isAuthorized) {
      return;
    }

    mainStore.sendAnalytics(['analytics'], {
      name: 'Loyalty: Log In',
      params: {
        source: 'Loyalty_page',
      },
    });
  };

  return (
    <>
      <div className="mx-6 position-relative">
        <div className="points-container position-absolute">
          <Icon type={company.config.points?.icon || 'coins'} size={58} className="d-flex flex-center c-white" />
          <p className="points-text m-0 c-white">{company.config.points?.value || ''}</p>
        </div>
        <img src={pointsImg} alt="" />
      </div>
      <div className="d-flex flex-direction-column text-center justify-content-center c-corange mt-8 mb-40">
        <div className="fs-36 lh-36">{t('pointsPage:caption')}</div>
        <div className="fs-14 lh-19 mt-8">{t('pointsPage:anotherWayTo')}</div>
      </div>
      {!mainStore.analytics.totalSuccessfulOrders && (
        <Link
          className="c-bg-salad4 br-8 px-12 py-10 d-flex align-items-center mb-40 mx-16"
          onClick={goToAuthHandler}
          to={{
            pathname: userStore.isAuthorized ? '/' : '/auth',
            state: { redirectToPage: 'points' },
          }}
        >
          <div className="icon icon-rtl icon-star d-flex flex-center s-20 mr-12" />
          <div className="d-flex flex-direction-column">
            <div className="lh-19 c-black fs-14 mb-4">
              {t('pointsPage:firstOrder')}
            </div>
            <div className="lh-14 c-black fs-12">
              {t(`pointsPage:${userStore.isAuthorized ? 'getYourPoints' : 'logInAndGet'}`)}
            </div>
          </div>
        </Link>
      )}

      {userStore.isAuthorized
        ? authorizedDetails.map((item, i) => (
          <div className="mx-24 d-flex align-items-start mb-24" key={i}>
            <div className="icon__number s-22 mr-12">{i + 1}</div>
            <div className="d-flex flex-direction-column">
              <div className="lh-19 c-black fs-14 mb-4">
                {t(`pointsPage:${item.firstLine}`)}
              </div>
              <div className="lh-14 c-black fs-12">
                {t(
                  `pointsPage:${item.secondLine}`,
                  {
                    amount: company.config.points?.pointsPerUnit,
                    currencySymbol: orderStore.currencySymbol,
                    companyName: company.config.name,
                  },
                )}
              </div>
            </div>
          </div>
        ))
        : notAuthorizedDetails.map((item, i) => (
          <div className="mx-24 d-flex align-items-start mb-24" key={i}>
            <div className="icon__number s-22 mr-12">{i + 1}</div>
            <div className="d-flex flex-direction-column">
              <div className="lh-19 c-black fs-14 mb-4">
                {t(`pointsPage:${item.firstLine}`)}
              </div>
              <div className="lh-14 c-black fs-12">
                {t(
                  `pointsPage:${item.secondLine}`,
                  {
                    amount: company.config.points?.pointsPerUnit,
                    currencySymbol: orderStore.currencySymbol,
                    companyName: company.config.name,
                  },
                )}
              </div>
            </div>
          </div>
        ))}

      <Link
        onClick={goToAuthHandler}
        to={{
          pathname: userStore.isAuthorized ? '/' : '/auth',
          state: { redirectToPage: 'points' },
        }}
        className="mx-24 c-bg-corange c-white br-8 d-flex justify-content-center align-items-center py-12 mt-40 mb-24"
      >
        {t(`pointsPage:${userStore.isAuthorized ? 'goShopping' : 'logInAndStart'}`)}
      </Link>

      <ExternalLink
        className="c-blue d-flex justify-content-center"
        to={{ pathname: '/inject', state: { page: 'terms' } }}
        href={company.config.links.terms}
      >
        {t('aboutPage:termsAndConditions')}
      </ExternalLink>
    </>
  );
});

export default PointsNotReceived;
