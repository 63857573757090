import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { catalogStore } from '../../../../stores/CatalogStore';
import { userStore } from '../../../../stores/UserStore';
import htmlClasses from 'html-classes';
import { orderStore } from '../../../../stores/OrderStore';
import { checkoutStore } from '../../../../stores/CheckoutStore';
import { PICKUP_ALCOHOL_COUPON_CODE } from '../../../../stores/constants';
import { mainStore } from '../../../../stores/MainStore';
import { company } from '../../../../company/Company';

const Promocode = observer(() => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [promocodeVal, setPromocodeVal] = useState(catalogStore.promocode.value || '');
  const [warningMsg, setWarningMsg] = useState('');
  const handlePromocodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPromocodeVal(e.currentTarget.value.trim());
  };
  const handleDelPromocode = () => {
    setPromocodeVal('');
    catalogStore.resetPromocode();
  };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    checkPromocode(promocodeVal);
  };

  const checkPromocode = (val: string) => {
    if (!val.length) return;
    setIsLoading(true);
    catalogStore
      .applyPromocode(val, 'cart')
      .then(() => {
        if (catalogStore.promocode.deliveryMethod) {
          checkoutStore.setDeliveryMethod(catalogStore.promocode.deliveryMethod);
        }
      })
      .catch((error) => error && console.error(error))
      .finally(() => setIsLoading(false));
  };

  const setPromocode = (val: string) => {
    if (!val?.length || val === promocodeVal.toUpperCase()) return;
    setPromocodeVal(val);
    checkPromocode(val);
  };

  useEffect(() => {
    if (!catalogStore.totalCartPrice.amountLeftToDiscount || mainStore.isZero(
      catalogStore.totalCartPrice.amountLeftToDiscount)) {
      setWarningMsg('');
      return;
    }

    if (!catalogStore.isPromoCodeApplied && catalogStore.promocode.coupon?.type) {
      let msg = catalogStore.promocode.coupon?.code === PICKUP_ALCOHOL_COUPON_CODE ? 'errors:promocodePriceLessNoAlcohol' : 'errors:promocodePriceLess';

      if (!company.isExceptionAlcohol) msg = 'errors:promocodePriceLessNoAlcohol';

      setWarningMsg(
        t(
          msg,
          {
            amountLeft: mainStore.addCurrencySymbol(
              catalogStore.totalCartPrice.amountLeftToDiscount),
            discount: catalogStore.formatPromocodeDiscountAmount,
          },
        ),
      );
    } else setWarningMsg('');
    //eslint-disable-next-line
  }, [catalogStore.totalCartPrice]);

  useEffect(() => {
    if (!catalogStore.promocode.value) return;
    checkPromocode(catalogStore.promocode.value);
  }, [JSON.stringify(orderStore.etaCalculation?.cost)]);

  useEffect(() => {
    catalogStore.checkProposedPromocodes().catch((error) => error && console.error(error));
    //eslint-disable-next-line
  }, [userStore.isAuthorized]);

  return (
    <form onSubmit={handleSubmit}>
      {catalogStore.proposedPromocodes.length > 0 && (
        <div className="br-8 c-bg-white border-tf2 px-8 mt-10">
          {catalogStore.proposedPromocodes.map((item, i) => (
            <React.Fragment key={item.name}>
              {i > 0 && <div className="h-1 c-bg-tf2 pe-n" />}
              <div className="d-flex align-items-center py-10 pl-4">
                <div className="br-3 w-38 h-24 fs-12 fw-500 text-center lh-24 c-white flex-shrink-0 c-bg-dorange">
                  {item.discount}
                </div>
                <div className="w-100p ml-14 mr-20 fs-12 lh-16">
                  <div className="fw-500 c-udgray">{item.title}</div>
                  <div className="c-t99">{item.text}</div>
                </div>
                <div
                  className="icon icon-rtl icon-plus s-28 c-bg-dgreen d-flex flex-center fs-22 br-3 c-white"
                  onClick={() => setPromocode(item.name)}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
      <div
        className={htmlClasses('promocode mt-10', {
          _filled: promocodeVal.length && catalogStore.promocode.success === null && !isLoading,
          _error: catalogStore.promocode.errorType === 'error' && !isLoading,
          _warning: (catalogStore.promocode.errorType === 'warning' || warningMsg) && !isLoading,
          _success: catalogStore.promocode.success === true && !warningMsg && !isLoading,
        })}
      >
        <button className="promocode__btn">{t('apply')}</button>
        <div
          className="promocode__icon icon-rtl icon icon-rtl icon-close s-28 d-flex flex-center fs-16 c-bg-red c-white rounded-circle position-absolute z-4"
          onClick={handleDelPromocode}
        />
        <div className="promocode__icon icon-rtl icon icon-rtl icon-exclamation s-28 d-flex flex-center fs-16 c-bg-orange c-white pe-n rounded-circle position-absolute" />
        <div className="promocode__icon icon-rtl icon icon-rtl icon-accepted s-28 d-flex flex-center fs-16 c-bg-dgreen c-white pe-n rounded-circle position-absolute" />
        {isLoading && <div className="spinner position-absolute" />}
        <input
          className={htmlClasses('input-text', { 'pe-n': catalogStore.promocode.success !== null })}
          type="text"
          value={promocodeVal}
          onChange={handlePromocodeChange}
          placeholder={t('promocode')}
        />
      </div>
      {catalogStore.promocode.success !== null && !isLoading && (
        <div
          className="fs-14 c-blue fw-500 text-right mt-10"
          onClick={handleDelPromocode}
        >
          {t('cartPage:deletePromocode')}
        </div>
      )}
      {catalogStore.isCharityPromocode && !isLoading && (
        <div className="alert _success mt-16 z-1">{t('cartPage:charityPromocode')}</div>
      )}
      {catalogStore.promocode.success !== null && catalogStore.promocode.message && !isLoading && (
        <div
          className={`alert _${
            catalogStore.promocode.success
              ? catalogStore.promocode.errorType || 'success'
              : catalogStore.promocode.errorType
          } mt-16 z-1`}
          dangerouslySetInnerHTML={{ __html: catalogStore.promocode.message }}
        />
      )}

      {catalogStore.promocode.success !== null && catalogStore.promocode.deliveryMethod && (
        <div
          className={`alert _${
            catalogStore.promocode.success
              ? catalogStore.promocode.errorType || 'success'
              : catalogStore.promocode.errorType
          } mt-16 z-1`}
        >{t('cartPage:promocodeAlcoholPickup')}</div>
      )}

      {warningMsg && !isLoading && <div className="alert _warning mt-16 z-1">{warningMsg}</div>}
      {catalogStore.promocode.success &&
        !catalogStore.isCharityPromocode &&
        catalogStore.isRestrictedItemInCart &&
        !isLoading &&
        !warningMsg && (
          <div className="alert _warning mt-16 z-1">
            {!company.isExceptionAlcohol ? t('cartPage:promocodeExceptionAlcoholPickup') : (
              t(
                catalogStore.promocode.coupon?.code === PICKUP_ALCOHOL_COUPON_CODE ? 'cartPage:promocodeExceptionAlcoholPickup' : 'cartPage:promocodeException')
            )}
          </div>
        )}
    </form>
  );
});

export default Promocode;
