import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import ScrollRestoration from '../components/ScrollRestoration';
import { scrollTo } from '../UI';
import { mainStore } from '../stores/MainStore';
import { catalogStore } from '../stores/CatalogStore';
import htmlClasses from 'html-classes';
import Skeleton from 'react-loading-skeleton';
import ProductList from '../components/ProductList';
import { Product } from '../types/Product/interface';

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const [products, setProducts] = useState<Product[] | null>(null);
  const [isReady, setIsReady] = useState(false);
  const handleSearchLink = () => {
    catalogStore.setSearchQuery('');
    mainStore.sendToRN('analytics', {
      name: 'Catalog: search clicked',
      params: {
        source: 'category',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'catalog_search_clicked',
      params: {
        source: 'category',
      },
    });
  };
  const handleShowNotFindProductPopover = () => mainStore.setIsNotFindProductPopover(true);
  const handleScrollTop = () => scrollTo('scroll-layout', 0, 200);

  useEffect(() => {
    catalogStore
      .requestBestsellers()
      .then((e) => {
        setProducts(e);
        if (!e.length) return;
        mainStore.sendToRN('analytics', {
          name: 'Catalog: Bestsellers visited',
          params: {
            source: 'homepage',
          },
        });
        mainStore.sendToRN('firebaseAnalytics', {
          name: 'catalog_bestsellers_visited',
          params: {
            source: 'homepage',
          },
        });
      })
      .catch((error) => error && console.error(error));
  }, []);

  return (
    <>
      {products && isReady && <ScrollRestoration pageName='bestsellers' />}
      <div className='d-flex h-50 flex-shrink-0 align-items-center'>
        <div
          className='icon icon-rtl icon-arrow-back d-flex flex-center s-50 fs-20 c-black'
          onClick={() => (history.length === 1 ? history.push('/') : history.goBack())}
        />
        <h3 className='text-center w-100p text-truncate'>{t('bestsellers')}</h3>
        <Link
          className='icon icon-rtl icon-search d-flex flex-center s-50 fs-20'
          to={{ pathname: '/search', state: { source: 'category' } }}
          onClick={handleSearchLink}
        />
      </div>
      <div className={htmlClasses('scroll-layout h-100p px-24', { 'overflow-hidden': !products })}>
        {products !== null ? (
          products.length > 0 ? (
            <>
              <ProductList
                productList={products}
                source='bestsellers_slider'
                onReady={() => setIsReady(true)}
                showThreeInLine
              />
              <div
                className='button _bordered w-100p mt-22'
                onClick={handleShowNotFindProductPopover}
              >
                {t('categoryPage:canNotFindItems')}
              </div>
              <div className='button _bordered w-100p mt-10' onClick={handleScrollTop}>
                {t('backToTop')}
              </div>
            </>
          ) : (
            <div className='text-center p-28 fs-14 c-gray'>{t('comingSoon')}</div>
          )
        ) : (
          <div className='d-flex mx-n4 flex-wrap'>
            {[1, 2, 3, 4].map((subitem, j) => (
              <div className='w-50p px-4 pb-8 flex-shrink-0' key={j}>
                <Skeleton className='product-item br-8 h-200' />
              </div>
            ))}
          </div>
        )}
        <div className='h-24' />
      </div>
    </>
  );
});
