import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link, useHistory, useLocation } from 'react-router-dom';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import SwiperClass from 'swiper/types/swiper-class';
import MainHeader from '../../components/MainHeader';
import OrderStatusWidget from '../../components/OrderStatusWidget';
import ScrollRestoration from '../../components/ScrollRestoration';
import { mainStore } from '../../stores/MainStore';
import { catalogStore } from '../../stores/CatalogStore';
import { Banner } from '../../api/Catalog';
import { BANNERS_SLIDER_DURATION } from '../../config';
import { orderStore } from '../../stores/OrderStore';
import { storiesStore } from '../../stores/StoriesStore';
import SearchDummy from '../../components/SearchDummy';
import Bestsellers from './Bestsellers/Bestsellers';
import GrabAgain from './GrabAgain/GrabAgain';
import StoriesList from './StoriesList/StoriesList';
import CategoryList from './CategoryList/CategoryList';

SwiperCore.use([Autoplay]);

type BannersListSliderProps = {
  banners: Banner[];
};

const BannersListSlider = observer(({ banners }: BannersListSliderProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleSlideChange = (swiper: SwiperClass) => setActiveIndex(swiper.activeIndex);
  const handleClick = (bannerId: number) => {
    mainStore.sendToRN('logAmplitudeEvent', {
      name: 'Home Page: Banner open',
      params: {
        banner_id: bannerId,
      },
    });
  };

  return (
    <>
      <Swiper
        className='category-slider mt-16'
        slidesPerView={1}
        autoplay={{
          delay: BANNERS_SLIDER_DURATION,
          disableOnInteraction: false,
        }}
        onSlideChange={(swiper) => handleSlideChange(swiper)}
      >
        {banners.map((item) => (
          <SwiperSlide className='px-16' key={item.id}>
            <div className='category-item'>
              <div
                className='category-item__img'
                style={{
                  backgroundImage: item.preview_image?.path
                    ? `url(${item.preview_image.path})`
                    : 'none',
                }}
              />
              {item.offer?.length > 0 && (
                <Link
                  className='link-abs category-item__link'
                  to={`/banner/${item.id}`}
                  onClick={() => handleClick(item.id)}
                />
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className='slider__dots mt-8'>
        {banners.map((item, i) => (
          <div className={htmlClasses('slider__dot', { _active: i === activeIndex })} key={i} />
        ))}
      </div>
    </>
  );
});

const BannersList = observer(() => {
  const handleClick = (bannerId: number) => {
    mainStore.sendToRN('logAmplitudeEvent', {
      name: 'Home Page: Banner open',
      params: {
        banner_id: bannerId,
      },
    });
  };

  return (
    <>
      {catalogStore.bannerList !== null ? (
        catalogStore.bannerList.items.length > 0 &&
        (catalogStore.bannerList.displayType === 'slider' ? (
          <BannersListSlider banners={catalogStore.bannerList.items} />
        ) : (
          <div className='category-list d-flex flex-wrap px-11'>
            {catalogStore.bannerList.items.map((item) => (
              <div
                className={htmlClasses('category-list__col', {
                  _2: item.tile_size === 2,
                  _3: item.tile_size === 3,
                })}
                key={item.id}
              >
                <div className='category-item'>
                  <div
                    className='category-item__img'
                    style={{
                      backgroundImage: item.preview_image?.path
                        ? `url(${item.preview_image.path})`
                        : 'none',
                    }}
                  />
                  {item.offer?.length > 0 && (
                    <Link
                      className='link-abs category-item__link'
                      to={`/banner/${item.id}`}
                      onClick={() => handleClick(item.id)}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        ))
      ) : (
        <SkeletonTheme color='#EBECED' highlightColor='#F7F7F7'>
          <div className='d-flex align-items-start flex-wrap px-11 mt-16'>
            {[1, 2, 3].map((subitem, j) => (
              <div className='category-list__col' key={j}>
                <Skeleton className='pt-100p br-8' />
              </div>
            ))}
          </div>
        </SkeletonTheme>
      )}
    </>
  );
});

export default observer(() => {
  const history = useHistory();
  const { state } = useLocation<{ scrollToCategoryId?: string }>();

  useEffect(() => {
    catalogStore.requestCategories();
    catalogStore.requestBanners();
    storiesStore.requestStories().then(() => {
      const startStory = storiesStore.stories.find((story) => story.isStart);
      if (startStory && !storiesStore.isStoryOpened(startStory.id)) {
        history.push(`/stories/${startStory.id}`);
      }
    }).catch((error) => error && console.error(error));
    //eslint-disable-next-line
  }, [orderStore.etaCalculation?.warehouse?.code]);

  useEffect(() => {
    if (!state || !state.scrollToCategoryId) return;

    const categoryEl = document.getElementById(`category-${state.scrollToCategoryId}`);

    if (categoryEl) {
      categoryEl.scrollIntoView({ behavior: 'auto', block: 'center' });

      window.history.replaceState({}, document.title);
    }
    //eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    mainStore.sendToRN('analytics', {
      name: 'Catalog: home page visited',
      params: {
        warehouse_code: orderStore.etaCalculation?.warehouse.code ?? '',
      },
    });
  }, []);

  return (
    <>
      <ScrollRestoration pageName='main' />
      <MainHeader />
      <div className='scroll-layout h-100p position-relative main-page'>
        <OrderStatusWidget />
        <SearchDummy className='mx-16 mt-8 mb-16 d-block' />
        <StoriesList />
        <BannersList />
        {mainStore.analytics.totalSuccessfulOrders ? <GrabAgain /> : <Bestsellers />}
        <CategoryList />
        <div className='h-24' />
      </div>
    </>
  );
});
