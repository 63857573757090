import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Popover from './Popover';

type PopoverAttentionChangeAddressProps = {
  isShow: boolean;
  onDismiss: () => void;
};

export default observer(({ isShow, onDismiss }: PopoverAttentionChangeAddressProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const handleChange = () => {
    onDismiss();
    history.push({
      pathname: '/delivery-address',
      state: { isShowBackBtn: true, source: pathname.slice(1) },
    });
  };

  return (
    <Popover isShow={isShow} onBackdropDismiss={onDismiss}>
      <div className="d-flex align-items-center">
        <div className="icon icon-rtl icon-attention s-20 fs-20 d-flex flex-center c-red mr-12" />
        <div className="fs-21 fw-500">{t('attention')}</div>
      </div>
      <div className="fs-14 lh-20 mt-20">{t('cartPage:changeAddress')}</div>
      <div className="d-flex align-items-center justify-content-end mt-24">
        <div className="h-40 mie-20 px-10 lh-40 c-blue fs-14 fw-500" onClick={onDismiss}>
          {t('cancel')}
        </div>
        <div className="button _primary h-40 fs-14" onClick={handleChange}>
          {t('change')}
        </div>
      </div>
    </Popover>
  );
});
