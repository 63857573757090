import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Order } from '../api/Order';
import { REQUEST_ORDER_STATUS_INTERVAL } from '../config';
import { userStore } from '../stores/UserStore';
import { mainStore } from '../stores/MainStore';
import { orderStore } from '../stores/OrderStore';
import { catalogStore } from '../stores/CatalogStore';
import htmlClasses from 'html-classes';
import { company } from '../company/Company';

type PromoBlockProps = {
  isFeedbackAllowed: boolean;
};

export default observer(() => {
  const { t } = useTranslation();
  const [isFeedbackAllowed, setIsFeedbackAllowed] = useState(false);

  useEffect(() => {
    if (!userStore.isAuthorized) return;
    if (orderStore.activeOrderID && orderStore.orderStatus !== 'none') return;
    orderStore
      .fetchOrders()
      .then(() => {
        orderStore.setActiveOrderID('');
        orderStore.setOrderStatus('none');
        if (!orderStore.orderList?.length) return;
        for (let i = 0; i < orderStore.orderList.length; i++) {
          if (
            orderStore.orderList[i].public_status === 'delivered' ||
            orderStore.orderList[i].public_status === 'failed' ||
            orderStore.orderList[i].public_status === 'cancelled'
          )
            continue;
          orderStore.setActiveOrderID(orderStore.orderList[i].id);
          orderStore.setActiveOrderBonuses(orderStore.orderList[i].received_bonuses);
          orderStore.setOrderStatus(orderStore.orderList[i].public_status);
          orderStore.setActiveOrderProductCount(orderStore.orderList[i].itemsCount.requested);
          break;
        }
        if (orderStore.activeOrderID) return;
        for (let i = 0; i < orderStore.orderList.length; i++) {
          if (orderStore.orderList[i].public_status === 'delivered') {
            if (orderStore.orderList[i].has_feedback) break;
            setIsFeedbackAllowed(false);
            orderStore.setActiveOrderID(orderStore.orderList[i].id);
            orderStore.setActiveOrderBonuses(orderStore.orderList[i].received_bonuses);
            orderStore.setOrderStatus(orderStore.orderList[i].public_status);
            orderStore.setActiveOrderProductCount(orderStore.orderList[i].itemsCount.requested);
            break;
          }
        }
      })
      .catch((error) => error && console.error(error));
    //eslint-disable-next-line
  }, [userStore.isAuthorized]);

  useEffect(() => {
    if (!userStore.isAuthorized) return;
    userStore.requestBonuses().catch((error) => error && console.error(error));
    if (!orderStore.activeOrderID) return;
    const getOrderStatus = () => {
      orderStore
        .requestOrder(orderStore.activeOrderID)
        .then((e) => {
          if (!e) {
            orderStore.setOrderStatus('none');
            orderStore.setActiveOrderID('');
            orderStore.setActiveOrderProductCount(0);
            return;
          }
          if (e.public_status === 'delivered') {
            if (e.has_feedback || (e as Order).finishedMoreThanDayAgo) {
              orderStore.setOrderStatus('none');
              orderStore.setActiveOrderID('');
              orderStore.setActiveOrderProductCount(0);
              setIsFeedbackAllowed(false);
              mainStore.setDeferedRateOrder(false);
              return;
            } else {
              setIsFeedbackAllowed(true);
              if (mainStore.deferedRateOrder) {
                mainStore.setIsRateOrderPopover(true);
                mainStore.setDeferedRateOrder(false);
              }
            }
          }
          orderStore.setOrderStatus(e.public_status);
        })
        .catch(() => {
          orderStore.setOrderStatus('none');
          orderStore.setActiveOrderID('');
          orderStore.setActiveOrderProductCount(0);
        });
    };
    getOrderStatus();
    const internal = setInterval(getOrderStatus, REQUEST_ORDER_STATUS_INTERVAL);
    return () => clearInterval(internal);
    //eslint-disable-next-line
  }, [orderStore.orderStatus, userStore.isAuthorized]);

  useEffect(() => {
    catalogStore.checkProposedPromocodes().catch((error) => error && console.error(error));
    //eslint-disable-next-line
  }, [
    userStore.isAuthorized,
    mainStore.analytics.totalSuccessfulOrders,
    orderStore.etaCalculation?.warehouse.code,
  ]);

  if (!userStore.isAuthorized) return <PromoBlock isFeedbackAllowed={isFeedbackAllowed} />;

  if (orderStore.orderStatus === 'accepted')
    return (
      <Link
        className="status-widget position-relative br-8 mx-16 mt-5 d-flex align-items-center"
        to={`/status/${orderStore.activeOrderID}`}
      >
        <div className="status-widget__icon-stage icon icon-rtl icon-basket h-100p align-items-center" />
        <div className="status-widget__icon-arrow icon icon-rtl icon-chevron-right h-100p align-items-center" />
        <div>
          <div className="fs-14 ">{t('statusWidget:accepted.title')}</div>
        </div>
      </Link>
    );

  if (orderStore.orderStatus === 'picking' || orderStore.orderStatus === 'readyToShip')
    return (
      <Link
        className="status-widget position-relative br-8 mx-16 mt-5 d-flex align-items-center"
        to={`/status/${orderStore.activeOrderID}`}
      >
        <div className="status-widget__icon-stage icon icon-rtl icon-bag h-100p align-items-center" />
        <div className="status-widget__icon-arrow icon icon-rtl icon-chevron-right h-100p align-items-center" />
        <div>
          <div className="fs-14 ">
            {t(`statusWidget:${orderStore.orderStatus}.title`)}
          </div>
          <div className="fs-12  mt-2">
            {t(orderStore.activeOrderProductCount === 1 ?
              `statusWidget:${orderStore.orderStatus}.${orderStore.activeOrderProductCount > 1 ? 'text_other' : 'text'}` : `statusWidget:${orderStore.orderStatus}.text_other`, {
              count: orderStore.activeOrderProductCount,
            })}
          </div>
        </div>
      </Link>
    );

  if (orderStore.orderStatus === 'inDelivery')
    return (
      <Link
        className="status-widget position-relative br-8 mx-16 mt-5 d-flex align-items-center"
        to={`/status/${orderStore.activeOrderID}`}
      >
        <div className="status-widget__icon-stage icon icon-rtl icon-delivery h-100p align-items-center" />
        <div className="status-widget__icon-arrow icon icon-rtl icon-chevron-right h-100p align-items-center" />
        <div>
          <div className="fs-14 ">{t('statusWidget:inDelivery.title')}</div>
          <div className="fs-12 mt-2">
            {t(`statusWidget:inDelivery.${orderStore.activeOrderProductCount > 1 ? 'text_other' : 'text'}`,
            { count: orderStore.activeOrderProductCount })}
          </div>
        </div>
      </Link>
    );

  if (orderStore.orderList !== null) return <PromoBlock isFeedbackAllowed={isFeedbackAllowed} />;

  return <></>;
});

const RateOrderStatusWidget = observer(() => {
  const { t } = useTranslation();
  const handleRate = () => mainStore.setIsRateOrderPopover(true);

  useEffect(() => {
    if (orderStore.activeOrderID === orderStore.lastRatedOrderID) return;
    handleRate();
  }, []);

  return (
    <div
      className={htmlClasses(
        'status-widget position-relative br-8 d-inline-flex align-items-center',
        catalogStore.proposedPromocodes.length ? 'flex-shrink-0' : 'w-100p',
      )}
      onClick={handleRate}
    >
      <div className="status-widget__icon-stage icon icon-rtl icon-star h-100p align-items-center" />
      <div className="status-widget__icon-arrow icon icon-rtl icon-chevron-right h-100p align-items-center" />
      <div>
        <div className="fs-14">{t('statusWidget:delivered.title')}</div>
        <div className="fs-12 mt-2">
          {t(`statusWidget:delivered.${orderStore.activeOrderProductCount > 1 ? 'text_other' : 'text'}`,
          { count: orderStore.activeOrderProductCount })}
        </div>
      </div>
    </div>
  );
});

const PromoBlock = observer(({ isFeedbackAllowed }: PromoBlockProps) => {
  const { t } = useTranslation();
  const handleApplyPromocode = () => {
    catalogStore
      .applyPromocode(catalogStore.proposedPromocodes[0].name, 'homepage')
      .then((e) => {
        if (!e) return;
        if (!catalogStore.isPromoCodeApplied && catalogStore.isFixedPromoCode) {
          mainStore.pushAlert(
            'warning',
            t('errors:promocodePriceLess', {
              amountLeft: mainStore.addCurrencySymbol(
                catalogStore.totalCartPrice.amountLeftToDiscount),
              discount: catalogStore.formatPromocodeDiscountAmount,
            }),
          );
        } else mainStore.pushAlert('success', t('cartPage:discountApplied'));
      })
      .catch((error) => error && console.error(error));
  };

  if (orderStore.orderStatus === 'delivered' && isFeedbackAllowed)
    return (
      <div className="overflow-auto px-16 mt-5 hide-scroll-bar text-nowrap">
        <RateOrderStatusWidget />
        {catalogStore.proposedPromocodes.length > 0 && (
          <div
            className="status-widget _discount position-relative br-8 ml-16 d-inline-flex align-items-start"
            onClick={handleApplyPromocode}
          >
            <PromoBlockContent />
          </div>
        )}
      </div>
    );

  if (catalogStore.proposedPromocodes.length)
    return (
      <div
        className="status-widget _discount position-relative br-8 mx-16 mt-5 d-flex align-items-start"
        onClick={handleApplyPromocode}
      >
        <PromoBlockContent />
      </div>
    );

  return <ReferralBlock />;
});

const PromoBlockContent = observer(() => {
  if (!catalogStore.proposedPromocodes.length) return <></>;

  return (
    <>
      <div className="status-widget__icon-arrow icon icon-rtl icon-chevron-right" />
      <div className="icon icon-rtl icon-spark d-flex flex-center s-20 fs-20 c-white mr-6 ml-3" />
      <div>
        <div className="fs-14 fw-500">{catalogStore.proposedPromocodes[0].title}</div>
        <div className="fs-11 mt-2 lh-14">{catalogStore.proposedPromocodes[0].text}</div>
      </div>
    </>
  );
});

const ReferralBlock = observer(() => {
  const { t } = useTranslation();
  let discount = company.config.referral.discount;

  if (!discount.includes('%')) discount = mainStore.addCurrencySymbol(discount);

  if (
    !userStore.isAuthorized ||
    company.isDisableLoyaltyProgram ||
    !mainStore.analytics.totalSuccessfulOrders
  ) {
    return <></>;
  }

  return (
    <Link
      className="status-widget _discount position-relative br-8 mx-16 mt-5 d-flex align-items-start"
      to={{ pathname: '/referral', state: { source: 'homepage' } }}
    >
      <div className="status-widget__icon-arrow icon icon-rtl icon-chevron-right" />
      <div className="icon icon-rtl icon-spark d-flex flex-center s-20 fs-20 c-lyellow mr-6 ml-3" />
      <div>
        <div className="fs-14 fw-500">
          {t(`referral:widgetTitle${discount.includes('%') ? 'Percent' : ''}`, {
            discount,
            companyName: company.config.name,
          })}
        </div>
        <div className="fs-12 mt-2 lh-14">{t('referral:widgetText')}</div>
      </div>
    </Link>
  );
});
