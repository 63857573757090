import IMask from 'imask';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { AnyMasked, IMaskInput } from 'react-imask';
import InputText from '../InputText';
import { DEFAULT_PLACEHOLDER } from './constants';

export type InputPhoneProps = {
  value: string;
  label: string;
  onValidate: (flag: boolean) => void;
  onChange?: (val: string) => void;
  onFocus?: (val: string) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
};

const maskData: (IMask.AnyMaskedOptions & { startsWith: string })[] = [
  {
    mask: '+00 (0000) 000-000',
    startsWith: '44',
    lazy: false,
  },
  {
    mask: '+0 (000) 000-00-00',
    startsWith: '7',
    lazy: false,
  },
  {
    mask: '+0000000000[00000]',
    startsWith: '',
  },
];

const maskDispatch = (appended: string, dynamicMasked: AnyMasked): AnyMasked => {
  const number = (dynamicMasked.value + appended).replace(/\D/g, '');
  return dynamicMasked.compiledMasks.find((m: AnyMasked) => number.indexOf(m.startsWith) === 0);
};

export default observer(
  ({
    value,
    label,
    onValidate,
    onChange,
    onFocus,
    placeholder = DEFAULT_PLACEHOLDER,
    className,
    disabled = false,
  }: InputPhoneProps) => {
    const [isValid, setIsValid] = useState(false);
    const handleChange = (val: string, mask: AnyMasked) => {
      if (onChange) onChange(val);
      onValidate(mask.masked.isComplete);
      setIsValid(mask.masked.isComplete);
    };

    const handleFocus = (val: string, mask: AnyMasked) => {
      if (onFocus) onFocus(val);
    };

    useEffect(() => {
      if (!value) return;
      const masked = IMask.createMask({
        mask: maskData,
        dispatch: maskDispatch,
      });
      masked.resolve(value);
      onValidate(masked.isComplete);
      setIsValid(masked.isComplete);
      //eslint-disable-next-line
    }, []);

    return (
      <InputText
        className={className}
        label={label}
        value={value}
        placeholder={placeholder}
      >
        {isValid && <div className="icon icon-rtl icon-check input-text__icon icon-rtl _check" />}
        <IMaskInput
          className="input-text"
          placeholder={placeholder}
          mask={maskData}
          value={value}
          unmask={true}
          type="tel"
          onAccept={handleChange}
          dispatch={maskDispatch}
          disabled={disabled}
          onFocus={handleFocus}
        />
      </InputText>
    );
  },
);
