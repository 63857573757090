import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, ReactNode, InputHTMLAttributes } from 'react';

interface InputTextProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  value?: string;
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
  className?: string;
  classNameInput?: string;
  classNameLabel?: string;
  type?: string;
  isValid?: boolean;
  isError?: boolean;
  refObj?: React.RefObject<HTMLInputElement>;
}

export default observer(
  ({
    value = '',
    label,
    placeholder,
    onChange,
    onFocus,
    onBlur,
    children,
    className,
    classNameInput,
    classNameLabel,
    type,
    isValid,
    isError,
    maxLength,
    refObj,
  }: InputTextProps) => {
    return (
      <div
        className={htmlClasses('input-text__wrap', className, { _active: value, _error: isError })}
      >
        {children ? (
          children
        ) : (
          <>
            {isValid && <div className="icon icon-rtl icon-check input-text__icon icon-rtl _check" />}
            <input
              className={htmlClasses('input-text', classNameInput, { 'mt-0 h-48': !label })}
              type={type || 'text'}
              value={value}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder={placeholder}
              maxLength={maxLength}
              ref={refObj}
            />
          </>
        )}
        {label && (
          <div
            className={htmlClasses('input-text__label text-truncate', classNameLabel, {
              _active: placeholder,
            })}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        )}
        <div className="input-text__border" />
      </div>
    );
  },
);
