/**
 * @prettier
 */

export enum CompanyName {
  Jiffy = 'jiffy',
  Baqal = 'baqal',
  Swifft = 'swifft',
}

export type GtmConfig = { auth: string; preview: string };

export type CompanyConfig = {
  id: string;
  lang: string;
  name: string;
  officialName: string;
  email: string;
  phonePlaceholder: string;
  defaultPhoneCode?: string;
  ageRestricted: number;
  timeZone: string;
  minimalOrderFeeThreshold: number;
  useGoogleApiForGeocoding?: boolean;
  disabledPlaceholder?: {
    image?: string;
    alt?: string;
    backgroundColor?: string;
  },
  warehouse: {
    default: string;
    minimalOrderFeeWarehouseCodes: string[];
  };
  map: {
    center: GeoCoordinates;
    zoom: number;
    markers?: {
      position: GeoCoordinates;
      label?: string;
    }[];
  };
  links: {
    [key: string]: string;
    terms: string;
    privacy: string;
    cookie: string;
    deliveryZones: string;
    forRiders: string;
  };
  referral: {
    code: string;
    discount: string;
    minBasket: string;
    downloadAppLink: string;
  };
  points?: {
    value?: string;
    pointsPerUnit?: number;
    icon: 'coins' | 'jiffy-points';
  };
  appStoreUrl: {
    ios: string;
    android: string;
  };
  apis: {
    [api: string]: {
      token?: string | number;
      locale?: string;
      production?: GtmConfig | string;
      staging?: GtmConfig | string;
      test?: GtmConfig | string;
      development?: GtmConfig | string;
      gtmId?: string;
      url?: string;
      widgetId?: string;
    };
  };
  langs: string[];
};
