import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Popover from './Popover';
import { userStore } from '../stores/UserStore';
import { ETAPaymentMethod } from '../api/ETA';
import ExternalLink from './ExternalLink';
import { company } from '../company/Company';
import { CompanyName } from '../company/interface';
import FCButton from './FCButton';

type PopoverChangeAddressProps = {
  isShow: boolean;
  onDismiss: () => void;
  type: 'missingItems' | 'notDelivered';
  paymentMethod: ETAPaymentMethod;
};

export default observer(({ isShow, onDismiss, type, paymentMethod }: PopoverChangeAddressProps) => {
  const { t } = useTranslation();

  return (
    <Popover isShow={isShow} onBackdropDismiss={onDismiss}>
      <div className="fs-21">
        {type === 'notDelivered'
          ? t('orderPage:orderWasNotDelivered')
          : t('orderPage:someItemsMissing')}
      </div>
      <div className="fs-14 lh-19 mt-15 ">
        {t('sorry')}
        {userStore.personalData.firstName ? ` ${userStore.personalData.firstName}` : ''},{' '}
        {type === 'notDelivered'
          ? t('orderPage:weCouldntDeliverButRefund')
          : t(
            `orderPage:weCouldntDeliverButRefundMissingItems${paymentMethod === ETAPaymentMethod.Cash ? 'Cash' : ''}`)}
      </div>
      {paymentMethod !== ETAPaymentMethod.Cash && (
        <>
          <div className="h-1 c-bg-tf2 mt-18" />
          <div className="fs-14 lh-19  mt-18">{t('orderPage:howSoonRefund')}</div>
          <div className="fs-12 lh-16 mt-6 c-mgray">{t('orderPage:weWillRefundYourMoney')}</div>
          {type === 'missingItems' && (
            <>
              <div className="fs-14 lh-19  mt-10">
                {t('orderPage:doeThisHappenOften')}
              </div>
              <div className="fs-12 lh-16 mt-6 c-mgray">
                {t('orderPage:WeAreWorkingToMakeItReliable')}
              </div>
            </>
          )}
        </>
      )}
      <div className="d-flex align-items-center justify-content-end mt-24">
        {company.isIncludedIn([CompanyName.Baqal]) ? (
            <ExternalLink
              className="h-40 mie-20 px-10 lh-40 c-blue fs-14 fw-500"
              href={company.config.links.whatsapp}
              targetBlank
            >
              {t('getHelp')}
            </ExternalLink>
          ) :
          <FCButton className="h-40 mie-20 px-10 lh-40 c-blue fs-14 fw-500" title={t('getHelp')} />}
        <div className="button _primary w-85 h-40 fs-14" onClick={onDismiss}>
          {t('ok')}
        </div>
      </div>
    </Popover>
  );
});
