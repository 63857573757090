import Skeleton from 'react-loading-skeleton';
import React, { ReactElement } from 'react';
import { ProductListSkeletonProps } from './interface';
import { PRODUCT_ITEM_WRAPPER_CLASSES } from '../constants';

const ProductListSkeleton = (props: ProductListSkeletonProps): ReactElement => {
  const { quantity, className = PRODUCT_ITEM_WRAPPER_CLASSES } = props;

  return (
    <>
      {Array(quantity)
        .fill(1)
        .map((_, j) => (
          <div className={className} key={j}>
            <Skeleton className="product-item br-8 h-min-200" />
          </div>
        ))}
    </>
  )
}

export default ProductListSkeleton;
