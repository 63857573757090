/**
 * @prettier
 */

import htmlClasses from 'html-classes';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { a, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import IllustrationAddressNotFound from '../../assets/img/address_not_found.svg';
import MapPoint from '../../assets/img/map_point.svg';
import IllustrationTeam from '../../assets/img/team.jpg';
import useDebounce from '../../components/DebounceHook';
import InputText from '../../components/InputText';
import {
  MAP_DEBOUNCE_TIME,
  MAP_GEOLOCATION_ZOOM,
  RN_ANSWER_TIMEOUT,
} from './constants';
import { DeliveryAddress, userStore } from '../../stores/UserStore';
import { orderStore } from '../../stores/OrderStore';
import { mainStore } from '../../stores/MainStore';
import { catalogStore } from '../../stores/CatalogStore';
import {
  AutocompletePrediction,
  AutocompleteSuggestion,
} from '../../api/DeliveryAddress';
import { ETADeliveryMethodType } from '../../api/ETA';
import ExternalLink from '../../components/ExternalLink';
import { uuid } from 'lodash-uuid';
import { company } from '../../company/Company';
import { CompanyName } from '../../company/interface';
import GMap from './GMap';
import store from './store';
import usePageAnalytics from '../usePageAnalytics';

type DeliveryAddressZipProps = {
  shortAddress: string;
  fullAddress: string;
  onClick: () => void;
};

type SubscribeFormProps = {
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleDissmis: () => void;
  type: string;
  className: string;
};

type PrivacyPolicyProps = {
  className: string;
};

const {
  map: { center: mapCenter, zoom: mapZoom },
} = company.config;

const Geocoding = observer(() => {
  useEffect(() => {
    if (store.isMapBusy) {
      return;
    }

    if (
      store.deliveryAddress &&
      store.deliveryAddress.coordinates.lat.toFixed(7) ===
        store.mapCenter.lat.toFixed(7) &&
      store.deliveryAddress.coordinates.lng.toFixed(7) ===
        store.mapCenter.lng.toFixed(7)
    ) {
      return;
    }

    const timeout = setTimeout(() => {
      store.setIsLoading(true);
      store
        .geocoding(
          store.mapCenter.lng === mapCenter.lng &&
            store.mapCenter.lat === mapCenter.lat,
        )
        .finally(() => store.setIsLoading(false));
    }, MAP_DEBOUNCE_TIME);

    return () => clearTimeout(timeout);
    //eslint-disable-next-line
  }, [store.isMapBusy, store.mapCenter]);

  return <></>;
});

const AddressInputWrap = observer(
  ({ sessionToken }: { sessionToken: string }) => {
    const { t } = useTranslation();
    const refAddressField = useRef<HTMLInputElement>(null);
    const debInputAddressValue = useDebounce<string>(
      store.inputAddressValue,
      800,
    );

    const handleManuallyInput = () => {
      store.setIsManuallyInput(true);
      store.setInputAddressFocused(true);
      store.setIsAddressNotExist(false);
      store.setIsAddressNotCovered(false);
      store.setIsSmallSizeCover(false);
      if (store.isManuallyInput) {
        return;
      }

      if (store.deliveryAddress?.shortAddress) {
        store.setInputAddressValue(store.deliveryAddress?.address1);
      }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      store.setInputAddressValue(e.currentTarget.value);
      store.setIsAddressNotExist(false);
      store.setIsAddressNotCovered(false);
    };

    const handleClear = () => {
      store.setInputAddressValue('');
      refAddressField.current?.focus();
    };

    useEffect(() => {
      if (store.isSmallSizeCover) {
        return;
      }
      if (!debInputAddressValue) {
        store.setAddresessList([]);
        return;
      }

      if (!debInputAddressValue.trim()) {
        return;
      }

      store.setIsLoading(true);
      userStore
        .getAutocomplete({
          sessionToken: sessionToken,
          input: debInputAddressValue,
          placeId: '',
        })
        .then(({ data: { predictions = [] } }) => {
          store.setAddresessList(predictions);
          if (!predictions.length) {
            store.setIsAddressNotExist(true);
          }
        })
        .catch(() => {
          store.setAddresessList([]);
          store.setIsAddressNotExist(true);
        })
        .finally(() => store.setIsLoading(false));
      //eslint-disable-next-line
    }, [debInputAddressValue, store.isSmallSizeCover]);

    useEffect(() => {
      if (
        store.isSmallSizeCover ||
        !window.ReactNativeWebView ||
        !mainStore.isRNReady
      ) {
        return;
      }
      mainStore.sendToRN(
        'getGeolocation',
        {
          target: 'findme_icon',
        },
        (e) => {
          if (
            !e ||
            !e.location?.coords?.latitude ||
            !e.location?.coords?.longitude
          ) {
            return;
          }
          userStore
            .geocodingByCoordinates({
              lat: e.location.coords.latitude,
              lng: e.location.coords.longitude,
            })
            .then((res) => {
              if (!res) {
                return;
              }
              store.setCurGeolocationAddress({
                address: res.shortAddress,
                postcode: res.zip,
                city: res.city,
                country: res.country,
                location: {
                  latitude: e.location.coords.latitude,
                  longitude: e.location.coords.longitude,
                },
                place_id: '',
              });
            })
            .catch((error) => error && console.error(error));
          store.setIsMapBusy(false);
          store.setIsAwaitGeolocation(false);
        },
        () => {
          store.setCurGeolocationAddress(null);
        },
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.isSmallSizeCover]);

    return (
      <div
        className={htmlClasses(
          'delivery-address__input-wrap position-relative br-8 border-tf2 h-48 d-flex px-16',
          { _error: store.isAddressNotCovered || store.isAddressNotExist },
          { 'shadow-none border-0 px-0': store.isSmallSizeCover },
        )}
      >
        {store.isManuallyInput && store.inputAddressFocused ? (
          <>
            <input
              className="input-text w-100p pie-24"
              type="text"
              ref={refAddressField}
              placeholder={t('deliveryAddressPage:inputAddressPlaceholder')}
              value={store.inputAddressValue}
              autoFocus={true}
              onChange={handleInputChange}
              onClick={handleManuallyInput}
            />
            {store.inputAddressValue.length > 0 && (
              <div
                className="input-text__clear icon icon-rtl icon-close s-24 fs-18 c-t99 d-flex flex-center h-100p align-items-center"
                onClick={handleClear}
              />
            )}
          </>
        ) : (
          <>
            {!store.deliveryAddress?.shortAddress ? (
              <div
                className={htmlClasses(
                  'position-relative h-48 br-8 border-teb c-bg-white px-12 w-100p d-flex align-items-center fs-14 c-gray',
                  { 'pe-n': store.isLoading },
                )}
                onClick={handleManuallyInput}
              >
                {t('deliveryAddressPage:inputAddressPlaceholder')}
              </div>
            ) : (
              <div
                className={htmlClasses(
                  'position-relative h-48 br-8 border-teb c-bg-white py-7 pis-12 w-100p d-flex flex-direction-row justify-content-between flex-gap-12',
                  { 'pe-n': store.isLoading },
                )}
                onClick={handleManuallyInput}
              >
                <div className="d-flex flex-direction-column overflow-hidden">
                  <div className="text-truncate fs-13 c-udgray">
                    {store.deliveryAddress.shortAddress}
                  </div>
                  <div className="text-truncate fs-10 c-mgray">
                    {store.deliveryAddress.address1}
                  </div>
                </div>
                <div className="icon icon-rtl icon-chevron-right s-24 pe-n fs-14 d-flex flex-center h-100p align-items-center" />
              </div>
            )}
          </>
        )}
      </div>
    );
  },
);

const AddressButtons = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation<{ isShowBackBtn?: boolean; source?: string }>();
  const handleSaveAddress = async () => {
    store.setIsLoading(true);
    let deliveryAddress = toJS(store.deliveryAddress);
    mainStore.sendAnalytics(['BI', 'analytics', 'firebase'], {
      name: 'General: address changed',
      params: {
        postcode: deliveryAddress?.zip || '',
        warehouse_code: store.etaCalculation?.warehouse.code || '',
        source: state?.source || '',
        eta_min: store.etaCalculation?.duration.min || 0,
        eta_max: store.etaCalculation?.duration.max || 0,
        delivery_fee:
          store.etaCalculation?.cost[ETADeliveryMethodType.JiffyDelivery]
            ?.shippingPounds || 0,
        threshold:
          store.etaCalculation?.cost[ETADeliveryMethodType.JiffyDelivery]
            ?.thresholdPounds || 0,
        is_surger: store.etaCalculation?.highDemand || false,
        lat: deliveryAddress?.coordinates.lat || '',
        lon: deliveryAddress?.coordinates.lng || '',
      },
    });
    if (!deliveryAddress || !store.etaCalculation) {
      if (userStore.isFirstLaunch) {
        store.setIsShowAddressNotFound(true);
      }
      store.setIsLoading(false);
      return;
    }
    if (userStore.isAuthorized) {
      if (deliveryAddress.addressId) {
        userStore
          .updateAddress(deliveryAddress.addressId, {
            street_address_1: deliveryAddress.address1,
            street_address_2: deliveryAddress.address2,
            postcode: deliveryAddress.zip,
            latitude: deliveryAddress.coordinates.lat,
            longitude: deliveryAddress.coordinates.lng,
          })
          .catch((error) => error && console.error(error));
      } else {
        try {
          const addressId = await userStore
            .addAddress({
              street_address_1: deliveryAddress.address1,
              street_address_2: deliveryAddress.address2,
              city: deliveryAddress.city,
              postcode: deliveryAddress.zip,
              latitude: deliveryAddress.coordinates.lat,
              longitude: deliveryAddress.coordinates.lng,
              country: deliveryAddress.country,
            })
            .catch((error) => error && console.error(error));
          deliveryAddress = {
            ...deliveryAddress,
            addressId: addressId || null,
          };
        } catch (error) {
          error && console.error(error);
        }
      }
    }
    userStore.setDeliveryAddress(deliveryAddress);
    orderStore.setEtaCalculation(toJS(store.etaCalculation));
    catalogStore.setCategoryListCacheExpired(null);
    catalogStore.setProductListCacheExpired({});
    catalogStore.setProductsCacheExpired({});
    mainStore.sendToRN('trackOneSignalOutcome', {
      name: 'warehouse_selected',
    });
    mainStore.sendToRN('sendTags', {
      postcode: deliveryAddress.zip || '',
    });
    mainStore.sendToRN('setUserProperties', {
      'Address: postcode': deliveryAddress.zip || '',
    });
    mainStore.sendToRN('setUserProperties', {
      'Address: lat': deliveryAddress.coordinates.lat,
    });
    mainStore.sendToRN('setUserProperties', {
      'Address: lon': deliveryAddress.coordinates.lng,
    });
    if (userStore.isFirstLaunch) {
      mainStore.sendToRN('analytics', {
        name: 'Onboarding: warehouse selected',
        params: {
          warehouse_code: store.etaCalculation.warehouse.code,
          postcode: deliveryAddress.zip || '',
          lat: deliveryAddress.coordinates.lat,
          lon: deliveryAddress.coordinates.lng,
        },
      });
      mainStore.sendToRN('firebaseAnalytics', {
        name: 'onboarding_warehouse_selected',
        params: {
          warehouse_code: store.etaCalculation.warehouse.code,
          postcode: deliveryAddress.zip || '',
          lat: deliveryAddress.coordinates.lat,
          lon: deliveryAddress.coordinates.lng,
        },
      });
      mainStore.yaMetrika('Onboarding: warehouse selected', {
        warehouse_code: store.etaCalculation.warehouse.code,
        postcode: deliveryAddress.zip || '',
        lat: deliveryAddress.coordinates.lat,
        lon: deliveryAddress.coordinates.lng,
      });
      store.setIsShowAddressFound(true);
      store.setIsLoading(false);
      return;
    }
    if (mainStore.deferedDeeplink) {
      history.replace({
        pathname: mainStore.deferedDeeplink,
        state: { isDeeplink: true },
      });
      mainStore.setDeferedDeeplink('');
      return;
    }
    if (state?.isShowBackBtn) {
      history.length === 1 ? history.replace('/') : history.goBack();
    } else {
      history.replace('/');
    }
  };

  if (!store.isSmallSizeCover) {
    return <></>;
  }

  return (
    <div className="flex-shrink-0 mt-16">
      <button
        className="button _primary _block w-100p delivery-address__address-button"
        disabled={
          !store.deliveryAddress ||
          store.inputAddressFocused ||
          store.isLoading ||
          (!userStore.isFirstLaunch && !store.etaCalculation)
        }
        onClick={handleSaveAddress}
      >
        {store.isLoading ? <span className="spinner" /> : t('chooseAddress')}
      </button>
    </div>
  );
});

const AddressList = observer(({ sessionToken }: { sessionToken: string }) => {
  const { t } = useTranslation();
  const handleSetDeliverAddress = (
    suggestion: AutocompletePrediction | AutocompleteSuggestion,
  ) => {
    if (store.isLoading) {
      return;
    }
    store.setIsLoading(true);

    userStore
      .getAutocomplete({
        sessionToken: sessionToken,
        input: '',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore fix later
        placeId: suggestion.place_id ?? '',
      })
      .then((e) => {
        const place = e.data.result;
        if (place) {
          store.setMapCenter({
            lat: place.location.latitude,
            lng: place.location.longitude,
          });
          store.setDeliveryAddress({
            zip: '',
            country: '',
            city: '',
            region: '',
            address1: '',
            address2: '',
            shortAddress: '',
            coordinates: {
              lat: place.location.latitude,
              lng: place.location.longitude,
            },
            placeId: mainStore.getPseudoId(),
            addressId: null,
            comment: '',
            instructions: [],
          });
          setTimeout(() => store.geocoding(), 500);
        }
      })
      .catch(() => {
        store.setDeliveryAddress(null);
      })
      .finally(() => store.setIsLoading(false));

    store.setIsActiveGeolocation(false);
    store.setInputAddressFocused(false);
    store.setIsManuallyInput(false);
    store.setIsSmallSizeCover(true);
  };

  if (
    !store.isManuallyInput ||
    !store.inputAddressFocused ||
    !store.addresessList.length
  ) {
    return <></>;
  }

  return (
    <div className="mt-16 h-100p overflow-auto">
      {store.curGeolocationAddress !== null && (
        <>
          <AddressZip
            shortAddress={t('deliveryAddressPage:currentLocation')}
            fullAddress={store.curGeolocationAddress.address}
            onClick={() =>
              handleSetDeliverAddress(
                store.curGeolocationAddress as AutocompleteSuggestion,
              )
            }
          />
          <div className="ml-32 mr-16 c-bg-tf2 h-1 pe-n" />
        </>
      )}
      {store.addresessList.map((item, i) => (
        <React.Fragment key={item.place_id}>
          {i > 0 && <div className="ml-32 mr-16 c-bg-tf2 h-1 pe-n" />}
          <AddressZip
            shortAddress={item.description}
            fullAddress={item.description}
            onClick={() => handleSetDeliverAddress(item)}
          />
        </React.Fragment>
      ))}
    </div>
  );
});

const AddressZip = observer(
  ({ shortAddress, fullAddress, onClick }: DeliveryAddressZipProps) => {
    return (
      <div
        className={htmlClasses(
          'delivery-address-item d-flex align-items-center h-48 overflow-hidden w-100p',
          { 'pe-n': store.isLoading },
        )}
        onClick={onClick}
      >
        <div className="icon icon-rtl icon-marker s-20 fs-20 d-flex flex-center mr-8 pe-n c-tc7" />
        <div className="pr-15 w-100p overflow-hidden pe-n">
          <div className="text-truncate delivery-address__zip-text-caption lh-16">
            {shortAddress}
          </div>
          <div className="text-truncate delivery-address__zip-text lh-16 mt-4 c-tad">
            {fullAddress}
          </div>
        </div>
      </div>
    );
  },
);

const Errors = observer(() => {
  const { t } = useTranslation();

  return (
    <>
      {store.isAddressNotExist && (
        <div
          className="alert _error"
          onClick={() => store.setIsAddressNotExist(false)}
        >
          {t('deliveryAddressPage:errorAddressNotExist')}
        </div>
      )}
      {store.isAddressNotCovered && (
        <div
          className="alert _error"
          onClick={() => store.setIsAddressNotCovered(false)}
        >
          {t('deliveryAddressPage:errorAddressNotCovered')}
        </div>
      )}
    </>
  );
});

const PrivacyPolicy = observer(({ className }: PrivacyPolicyProps) => {
  return (
    <div className={className}>
      <Trans i18nKey="authPage:subscribeTerms">
        By subscribing, you accept our
        <ExternalLink
          className="c-dgray text-underline"
          to={{ pathname: '/inject', state: { page: 'terms' } }}
          href={company.config.links.terms}
        >
          Terms and Conditions
        </ExternalLink>
        and
        <ExternalLink
          className="c-dgray text-underline"
          to={{ pathname: '/inject', state: { page: 'privacy' } }}
          href={company.config.links.privacy}
        >
          Privacy policy
        </ExternalLink>
        .
        <ExternalLink
          className="c-dgray text-underline"
          to={{ pathname: '/inject', state: { page: 'cookie' } }}
          href={company.config.links.cookie}
        >
          See more
        </ExternalLink>
      </Trans>
    </div>
  );
});

const SubscribeForm = observer(
  ({ handleSubmit, handleDissmis, type, className }: SubscribeFormProps) => {
    const { t } = useTranslation();
    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) =>
      store.setNameVal(e.currentTarget.value);
    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) =>
      store.setEmailVal(e.currentTarget.value);

    useEffect(() => {
      if (!window.ReactNativeWebView || store.isPushRequested) {
        return;
      }
      mainStore.sendToRN(
        'subscribeToNotifications',
        {
          email: store.emailVal.trim(),
          isPush: true,
          isEmail: false,
        },
        () => {
          store.setIsPushRequested(true);
          mainStore.setIsBlockedPush(false);
          userStore
            .updateDeviceData(store.nameVal.trim(), store.emailVal.trim(), [
              {
                name: 'personalised',
                push: true,
                email: false,
              },
              {
                name: 'discounts',
                push: true,
                email: false,
              },
              {
                name: 'third_party',
                push: true,
                email: false,
              },
            ])
            .then((e) => {
              if (!e) {
                return;
              }
              userStore.setSubscriptionFlags({
                discounts: {
                  name: 'discounts',
                  push: true,
                  email: false,
                },
                personalised: {
                  name: 'personalised',
                  push: true,
                  email: false,
                },
                third_party: {
                  name: 'third_party',
                  push: true,
                  email: false,
                },
              });
            })
            .catch((error) => error && console.error(error));
        },
        () => {
          store.setIsPushRequested(true);
          mainStore.setIsBlockedPush(true);
        },
      );
    }, []);

    return (
      <form className={className} onSubmit={handleSubmit}>
        <InputText
          label={t('yourName')}
          value={store.nameVal}
          onChange={handleNameChange}
        />
        <InputText
          className="mt-10"
          label={t('email')}
          value={store.emailVal}
          isValid={store.isEmailValid}
          isError={!!(store.emailVal.length && !store.isEmailValid)}
          onChange={handleEmailChange}
        />
        <div className="d-flex align-items-center justify-content-end mt-16 mx-n4">
          {type === 'AddressNotFound' ? (
            <div
              className={htmlClasses(
                'button _secondary mx-4 w-100p flex-shrink-1',
                {
                  'pe-n': store.isAwaitSubscription,
                },
              )}
              onClick={handleDissmis}
            >
              {t('skip')}
            </div>
          ) : (
            <div
              className={htmlClasses(
                'button _secondary mx-4 w-100p flex-shrink-1',
                {
                  'pe-n': store.isAwaitSubscription,
                },
              )}
              onClick={handleDissmis}
            >
              {t('later')}
            </div>
          )}
          <button
            className="button _primary mx-4 w-100p flex-shrink-1"
            disabled={!store.isFormValid || store.isAwaitSubscription}
          >
            {store.isAwaitSubscription ? (
              <span className="spinner" />
            ) : (
              t('subscribe')
            )}
          </button>
        </div>
        <PrivacyPolicy className="mt-32 text-center fs-12 lh-16 c-dgray" />
        <div className="h-24" />
      </form>
    );
  },
);

const AddressNotFound = observer(() => {
  const { t } = useTranslation();
  const [isSubscribePush, setIsSubscribePush] = useState<boolean | null>(null);
  const [isSuccessSubscribe, setIsSuccessSubscribe] = useState<boolean | null>(
    null,
  );
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    store.setIsAwaitSubscription(true);
    setIsSubscribePush(null);
    mainStore.sendToRN('analytics', {
      name: 'Onboarding: subscribe dialog clicked',
      params: {
        answer: 'next',
        type: 'no_store',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'onboarding_subscribe_dialog_clicked',
      params: {
        answer: 'next',
        type: 'no_store',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    mainStore.sendToRN(
      'subscribeToNotifications',
      {
        email: store.emailVal.trim(),
        isEmail: true,
        isPush: true,
      },
      () => {
        setIsSubscribePush(true);
        mainStore.setIsBlockedPush(false);
      },
      () => {
        setIsSubscribePush(false);
        if (mainStore.isBlockedPush) {
          mainStore.pushAlert('warning', t('pushBlockedWarning'));
        }
        mainStore.setIsBlockedPush(true);
      },
    );
    if (!window.ReactNativeWebView) {
      setIsSubscribePush(true);
    }
  };
  const handleDissmis = () => {
    setIsSuccessSubscribe(null);
    mainStore.sendToRN('analytics', {
      name: 'Onboarding: subscribe dialog clicked',
      params: {
        answer: 'skip',
        type: 'no_store',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'onboarding_subscribe_dialog_clicked',
      params: {
        answer: 'skip',
        type: 'no_store',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    store.setDeliveryAddress(null);
    store.setMapCenter(mapCenter);
    store.setMapZoom(mapZoom);
    store.setIsShowAddressNotFound(false);
    store.setIsChangeDeliveryAreaPopover(true);
  };

  useEffect(() => {
    store.setIsAddressNotExist(false);
    store.setIsAddressNotCovered(false);
    if (!store.isShowAddressNotFound) {
      return;
    }
    mainStore.sendToRN('analytics', {
      name: 'Onboarding: subscribe dialog shown',
      params: {
        type: 'no_store',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'onboarding_subscribe_dialog_shown',
      params: {
        type: 'no_store',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    //eslint-disable-next-line
  }, [store.isShowAddressNotFound]);

  useEffect(() => {
    if (isSubscribePush === null) {
      return;
    }
    userStore
      .updateDeviceData(store.nameVal.trim(), store.emailVal.trim(), [
        {
          name: 'personalised',
          push: isSubscribePush,
          email: true,
        },
        {
          name: 'discounts',
          push: isSubscribePush,
          email: true,
        },
        {
          name: 'third_party',
          push: isSubscribePush,
          email: true,
        },
      ])
      .then((e) => {
        if (!e) {
          return;
        }
        mainStore.pushAlert(
          'success',
          t('deliveryAddressPage:addressNotFound.successSubscribe'),
        );
        mainStore.sendToRN('analytics', {
          name: 'Subscribe: subscribe from dialog',
          params: {
            type: 'email',
            source: 'onboarding',
          },
        });
        mainStore.sendToRN('firebaseAnalytics', {
          name: 'subscribe_subscribe_from_dialog',
          params: {
            type: 'email',
            source: 'onboarding',
          },
        });
        mainStore.sendToRN('sendTags', {
          in_waiting_list: true,
        });
        mainStore.sendToRN('sendTags', {
          subscribed_personal_offers: isSubscribePush ? 'all' : 'email',
        });
        mainStore.sendToRN('sendTags', {
          subscribed_discounts: isSubscribePush ? 'all' : 'email',
        });
        mainStore.sendToRN('sendTags', {
          subscribed_thirdparty: isSubscribePush ? 'all' : 'email',
        });
        setIsSuccessSubscribe(true);
      })
      .catch((error) => error && console.error(error))
      .finally(() => {
        store.setIsAwaitSubscription(false);
        setIsSubscribePush(null);
      });
    //eslint-disable-next-line
  }, [isSubscribePush]);

  useEffect(() => {
    if (isSuccessSubscribe === null) {
      return;
    }
    const timeout = setTimeout(() => {
      handleDissmis();
    }, 2000);
    return () => clearTimeout(timeout);
  }, [isSuccessSubscribe]);

  useEffect(() => {
    if (!window.ReactNativeWebView) {
      return;
    }

    let timeout = 0;

    if (!store.isAwaitSubscription || isSubscribePush !== null) {
      return () => clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      setIsSubscribePush(false);
      if (mainStore.isBlockedPush) {
        mainStore.pushAlert('warning', t('pushBlockedWarning'));
      }
      mainStore.setIsBlockedPush(true);
    }, RN_ANSWER_TIMEOUT) as unknown as number;

    return () => clearTimeout(timeout);
  }, [store.isAwaitSubscription, isSubscribePush]);

  if (!store.isShowAddressNotFound) {
    return <></>;
  }

  return (
    <div className="content-layout c-bg-white">
      <div
        className={htmlClasses(
          'h-100p d-flex flex-direction-column overflow-hidden',
          {
            'pe-n o-5': isSuccessSubscribe,
          },
        )}
      >
        <div className="h-100p d-flex align-items-center justify-content-center overflow-hidden">
          <img className="h-130 icon-rtl" src={IllustrationAddressNotFound} alt="" />
        </div>
        <div className="mt-auto px-24 pt-20 flex-shrink-0">
          <div className="fs-21 lh-25 text-center">
            {t('deliveryAddressPage:addressNotFound.title')}
          </div>
          <div className="fs-14  lh-20 text-center mt-10 c-udgray">
            {t('deliveryAddressPage:addressNotFound.text')}
          </div>
          <SubscribeForm
            className="mt-20"
            handleSubmit={handleSubmit}
            handleDissmis={handleDissmis}
            type="AddressNotFound"
          />
        </div>
      </div>
    </div>
  );
});

const AddressFound = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isSubscribePush, setIsSubscribePush] = useState<boolean | null>(null);
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    store.setIsAwaitSubscription(true);
    setIsSubscribePush(null);
    userStore.setIsFirstLaunch(false);
    mainStore.sendToRN('analytics', {
      name: 'Onboarding: subscribe dialog clicked',
      params: {
        answer: 'next',
        type: 'store_found',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    mainStore.sendAnalytics(['BI', 'analytics'], {
      name: 'Onboarding: complete',
      params: {
        eta_min: store.etaCalculation?.duration.min || 0,
        eta_max: store.etaCalculation?.duration.max || 0,
        delivery_fee:
          store.etaCalculation?.cost[ETADeliveryMethodType.JiffyDelivery]
            ?.shippingPounds || 0,
        threshold:
          store.etaCalculation?.cost[ETADeliveryMethodType.JiffyDelivery]
            ?.thresholdPounds || 0,
        is_surger: store.etaCalculation?.highDemand || false,
        type: 'passed',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'onboarding_subscribe_dialog_clicked',
      params: {
        answer: 'next',
        type: 'store_found',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'onboarding_complete',
      params: {
        eta: store.etaCalculation?.duration.min || 0,
        type: 'passed',
      },
    });
    mainStore.sendToRN(
      'subscribeToNotifications',
      {
        email: store.emailVal.trim(),
        isEmail: true,
        isPush: true,
      },
      () => {
        setIsSubscribePush(true);
        mainStore.setIsBlockedPush(false);
      },
      () => {
        setIsSubscribePush(false);
        if (mainStore.isBlockedPush) {
          mainStore.pushAlert('warning', t('pushBlockedWarning'));
        }
        mainStore.setIsBlockedPush(true);
      },
    );
    if (!window.ReactNativeWebView) {
      setIsSubscribePush(true);
    }
  };
  const handleDissmis = () => {
    mainStore.sendToRN('analytics', {
      name: 'Onboarding: subscribe dialog clicked',
      params: {
        answer: 'skip',
        type: 'store_found',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    mainStore.sendAnalytics(['BI', 'analytics'], {
      name: 'Onboarding: complete',
      params: {
        eta_min: store.etaCalculation?.duration.min || 0,
        eta_max: store.etaCalculation?.duration.max || 0,
        delivery_fee:
          store.etaCalculation?.cost[ETADeliveryMethodType.JiffyDelivery]
            ?.shippingPounds || 0,
        threshold:
          store.etaCalculation?.cost[ETADeliveryMethodType.JiffyDelivery]
            ?.thresholdPounds || 0,
        is_surger: store.etaCalculation?.highDemand || false,
        type: 'skipped',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'onboarding_subscribe_dialog_clicked',
      params: {
        answer: 'skip',
        type: 'store_found',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'onboarding_complete',
      params: {
        eta: store.etaCalculation?.duration.min || 0,
        type: 'skipped',
      },
    });
    userStore
      .updateDeviceData('', '', [
        {
          name: 'personalised',
          push: false,
          email: false,
        },
        {
          name: 'discounts',
          push: false,
          email: false,
        },
        {
          name: 'third_party',
          push: false,
          email: false,
        },
      ])
      .catch((error) => error && console.error(error));
    mainStore.sendToRN('sendTags', {
      subscribed_personal_offers: '0',
    });
    mainStore.sendToRN('sendTags', {
      subscribed_discounts: '0',
    });
    mainStore.sendToRN('sendTags', {
      subscribed_thirdparty: '0',
    });
    if (company.isIncludedIn([CompanyName.Jiffy])) {
      mainStore.pushAlert(
        'warning',
        t('deliveryAddressPage:addressFound.skipSubscribe'),
        () => {
          history.push('/notifications');
        },
      );
    }
    userStore.setIsFirstLaunch(false);
    if (mainStore.deferedDeeplink) {
      history.replace({
        pathname: mainStore.deferedDeeplink,
        state: { isDeeplink: true },
      });
      mainStore.setDeferedDeeplink('');
    } else {
      history.replace('/');
    }
  };

  useEffect(() => {
    if (!store.isShowAddressFound) {
      return;
    }
    mainStore.sendToRN('analytics', {
      name: 'Onboarding: subscribe dialog shown',
      params: {
        type: 'store_found',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'onboarding_subscribe_dialog_shown',
      params: {
        type: 'store_found',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    //eslint-disable-next-line
  }, [store.isShowAddressFound]);

  useEffect(() => {
    if (isSubscribePush === null) {
      return;
    }
    userStore
      .updateDeviceData(store.nameVal.trim(), store.emailVal.trim(), [
        {
          name: 'personalised',
          push: isSubscribePush,
          email: true,
        },
        {
          name: 'discounts',
          push: isSubscribePush,
          email: true,
        },
        {
          name: 'third_party',
          push: isSubscribePush,
          email: true,
        },
      ])
      .then((e) => {
        if (!e) {
          return;
        }
        userStore.setSubscriptionFlags({
          discounts: {
            name: 'discounts',
            push: isSubscribePush,
            email: true,
          },
          personalised: {
            name: 'personalised',
            push: isSubscribePush,
            email: true,
          },
          third_party: {
            name: 'third_party',
            push: isSubscribePush,
            email: true,
          },
        });
        mainStore.pushAlert(
          'success',
          t('deliveryAddressPage:addressFound.successSubscribe'),
          () => {
            history.push('/notifications');
          },
        );
        mainStore.sendToRN('analytics', {
          name: 'Subscribe: subscribe from dialog',
          params: {
            type: 'email',
            source: 'onboarding',
          },
        });
        mainStore.sendToRN('firebaseAnalytics', {
          name: 'subscribe_subscribe_from_dialog',
          params: {
            type: 'email',
            source: 'onboarding',
          },
        });
        mainStore.sendToRN('sendTags', {
          subscribed_personal_offers: isSubscribePush ? 'all' : 'email',
        });
        mainStore.sendToRN('sendTags', {
          subscribed_discounts: isSubscribePush ? 'all' : 'email',
        });
        mainStore.sendToRN('sendTags', {
          subscribed_thirdparty: isSubscribePush ? 'all' : 'email',
        });
      })
      .catch((error) => error && console.error(error))
      .finally(() => {
        store.setIsAwaitSubscription(false);
        setIsSubscribePush(null);
        if (mainStore.deferedDeeplink) {
          history.replace({
            pathname: mainStore.deferedDeeplink,
            state: { isDeeplink: true },
          });
          mainStore.setDeferedDeeplink('');
        } else {
          history.replace('/');
        }
      });
    //eslint-disable-next-line
  }, [isSubscribePush]);

  useEffect(() => {
    if (!window.ReactNativeWebView) {
      return;
    }

    let timeout = 0;

    if (!store.isAwaitSubscription || isSubscribePush !== null) {
      return () => clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      setIsSubscribePush(false);
      if (mainStore.isBlockedPush) {
        mainStore.pushAlert('warning', t('pushBlockedWarning'));
      }
      mainStore.setIsBlockedPush(true);
    }, RN_ANSWER_TIMEOUT) as unknown as number;

    return () => clearTimeout(timeout);
  }, [store.isAwaitSubscription, isSubscribePush]);

  if (!store.isShowAddressFound) {
    return <></>;
  }

  if (!company.isIncludedIn([CompanyName.Jiffy])) {
    handleDissmis();

    return <></>;
  }

  return (
    <div className="content-layout c-bg-white">
      <div className="h-100p d-flex flex-direction-column overflow-hidden">
        <div className="d-flex flex-direction-column align-items-center justify-content-center h-100p overflow-hidden px-24">
          <div className="fs-21 lh-25 text-center flex-shrink-0 mt-24">
            {t('deliveryAddressPage:addressFound.title')}
          </div>
          <div className="fs-14 lh-20 text-center mt-16 c-udgray flex-shrink-0">
            {t('deliveryAddressPage:addressFound.upperText')}
          </div>
          <div className="photo-team d-flex align-items-center justify-content-center overflow-hidden my-16">
            <img
              className="w-max-100p h-100p br-8"
              src={IllustrationTeam}
              alt=""
            />
          </div>
          <div className="fs-14 lh-20 text-center c-udgray flex-shrink-0">
            {t('deliveryAddressPage:addressFound.text')}
          </div>
        </div>
        <SubscribeForm
          className="px-24 pt-20 flex-shrink-0"
          handleSubmit={handleSubmit}
          handleDissmis={handleDissmis}
          type="AddressFound"
        />
      </div>
    </div>
  );
});

const Header = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation<{ isShowBackBtn?: boolean }>();

  if (!state?.isShowBackBtn) {
    return <></>;
  }

  return (
    <div className="d-flex flex-shrink-0 h-50 align-items-center px-7">
      <div
        className="icon icon-rtl icon-arrow-back d-flex flex-center s-50 fs-20 c-black"
        onClick={() =>
          history.length === 1 ? history.push('/') : history.goBack()
        }
      />
      <h3 className="w-100p text-center pie-50 text-truncate">
        {t('deliveryAddressPage:pageShortTitle')}
      </h3>
    </div>
  );
});

const Cover = observer(() => {
  const [sessionToken, setSessionToken] = useState<string>('');
  const { t } = useTranslation();
  const [{ y }, set] = useSpring(() => ({ y: 0 }));

  useEffect(() => {
    setSessionToken(uuid());
  }, []);

  const handleCollapseCover = () => {
    if (store.isSmallSizeCover) {
      return;
    }
    store.setInputAddressFocused(false);
    store.setIsManuallyInput(false);
    store.setIsSmallSizeCover(true);
    store.setIsMapBusy(false);
    store.setIsSmallSizeCover(true);
    store.setIsAddressNotExist(false);
    set.start({ y: 0, immediate: true });
  };
  const bind = useDrag(({ last, vxvy: [, vy], movement: [, my], event }) => {
    if (
      event.target &&
      (event.target as HTMLDivElement)?.classList?.contains(
        'delivery-address-item',
      )
    ) {
      return;
    }
    if (store.isSmallSizeCover) {
      handleCollapseCover();
      return;
    }
    if (my < 0) {
      return;
    }
    if (my >= 100) {
      handleCollapseCover();
      return;
    }
    if (last) {
      if (my > 80 || vy > 0.5) {
        handleCollapseCover();
        return;
      }
      set.start({ y: 0, immediate: false });
    } else {
      set.start({ y: my, immediate: true });
    }
  });

  const handleGeolocation = () => {
    if (!window.ReactNativeWebView) {
      return;
    }
    if (store.isActiveGeolocation) {
      store.setIsActiveGeolocation(false);
    } else {
      store.setIsAwaitGeolocation(true);
      mainStore.sendToRN(
        'getGeolocation',
        {
          target: 'findme_icon',
        },
        (e) => {
          if (
            !e ||
            !e.location?.coords?.latitude ||
            !e.location?.coords?.longitude
          ) {
            store.setIsMapBusy(false);
            store.setIsAwaitGeolocation(false);
            return;
          }
          store.setGeolocationCoordinates({
            lat: e.location.coords.latitude,
            lng: e.location.coords.longitude,
          });
          store.setMapZoom(MAP_GEOLOCATION_ZOOM);
          store.setIsActiveGeolocation(true);
          store.setIsMapBusy(false);
          store.setIsSmallSizeCover(true);
          store.setIsAwaitGeolocation(false);
        },
        () => {
          store.setIsActiveGeolocation(false);
          store.setGeolocationCoordinates(null);
          store.setIsAwaitGeolocation(false);
          store.setIsMapBusy(false);
        },
      );
    }
    store.setIsManuallyInput(false);
    store.setInputAddressFocused(false);

    /**
     * When user clicks on geolocation button, we need to block map actions,
     * so the user will not to see wrong address from MAP_CENTER
     * */
    store.setIsMapBusy(true);
  };

  return (
    <>
      {!store.isSmallSizeCover && (
        <div className="popover__bg" onClick={handleCollapseCover} />
      )}
      <a.div
        {...bind()}
        style={{ y }}
        className={htmlClasses(
          'delivery-address__cover position-absolute d-flex flex-direction-column justify-content-between w-100p c-bg-white',
          { '_expanded overflow-hidden': !store.isSmallSizeCover },
        )}
      >
        {store.isSmallSizeCover && (
          <div className="delivery-address__geolocation s-40 rounded-circle c-bg-white position-absolute">
            {store.isAwaitGeolocation ? (
              <span className="spinner delivery-address__btn-geolocation position-absolute" />
            ) : (
              <div
                className={htmlClasses(
                  'icon icon-rtl s-40 d-flex flex-center fs-21 position-absolute c-blue rounded-circle',
                  store.isActiveGeolocation
                    ? 'icon-geolocation-fill'
                    : 'icon-geolocation',
                  { 'pe-n': store.isLoading },
                )}
                onClick={handleGeolocation}
              />
            )}
          </div>
        )}
        <div className="flex-shrink-0">
          {store.isSmallSizeCover && (
            <>
              <div className="delivery-address__title ">
                {t('deliveryAddressPage:pageTitle')}
              </div>
              <div className="delivery-address__announce c-mgray  mb-16">
                {t('deliveryAddressPage:announce')}
              </div>
            </>
          )}
          <AddressInputWrap sessionToken={sessionToken} />
          <Errors />
        </div>
        <AddressList sessionToken={sessionToken} />
        <AddressButtons />
      </a.div>
    </>
  );
});

const PopoverChangeDeliveryArea = observer(() => {
  const { t } = useTranslation();
  const handleDissmis = () => {
    store.setIsChangeDeliveryAreaPopover(false);
    mainStore.sendAnalytics(['BI', 'analytics'], {
      name: 'Onboarding: Change Delivery Area clicked',
      params: {},
    });
  };

  return (
    <div className="delivery-address__cover position-absolute d-flex flex-direction-column justify-content-between w-100p c-bg-white">
      <div className="fs-21 lh-25 text-center">
        {t('deliveryAddressPage:changeDeliveryAreaPopover.title')}
      </div>
      <div
        className="fs-14 lh-20 text-center mt-10 c-black"
        dangerouslySetInnerHTML={{
          __html: t('deliveryAddressPage:changeDeliveryAreaPopover.text'),
        }}
      />
      <div className="button _primary w-100p mt-32" onClick={handleDissmis}>
        {t('deliveryAddressPage:changeDeliveryAreaPopover.button')}
      </div>
      <PrivacyPolicy className="mt-24 text-center fs-12 lh-16 c-dgray" />
    </div>
  );
});

const MapLegend = observer(() => {
  const { t } = useTranslation();

  if (company.isHideMapLegend) {
    return <></>;
  }

  return (
    <div className="delivery-address__legend">
      <div className="d-flex align-items-center flex-shrink-0 mx-10">
        <div className="s-14 rounded-circle c-bg-salad mr-4" />
        <div>15 – 20 {t('min')}</div>
      </div>
      <div className="d-flex align-items-center flex-shrink-0 mx-10">
        <div className="s-14 rounded-circle c-bg-green mr-4" />
        <div>25 – 30 {t('min')}</div>
      </div>
      <div className="d-flex align-items-center flex-shrink-0 mx-10">
        <div className="s-14 rounded-circle c-bg-lorange mr-4" />
        <div>35 – 60 {t('min')}</div>
      </div>
    </div>
  );
});

export default observer(() => {
  usePageAnalytics();

  const { state } = useLocation<{
    isNewAddress?: boolean;
    editAddress?: DeliveryAddress;
  }>();

  useEffect(() => {
    return () => store.resetStore();
  }, []);

  useLayoutEffect(() => {
    if (state?.isNewAddress) {
      return;
    }
    if (state?.editAddress) {
      store.setEditAddressData({
        addressId: state.editAddress.addressId || null,
        comment: state.editAddress.comment || '',
        instructions: state.editAddress.instructions || [],
      });
      store.setMapCenter(state.editAddress.coordinates || mapCenter);
      store.setDeliveryAddress(state.editAddress);
      store.setEtaCalculation(null);
      store.setInputAddressValue(state.editAddress.address1);
      store.geocoding().catch((error) => error && console.error(error));
      return;
    }
    store.setMapCenter(
      toJS(userStore.deliveryAddress?.coordinates || mapCenter),
    );
    store.setDeliveryAddress(toJS(userStore.deliveryAddress));
    store.setEtaCalculation(toJS(orderStore.etaCalculation));
    if (store.deliveryAddress?.shortAddress) {
      store.setInputAddressValue(store.deliveryAddress?.address1);
    }
    //eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    if (!mainStore.isRNReady) {
      return;
    }
    if (
      window.ReactNativeWebView &&
      (!userStore.deliveryAddress?.coordinates || userStore.isFirstLaunch)
    ) {
      store.setIsAwaitGeolocation(true);
      mainStore.sendToRN(
        'getGeolocation',
        {
          target: 'onboarding',
        },
        (e) => {
          if (
            !e ||
            !e.location?.coords?.latitude ||
            !e.location?.coords?.longitude
          ) {
            store.setIsMapBusy(false);
            store.setIsAwaitGeolocation(false);
            return;
          }
          store.setGeolocationCoordinates({
            lat: e.location.coords.latitude,
            lng: e.location.coords.longitude,
          });
          store.setMapZoom(MAP_GEOLOCATION_ZOOM);
          store.setIsActiveGeolocation(true);
          store.setIsMapBusy(false);
          store.setIsAwaitGeolocation(false);
        },
        () => {
          store.setIsActiveGeolocation(false);
          store.setGeolocationCoordinates(null);
          store.setIsAwaitGeolocation(false);
        },
      );

      store.setIsMapBusy(true);
    }
  }, [mainStore.isRNReady]);

  return (
    <div className="delivery-address content-layout">
      <Header />
      <div className="h-75p position-relative">
        <GMap />
        <img
          className={htmlClasses('delivery-address__marker', {
            _animate: store.isLoading,
          })}
          src={MapPoint}
          alt=""
        />
        <MapLegend />
        <Geocoding />
      </div>
      {store.isChangeDeliveryAreaPopover ? (
        <PopoverChangeDeliveryArea />
      ) : (
        <Cover />
      )}
      <AddressNotFound />
      <AddressFound />
    </div>
  );
});
