import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Popover from './Popover';
import { mainStore } from '../stores/MainStore';
import { orderStore } from '../stores/OrderStore';
import { useLocation } from 'react-router-dom';

export default observer(() => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!orderStore.etaCalculation) return;
    const serverTime = orderStore.etaCalculation.meta.serverTime.replace(' ', 'T');
    let popoverAlreadyShowed = false;
    const hoursNotShowPopover = 9;

    if (mainStore.lastDateShowShopClosedPopover) {
      if (
        (+new Date(serverTime) - +new Date(
          mainStore.lastDateShowShopClosedPopover)) / 1000 / 60 / 60 <
        hoursNotShowPopover
      ) {
        popoverAlreadyShowed = true;
      }
    }

    if (!popoverAlreadyShowed) {
      if (
        !orderStore.etaCalculation.warehouse.availability.availableNow &&
        !mainStore.isOverdueWarehouseTime()
      ) {
        mainStore.setIsShopClosedPopover(true);
        mainStore.setLastDateShowShopClosedPopover(orderStore.etaCalculation.meta.serverTime);
      }
    }
    //eslint-disable-next-line
  }, [orderStore.etaCalculation?.warehouse.availability.availableNow]);

  const handleDismiss = () => {
    mainStore.setIsShopClosedPopover(false);
  };

  return !pathname.match(/^\/stories/g) ? (
    <Popover isShow={mainStore.isShopClosedPopover} onBackdropDismiss={handleDismiss}>
      <div className="  fs-21 c-black">{t('shopClosedPopover:weClosed')}</div>
      <div className="fs-16 lh-22 mt-4">
        {t('shopClosedPopover:exploreCatalogAt', {
          time: orderStore.etaCalculation?.warehouse.availability.opening || '',
        })}
      </div>
      <div className="d-flex align-items-center mt-24">
        <div
          className="button _primary w-100p fs-16 lh-22 "
          onClick={handleDismiss}
        >
          {t('ok')}
        </div>
      </div>
    </Popover>
  ) : <></>;
});
