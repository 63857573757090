import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import htmlClasses from 'html-classes';
import { Link } from 'react-router-dom';
import { catalogStore } from '../../stores/CatalogStore';
import { mainStore } from '../../stores/MainStore';
import { observer } from 'mobx-react-lite';

interface SearchDummyProps {
  className?: string;
}

export default observer(({ className }: SearchDummyProps): ReactElement => {
  const { t } = useTranslation();

  const handleSearchClick = () => {
    catalogStore.setSearchQuery('');
    mainStore.sendToRN('analytics', {
      name: 'Catalog: search clicked',
      params: {
        source: 'homepage',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'catalog_search_clicked',
      params: {
        source: 'homepage',
      },
    });
  };

  return (
    <Link
      className={htmlClasses('search-dummy-link', className)}
      to={{ pathname: '/search', state: { source: 'homepage' } }}
      onClick={handleSearchClick}
    >
      <div className="search-dummy br-8 d-flex align-items-center pe-n">
        <div className="icon icon-rtl icon-search c-salad s-40 d-flex flex-center fs-18 ml-3 _active" />
        <div className="fs-14 c-salad ff-feixen-book">
          {t('searchPage:inputPlaceholder')}
        </div>
      </div>
    </Link>
  );
});
