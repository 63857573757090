import {MutableRefObject, useEffect, useState} from "react";
import * as Sentry from '@sentry/react';

export const useGoogleRecaptchaHook = (RecaptchaRef:MutableRefObject<RecaptchaProps>) => {
  const [isRecaptchaChallengeOpened, setIsRecaptchaChallengeOpened] = useState(false);

  const onClickOnOverlay = (e: MouseEvent) => {
    // Code below checks that user closes captcha challenge popup by clicking on overlay
    setIsRecaptchaChallengeOpened(
      e.target === document?.querySelector('iframe[src*="/bframe?"]')?.parentNode?.parentNode?.firstChild
    );
  }

  useEffect(() => {
    document.addEventListener('click', onClickOnOverlay);

    return () => {
      document.removeEventListener('click', onClickOnOverlay);
    }
  }, []);

  return {
    isRecaptchaChallengeOpened,
    getCaptchaToken: () => RecaptchaRef?.current?.getResponse() ?? '',
    startCaptcha: (): boolean => {
      try {
        RecaptchaRef?.current?.reset();
        RecaptchaRef?.current?.execute();

        return true;
      } catch (e) {
        Sentry.captureMessage('[Recaptcha] Failed Recaptcha init', e);

        return false;
      }
    }
  }
}

