import NotFound from '../../../assets/img/not_found.svg';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { catalogStore } from '../../../stores/CatalogStore';
import ProductItem from '../../../components/ProductItem';
import Skeleton from 'react-loading-skeleton';
import { observer } from 'mobx-react-lite';
import { Product } from '../../../types/Product/interface';

export default observer(() => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [recommendProducts, setRecommendProducts] = useState<Product[]>([]);


  useEffect(() => {
    setIsLoading(true);
    catalogStore.requestRecommendProducts([], 'search')
      .then((products) => {
        setRecommendProducts((products || []));
      })
      .catch((error) => error && console.error(error))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="scroll-layout h-100p">
      <div className="mt-35">
        <img className="h-130 mx-auto d-block icon-rtl" src={NotFound} alt="" />
        <div className="fs-21 w-230 text-center mt-24 mx-auto">
          {t('searchPage:productNotExist')}
        </div>
      </div>
      <div className="h-24" />

      {!isLoading && recommendProducts.length > 0 && (
        <div className="fs-16 lh-36 my-8">{t('searchPage:checkOffers')}</div>
      )}

      <div className="d-flex mx-n4 flex-wrap">
        {!isLoading ? recommendProducts.map((item) => (
          <div
            className="px-4 pb-8 flex-shrink-0 w-50p"
          >
            <ProductItem product={item} source="category" isWithinSlider key={item.id} />
          </div>
        )) : (
          <div className="px-24 position-absolute l-0 r-0 z-4">
            <div className="d-flex mx-n4 flex-wrap">
              {[1, 2, 3, 4].map((_, j) => (
                <div className="w-50p px-4 pb-8 flex-shrink-0" key={j}>
                  <Skeleton className="product-item br-8 h-200" />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
