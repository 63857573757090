import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren } from 'react';
import { ApplySMSButtonProps } from '../interface';

const ApplySMSButton = observer((props: PropsWithChildren<ApplySMSButtonProps>) => {
  const { isLoading, isDisabled, children } = props;

  return (
    <button
      className="button _primary w-100p"
      disabled={isDisabled || isLoading}
    >
      {isLoading && <span className="spinner" />}

      {!isLoading && children}
    </button>
  )
});

export default ApplySMSButton;
