import { observer } from 'mobx-react-lite';
import React from 'react';
import { PopoverInstructionItemProps } from '../../interface';

const PopoverInstructionItem = observer((props: PopoverInstructionItemProps) => {
  const {seqNumber, title, subtitle} = props;

  return (
    <div className="d-flex align-items-start mb-24">
      <div className="icon__number s-22 mr-12 lh-1">{seqNumber}</div>
      <div className="d-flex flex-direction-column">
        <div className="ff-manrope fw-500 lh-19 c-black fs-14 mb-4">
          {title}
        </div>
        <div className="ff-manrope fw-400 lh-14 c-black fs-12">
          {subtitle}
        </div>
      </div>
    </div>
  )
});

export default PopoverInstructionItem;
