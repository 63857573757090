import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { catalogStore } from '../../stores/CatalogStore';
import ProductItem from '../ProductItem';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import usePrevious from '../../hooks/usePrevious';
import isEqual from 'lodash.isequal';
import ProductList from '../ProductList';
import { RecommendProductsSlider } from './interface';
import { getShowMoreItems, removeRepetitions } from './utils';
import { scrollTo } from '../../UI';
import GoToRecommendationButton from './GoToRecommendationButton/GoToRecommendationButton';
import { ITEMS_PER_PAGE_LIMIT } from './constants';
import { mainStore } from '../../stores/MainStore';
import { orderStore } from '../../stores/OrderStore';
import { Product } from '../../types/Product/interface';

export default observer(({ sku, source, className, shouldShowAsList = false }: RecommendProductsSlider) => {
  const { t } = useTranslation();
  const [recommendProducts, setRecommendProducts] = useState<Product[]>([]);
  const [paginatedRecommendedProducts, setPaginatedRecommendedProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);
  const [previousWHCode, setPreviousWHCode] = useState(orderStore.whCode);
  const previousSku = usePrevious(sku);
  const handleUpdatePaginatedList = () => {
    mainStore.sendAnalytics(['analytics'], {
      name: 'Show more button clicked',
      params: {
        cart_id: undefined,
        products_amount: catalogStore.cart.reduce((sum, item) => sum + item.count, 0),
        items_amount: catalogStore.cart.length,
        price: catalogStore.totalCartPrice.base,
        final_price: catalogStore.finalPrice,
        eta_min: orderStore.etaCalculation?.duration.min || 0,
        eta_max: orderStore.etaCalculation?.duration.max || 0,
        delivery_fee: orderStore.fee.shippingPounds || 0,
        threshold: orderStore.fee.thresholdPounds || 0,
        is_surger: orderStore.etaCalculation?.highDemand || false,
      }
    });

    const newItems = getShowMoreItems(recommendProducts, paginatedRecommendedProducts);

    const newPaginatedProductsList = [...paginatedRecommendedProducts, ...newItems];

    setPaginatedRecommendedProducts(() => newPaginatedProductsList);
    setIsShowMore(newPaginatedProductsList.length !== recommendProducts.length);
  };

  useEffect(() => {
    if (!sku.length || isEqual(sku, previousSku)) {
      return;
    }

    setIsLoading(true);
    catalogStore.requestRecommendProducts(sku, source === 'reco_cart' ? 'cart' : 'card')
      .then((products) => {
        if (products && previousWHCode !== orderStore.whCode) {
          setPreviousWHCode(orderStore.whCode);
          setRecommendProducts(products);

          return;
        }

        if (shouldShowAsList) {
          setRecommendProducts(prevState => removeRepetitions([
            ...prevState,
            ...(products || [])
          ]));

          return;
        }

        setRecommendProducts((products || []));
      })
      .catch((error) => error && console.error(error))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [sku, previousWHCode]);

  useEffect(() => {
    if (recommendProducts.length === 0 || !shouldShowAsList) {
      return;
    }

    if (paginatedRecommendedProducts.length < ITEMS_PER_PAGE_LIMIT) {
      handleUpdatePaginatedList();

      return;
    }

    setIsShowMore(paginatedRecommendedProducts.length !== recommendProducts.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowAsList, JSON.stringify(recommendProducts), paginatedRecommendedProducts.length]);

  const handleScrollTop = () => {
    mainStore.sendAnalytics(['analytics'], {
      name: 'Back to top button clicked',
      params: {
        cart_id: undefined,
        products_amount: catalogStore.cart.reduce((sum, item) => sum + item.count, 0),
        items_amount: catalogStore.cart.length,
        price: catalogStore.totalCartPrice.base,
        final_price: catalogStore.finalPrice,
        eta_min: orderStore.etaCalculation?.duration.min || 0,
        eta_max: orderStore.etaCalculation?.duration.max || 0,
        delivery_fee: orderStore.fee.shippingPounds || 0,
        threshold: orderStore.fee.thresholdPounds || 0,
        is_surger: orderStore.etaCalculation?.highDemand || false,
      }
    });

    scrollTo('scroll-layout', 0, 200);
  };

  if (!isLoading && !recommendProducts.length) {
    return <></>;
  }

  return (
    <div className={className} id="recommendations-list">
      {!shouldShowAsList && (
        <>
          <div className="fs-16 lh-36 my-8 ">{t('youMightAlsoLike')}</div>

          <div className="scroll-list _24 hide-scroll-bar pb-8 mb-n8 mx-n24">
            {!isLoading ? recommendProducts.map((item) => (
              <ProductItem product={item} source={source} isWithinSlider key={item.id} />
            )) : [0, 1, 2, 3].map((i) => (
              <Skeleton className="product-item br-5 mr-16 h-150" key={i} />
            ))}
          </div>
        </>
      )}

      {shouldShowAsList && (
        <>
          <div className="fs-16  lh-36 my-8 ">{t('anythingElse')}</div>

          <ProductList
            productList={paginatedRecommendedProducts}
            source={source}
            isLoading={isLoading && paginatedRecommendedProducts.length === 0}
            showThreeInLine
          />

          {isShowMore && (
            <>
              <div className="button _bordered w-100p mt-10"
                   onClick={handleUpdatePaginatedList}
              >
                {t('showMore')}
              </div>
            </>
          )}

          {paginatedRecommendedProducts.length > 0 && (
            <div className="button _bordered w-100p mt-10"
                 onClick={handleScrollTop}
            >
              {t('backToTop')}
            </div>
          )}

          <GoToRecommendationButton recommendProducts={paginatedRecommendedProducts} />
        </>
      )}
    </div>
  );
});
