import IllustrationEmptyFavorites from '../../../assets/img/empty_favorites.svg';
import { Link } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyFavoritesListProps } from './interface';
import { observer } from 'mobx-react-lite';

const EmptyFavoritesList = observer((props: EmptyFavoritesListProps) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex h-100p flex-direction-column align-items-center">
      <img className="mt-24 h-130 flex-shrink-0" src={IllustrationEmptyFavorites} alt="" />

      <div className="mt-15 text-center  fs-21 lh-25 c-udgray fw-500">
        {t('hi')}!
      </div>

      <div className="mt-16 text-center  fs-21 c-udgray">
        {t('cartPage:nothingHere')}
      </div>

      <div className="text-center fs-16  mt-32 mt-auto">
        <Link className="c-blue" to="/">
          {t('goMarket')}
        </Link>
      </div>

      <div className="h-24" />
    </div>
  )
});

export default EmptyFavoritesList
