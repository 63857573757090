/**
 * @prettier
 */

import { DeliveryAddress, userStore } from '../../stores/UserStore';
import {
  AutocompletePrediction,
  AutocompleteSuggestion,
} from '../../api/DeliveryAddress';
import { ETACalculation } from '../../api/ETA';
import { makeAutoObservable } from 'mobx';
import { mainStore } from '../../stores/MainStore';
import { orderStore } from '../../stores/OrderStore';
import { company } from '../../company/Company';

type EditAddressData = {
  addressId: string | null;
  comment: string;
  instructions: string[];
};

const {
  map: { center: mapCenter, zoom: mapZoom },
} = company.config;

class Store {
  isMapBusy = true;
  mapCenter: GeoCoordinates =
    userStore.deliveryAddress?.coordinates || mapCenter;
  mapZoom = mapZoom;
  geolocationCoordinates: GeoCoordinates | null = null;
  curGeolocationAddress: AutocompleteSuggestion | null = null;
  isActiveGeolocation = false;
  isManuallyInput = false;
  isLoading = false;
  isAwaitGeolocation = false;
  isAwaitSubscription = false;
  inputAddressValue = '';
  inputAddressFocused = false;
  addresessList: AutocompletePrediction[] = [];
  deliveryAddress: DeliveryAddress | null = null;
  etaCalculation: ETACalculation | null = null;
  isAddressNotExist = false;
  isAddressNotCovered = false;
  isSmallSizeCover = true;
  nameVal = '';
  emailVal = '';
  isShowAddressNotFound = false;
  isShowAddressFound = false;
  isPushRequested = false;
  editAddressData: EditAddressData = {
    addressId: null,
    comment: '',
    instructions: [],
  };
  isChangeDeliveryAreaPopover = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isEmailValid(): boolean {
    return mainStore.validateEmail(this.emailVal);
  }

  get isFormValid(): boolean {
    return this.isEmailValid && !!this.nameVal.length;
  }

  setIsMapBusy(flag: boolean) {
    this.isMapBusy = flag;
  }

  setIsActiveGeolocation(flag: boolean) {
    this.isActiveGeolocation = flag;
  }

  setIsManuallyInput(flag: boolean) {
    this.isManuallyInput = flag;
  }

  setIsLoading(flag: boolean) {
    this.isLoading = flag;
  }

  setInputAddressFocused(flag: boolean) {
    this.inputAddressFocused = flag;
  }

  setIsAddressNotExist(flag: boolean) {
    this.isAddressNotExist = flag;
  }

  setIsAddressNotCovered(flag: boolean) {
    this.isAddressNotCovered = flag;
  }

  setMapCenter(val: GeoCoordinates) {
    this.mapCenter = val;
  }

  setMapZoom(val: number) {
    this.mapZoom = val;
  }

  setInputAddressValue(val: string) {
    this.inputAddressValue = val;
  }

  setAddresessList(val: AutocompletePrediction[]) {
    this.addresessList = val;
  }

  setDeliveryAddress(val: DeliveryAddress | null) {
    this.deliveryAddress = !val
      ? val
      : Object.assign(val, this.editAddressData);
  }

  setEtaCalculation(val: ETACalculation | null) {
    this.etaCalculation = val;
  }

  setIsSmallSizeCover(flag: boolean) {
    this.isSmallSizeCover = flag;
  }

  setNameVal(val: string) {
    this.nameVal = val;
  }

  setEmailVal(val: string) {
    this.emailVal = val;
  }

  setIsShowAddressNotFound(flag: boolean) {
    this.isShowAddressNotFound = flag;
  }

  setIsShowAddressFound(flag: boolean) {
    this.isShowAddressFound = flag;
  }

  setIsAwaitGeolocation(flag: boolean) {
    this.isAwaitGeolocation = flag;
  }

  setIsAwaitSubscription(flag: boolean) {
    this.isAwaitSubscription = flag;
  }

  setGeolocationCoordinates(val: GeoCoordinates | null) {
    this.geolocationCoordinates = val;

    /**
     * Code below sets new center of map, together with "geolocationCoordinates"
     *
     * TODO: Not sure why this properties are different. Needs to remove one of them
     * */
    if (val) {
      this.mapCenter = val;
    }
  }

  setCurGeolocationAddress(val: AutocompleteSuggestion | null) {
    this.curGeolocationAddress = val;
  }

  setIsPushRequested(flag: boolean) {
    this.isPushRequested = flag;
  }

  setEditAddressData(val: EditAddressData) {
    this.editAddressData = val;
  }

  setIsChangeDeliveryAreaPopover(flag: boolean) {
    this.isChangeDeliveryAreaPopover = flag;
  }

  resetStore() {
    this.isMapBusy = true;
    this.mapCenter = mapCenter;
    this.geolocationCoordinates = null;
    this.isActiveGeolocation = false;
    this.isManuallyInput = false;
    this.isLoading = false;
    this.isAwaitGeolocation = false;
    this.isAwaitSubscription = false;
    this.inputAddressValue = '';
    this.inputAddressFocused = false;
    this.addresessList = [];
    this.deliveryAddress = null;
    this.etaCalculation = null;
    this.isAddressNotExist = false;
    this.isAddressNotCovered = false;
    this.isSmallSizeCover = true;
    this.nameVal = '';
    this.emailVal = '';
    this.isShowAddressNotFound = false;
    this.isShowAddressFound = false;
    this.curGeolocationAddress = null;
    this.isPushRequested = false;
    this.editAddressData = {
      addressId: null,
      comment: '',
      instructions: [],
    };
  }

  async geocoding(skipGeocoding = false) {
    if (!skipGeocoding) {
      const prevPlaceId = this.deliveryAddress?.placeId;
      try {
        const deliveryAddress = await userStore.geocodingByCoordinates(
          this.mapCenter,
        );
        this.setDeliveryAddress(deliveryAddress);
        this.setInputAddressValue(deliveryAddress?.address1 || '');
      } catch (e) {
        this.setDeliveryAddress(null);
        this.setInputAddressValue('');
      }

      if (prevPlaceId === this.deliveryAddress?.placeId) {
        if (!this.etaCalculation && !userStore.isFirstLaunch) {
          this.setIsAddressNotCovered(true);
        }
        return;
      }
    }

    this.setAddresessList([]);

    try {
      if (this.deliveryAddress) {
        const etaCalculation = await orderStore.fetchETA(
          `${this.deliveryAddress.coordinates.lat},${this.deliveryAddress.coordinates.lng}`,
        );
        this.setEtaCalculation(etaCalculation);
        this.setIsAddressNotCovered(false);
        this.setIsAddressNotExist(false);
        if (!etaCalculation) {
          this.setIsAddressNotCovered(true);
        }
      } else {
        this.setEtaCalculation(null);
        if (!userStore.isFirstLaunch) {
          this.setIsAddressNotCovered(true);
        }
      }
    } catch (e) {
      this.setEtaCalculation(null);
      if (!userStore.isFirstLaunch) {
        this.setIsAddressNotCovered(true);
      }
    }
  }
}

const store = new Store();

export default store;
