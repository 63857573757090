import Icon from '../../ui/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../ui/Typography';
import { BadgeProps } from '../interface';

export const PriceLockBadge = (props: BadgeProps) => {
  const { offer, className } = props;
  const {t} = useTranslation();

  if (offer.promoRequiredQuantity || !offer.properties.price_lock_tag) {
    return null;
  }

  return (
    <div className={className}>
      <div className="d-inline-flex align-items-center br-2 c-bg-primaryGray c-dgreen py-2 px-4">
        <Icon type='lock' size={10} className="mr-2"/>
        <Typography lineHeight={11} fontSize={9}>
          {t('priceLock')}
        </Typography>
      </div>
    </div>
  )
}
