import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { PizzaBadgeProps } from './interface';
import { DEFAULT_PIZZA_ETA_TIME_MINS } from './constants';
import { PIZZA_COMMODITY_GROUP } from '../constants';
import htmlClasses from 'html-classes';

export const PizzaBadge = observer((props: PizzaBadgeProps) => {
  const { count, commodityGroup, className } = props;
  const time = DEFAULT_PIZZA_ETA_TIME_MINS * count;

  if (!commodityGroup || commodityGroup !== PIZZA_COMMODITY_GROUP) {
    return null;
  }

  return (
    <div className={htmlClasses("br-3 c-bg-ulyellow fs-8 p-5 mb-4 c-dgreen flex-shrink-0 lh-1", className)}>
      <Trans i18nKey="timeToBake" values={{ time: time }} components={{ bold: <b/>}}/>
    </div>
  );
});
