import { useState, useLayoutEffect, useMemo, useEffect } from 'react';

/**
 * Code below helps to avoid problem with juggling inside list where were added new elements.
 * It is specific bug for ios and safari that they are not keeping scroll position when content was changed
 *
 * more information about it could be found here:
 * https://medium.com/@mikolajkocieda/react-hooks-keep-scroll-position-during-change-list-views-cd86463f9629
 * */
export function useSafariScrollPosition(updateProperty: string) {
  const [scrollListElement, setScrollListElement] = useState<Nullable<HTMLDivElement>>(null);

  function isMobileSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ||
      (/iP(ad|od|hone)/i.test(navigator.userAgent) &&
      /WebKit/i.test(navigator.userAgent) &&
      !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(navigator.userAgent)));
  }

  useEffect(() => {
    if (!isMobileSafari()) {
      return;
    }
    // gets point on scroll element
    setScrollListElement(document.querySelector('.scroll-layout') as HTMLDivElement);
  }, []);

  const prevScrollHeightValue = useMemo(() => {
    /**
     * I have to use "useMemo" here, because it is start to work BEFORE new items will be added to list
     * so I can get old value of scroll container height
     * */
    return scrollListElement?.scrollHeight ?? null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProperty]);

  useLayoutEffect(() => {
    /**
     * I have to use "useLayoutEffect" here, because it is start to work AFTER new items will be added to list
     * so I can calculate new scroll position for container
     * */
    if (!scrollListElement || prevScrollHeightValue === null) {
      return;
    }

    const currentScrollHeight = scrollListElement.scrollHeight ?? 0;
    const currentScrollTop = scrollListElement.scrollTop ?? 0;

    const newScrollTop = currentScrollTop + (currentScrollHeight - prevScrollHeightValue);

    if (currentScrollHeight !== prevScrollHeightValue) {
      scrollListElement.scrollTo({
        top: newScrollTop
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProperty]);
}


export default useSafariScrollPosition;
