import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { mainStore } from '../stores/MainStore';
import { userStore } from '../stores/UserStore';
import htmlClasses from 'html-classes';
import { Address } from '../api/DeliveryAddress';
import { orderStore } from '../stores/OrderStore';
import { catalogStore } from '../stores/CatalogStore';
import PopoverWithSwipe from './PopoverWithSwipe';
import { toJS, runInAction } from 'mobx';
import { company } from '../company/Company';

type AddressItemProps = {
  address: Address;
  isActive: boolean;
  isEditingList: boolean;
  isShowDeleteBtn: boolean;
  onDelete: (id: string) => void;
};

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const [isEditingList, setIsEditingList] = useState(false);
  const [deletingList, setDeletingList] = useState<string[]>([]);
  const [activeAddressIndex, setActiveAddressIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const onDismiss = () => mainStore.setIsChangeAddressPopover(false);
  const handleToggleEditing = async () => {
    if (isEditingList) {
      setIsEditingList(false);
      if (deletingList.length) {
        setIsLoading(true);
        const list: string[] = [];
        let isError = false;
        for (let i = 0; i < deletingList.length; i++) {
          try {
            if (await userStore.deleteAddress(deletingList[i])) {
              list.push(deletingList[i]);
            } else isError = true;
          } catch (e) {
            isError = true;
          }
        }
        if (isError) {
          mainStore.pushAlert('error', `${t('errors:unknown')}<br>${t('pleaseTryAgain')}`);
        }
        userStore.setAddressList(
          userStore.addressList.filter((address) => {
            return list.indexOf(address.id) === -1;
          }),
        );
        setDeletingList([]);
        setIsLoading(false);
      }
    } else setIsEditingList(true);
  };
  const handleDeleteAddress = (id: string) => setDeletingList([...deletingList, id]);
  const handleOpenMap = (isNewAddress = true) => {
    onDismiss();
    history.push({
      pathname: '/delivery-address',
      state: {
        isShowBackBtn: true,
        isNewAddress: isNewAddress,
        source: pathname.slice(1) || 'homepage',
      },
    });
  };
  const handleCancelEditing = () => {
    setIsEditingList(false);
    setDeletingList([]);
  };

  useEffect(() => {
    if (!mainStore.isChangeAddressPopover) return;
    userStore
      .fetchAddresses()
      .then(() => {
        if (!mainStore.isChangeAddressPopover) return;
        if (!userStore.addressList.length) {
          handleOpenMap(false);
          return;
        }
        if (!userStore.deliveryAddress?.addressId) return;
        let activeIndex: number | null = null;
        for (let i = 0; i < userStore.addressList.length; i++) {
          if (
            userStore.addressList[i].id === userStore.deliveryAddress.addressId &&
            userStore.addressList[i].street_address_1 === userStore.deliveryAddress.address1
          ) {
            activeIndex = i;
            break;
          }
        }
        if (activeIndex === null) {
          runInAction(() => {
            if (!userStore.deliveryAddress) return;
            userStore.deliveryAddress.addressId = null;
          });
        } else setActiveAddressIndex(activeIndex);
      })
      .catch((error) => error && console.error(error));
    return () => {
      setIsEditingList(false);
      setDeletingList([]);
      setActiveAddressIndex(null);
    };
    //eslint-disable-next-line
  }, [mainStore.isChangeAddressPopover]);

  useEffect(() => {
    if (!userStore.isAuthorized) mainStore.setIsChangeAddressPopover(false);
    //eslint-disable-next-line
  }, [userStore.isAuthorized]);

  return (
    <PopoverWithSwipe
      className="_max-height d-flex flex-direction-column overflow-hidden"
      isShow={mainStore.isChangeAddressPopover}
      onBackdropDismiss={onDismiss}
    >
      <div className="d-flex align-items-center justify-content-between flex-shrink-0 mb-24">
        <div className="fs-21 c-udgray fw-500">
          {t(`selectAddressPopover:${isEditingList ? 'edit' : 'select'}DeliveryAddress`)}
        </div>
        {isLoading ? (
          <span className="spinner ml-12 flex-shrink-0" />
        ) : (
          <div
            className={htmlClasses(
              'icon icon-rtl s-24 d-flex flex-center ml-12',
              isEditingList ? 'icon-check-long c-tgreen fs-21' : 'icon-pen c-udgray fs-24',
            )}
            onClick={handleToggleEditing}
          />
        )}
      </div>
      <div className="overflow-auto mx-n24 px-24">
        {userStore.addressList.map(
          (address, i) =>
            deletingList.indexOf(address.id) === -1 && (
              <React.Fragment key={address.id}>
                <AddressItem
                  address={address}
                  isActive={i === activeAddressIndex}
                  isEditingList={isEditingList}
                  isShowDeleteBtn={
                    userStore.addressList.length > 1 &&
                    userStore.addressList.length - 1 > deletingList.length
                  }
                  onDelete={handleDeleteAddress}
                />
                <div className="h-1 c-bg-tf2 pe-n my-16 hide-last-child" />
              </React.Fragment>
            ),
        )}
      </div>
      <div className="flex-shrink-0 mt-auto">
        <div className="text-center px-32 fs-14 lh-20 c-mgray mt-24">
          {t('selectAddressPopover:attentionMessage')}
        </div>
        <button
          className={`button ${isEditingList ? '_bordered' : '_primary'} w-100p mt-12`}
          onClick={isEditingList ? handleCancelEditing : () => handleOpenMap()}
        >
          {t(`selectAddressPopover:${isEditingList ? 'cancelEditing' : 'addNewAddress'}`)}
        </button>
      </div>
    </PopoverWithSwipe>
  );
});

const AddressItem = observer(
  ({ address, isActive, isEditingList, isShowDeleteBtn, onDelete }: AddressItemProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { pathname } = useLocation();
    const [errorAddressNotCovered, setErrorAddressNotCovered] = useState('');
    const handleApplyAddress = async () => {
      if (isActive) {
        mainStore.setIsChangeAddressPopover(false);
        return;
      }
      try {
        const etaCalculation = await orderStore.fetchETA(
          `${address.latitude},${address.longitude}`,
        );
        if (!etaCalculation) {
          setErrorAddressNotCovered(t('deliveryAddressPage:errorAddressNotCovered'));
          return;
        }
        userStore.setDeliveryAddress({
          zip: address.postcode || '',
          country: 'GB',
          city: address.city || 'London',
          region: 'Greater London',
          address1: address.street_address_1 || '',
          address2: address.street_address_2 || '',
          shortAddress: address.street_address_1 || '',
          coordinates: {
            lat: address.latitude,
            lng: address.longitude,
          },
          placeId: '',
          addressId: address.id,
          comment: address.comment || '',
          instructions: toJS(address.instructions || []),
        });
        orderStore.setEtaCalculation(etaCalculation);
        catalogStore.setCategoryListCacheExpired(null);
        catalogStore.setProductListCacheExpired({});
        catalogStore.setProductsCacheExpired({});
        mainStore.setIsChangeAddressPopover(false);
      } catch (e) {
        setErrorAddressNotCovered(t('deliveryAddressPage:errorAddressNotCovered'));
      }
    };
    const handleChangeAddress = () => {
      mainStore.setIsChangeAddressPopover(false);
      history.push({
        pathname: '/delivery-address',
        state: {
          isShowBackBtn: true,
          editAddress: {
            zip: address.postcode || '',
            country: 'GB',
            city: address.city || 'London',
            region: 'Greater London',
            address1: address.street_address_1 || '',
            address2: address.street_address_2 || '',
            shortAddress: address.street_address_1 || '',
            coordinates: {
              lat: address.latitude,
              lng: address.longitude,
            },
            placeId: '',
            addressId: address.id,
            comment: address.comment || '',
            instructions: toJS(address.instructions || []),
          },
          source: pathname.slice(1) || 'homepage',
        },
      });
    };

    return (
      <>
        {isEditingList ? (
          <div className="d-flex align-items-center">
            {isShowDeleteBtn && !isActive && (
              <div
                className="icon icon-rtl icon-remove s-28 fs-16 d-flex flex-center mr-16 c-bg-tf2 rounded-circle"
                onClick={() => onDelete(address.id)}
              />
            )}
            <div
              className="position-relative h-min-48 br-8 border-teb py-8 pl-8 w-100p d-flex align-items-center pr-40"
              onClick={handleChangeAddress}
            >
              <div className="icon icon-rtl icon-chevron-right s-24 position-absolute pe-n fs-14 d-flex flex-center cart-address__icon" />
              <div className="fs-13 c-udgray">{address.street_address_1}</div>
            </div>
          </div>
        ) : (
          <div className="overflow-hidden" onClick={handleApplyAddress}>
            <div className="d-flex align-items-start justify-content-between">
              <div className="fs-16 lh-20 c-udgray pt-4">{address.street_address_1}</div>
              <div className={htmlClasses('radio-bullet ml-16', { _active: isActive })} />
            </div>
            {company.hasAddress2 && address.street_address_2 && address.street_address_2.length > 0 && (
              <div className="mt-3 fs-13 c-mgray lh-16">{address.street_address_2 || ''}</div>
            )}
            {address.instructions && address.instructions.length > 0 && (
              <div className="mt-3 fs-13 c-mgray lh-16">{address.instructions.join(', ')}</div>
            )}
            {address.comment && address.comment.length > 0 && (
              <div className="mt-3 fs-13 c-mgray lh-16">{address.comment || ''}</div>
            )}
            {errorAddressNotCovered.length > 0 && (
              <div className="mt-3 fs-13 c-red lh-16">{errorAddressNotCovered}</div>
            )}
          </div>
        )}
      </>
    );
  },
);
