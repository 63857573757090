import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popover from './Popover';
import IconAgeOver18 from '../assets/img/age_over_18.svg';
import IconAgeOver21 from '../assets/img/product-info-icons/icon-only-for-adults-21.svg';
import Checkbox from './Checkbox';
import htmlClasses from 'html-classes';
import { userStore } from '../stores/UserStore';
import { catalogStore } from '../stores/CatalogStore';
import { mainStore } from '../stores/MainStore';
import { orderStore } from '../stores/OrderStore';
import { company } from '../company/Company';

export default observer(() => {
  const { t } = useTranslation();
  const [isCustomerAdult, setIsCustomerAdult] = useState<boolean>(false);
  const handleConfirmAge = () => {
    setIsCustomerAdult(!isCustomerAdult);
  };
  const handleDismiss = () => {
    setIsCustomerAdult(false);
    mainStore.setIsAgeRestrictionPopover(false);
    if (catalogStore.isAdultItemInCart) catalogStore.collectAdultProducts();
  };
  const handleAccept = () => {
    userStore.setIsAdult(true);
    mainStore.setIsAgeRestrictionPopover(false);
    if (catalogStore.addToCartCatch) {
      catalogStore.setCartItemCountByProduct(
        catalogStore.addToCartCatch.cartItem,
        catalogStore.addToCartCatch.count,
        'add',
        catalogStore.addToCartCatch.source,
      );
      catalogStore.addToCartCatch = null;
    } else if (catalogStore.addToGiftCatch) {
      orderStore.setActiveGift(catalogStore.addToGiftCatch.gift || null);
      mainStore.sendToRN('logAmplitudeEvent', {
        name: 'Gift: Selected',
        params: {
          gift_mechanism_ID: orderStore.gift?.id,
          product_name: orderStore.activeGift?.name,
        },
      });
      catalogStore.addToGiftCatch.callback();

      catalogStore.addToGiftCatch = null;
    }
  };

  return (
    <Popover isShow={mainStore.isAgeRestrictionPopover} onBackdropDismiss={handleDismiss}>
      <div className="d-flex align-items-center justify-content-start fs-21 fw-500">
        <img
          src={company.config.ageRestricted > 18 ? IconAgeOver21 : IconAgeOver18}
          width={48}
          alt=""
        />
        <div className="mis-12">{t('productPage:pleaseConfirmYourAge')}</div>
      </div>
      <div className="mt-10 fs-14 lh-19 fw-500">{t('productPage:conditionsToBuy')}</div>
      <div className="product-card__line my-12 mx-0" />
      <div className="c-tad fs-13 lh-16">{t('productPage:needToShowProofOfAge')}</div>
      <div
        className="d-flex align-items-center justify-content-start mt-20"
        onClick={handleConfirmAge}
      >
        <Checkbox checked={isCustomerAdult} />
        <span className="mis-8">{t('productPage:confirmationAgeOver18')}</span>
      </div>
      <div className="d-flex align-items-center justify-content-end mt-24">
        <div
          className="h-40 mie-20 px-10 lh-40 c-blue fs-14 fw-500"
          onClick={handleDismiss}
        >
          {t('cancel')}
        </div>
        <div
          className={htmlClasses('button _primary h-40 fs-14', {
            _disabled: !isCustomerAdult,
          })}
          onClick={handleAccept}
        >
          {t('confirm')}
        </div>
      </div>
    </Popover>
  );
});
