import { CompanyConfig } from '../interface';

export const SwifftConfig: CompanyConfig = {
  id: '49589d9f-753a-421f-bd36-cea6202c4ba7',
  lang: 'en-US',
  name: 'Swifft',
  officialName: 'Swifft LLC',
  email: 'support@swifft.com',
  phonePlaceholder: '+1 XXXXXXXXXXX',
  defaultPhoneCode: '+1',
  ageRestricted: 21,
  timeZone: 'America/Los_Angeles',
  minimalOrderFeeThreshold: 700,
  warehouse: {
    default: 'devs',
    minimalOrderFeeWarehouseCodes: [],
  },
  map: {
    center: { lat: 38.5425263, lng: -121.7438544 },
    zoom: 12,
  },
  links: {
    terms: 'https://app.termly.io/document/terms-and-conditions/460fc740-e750-474e-aa43-16ebe4fe1d97',
    privacy: 'https://app.termly.io/document/privacy-policy/c6d01a79-1e84-43f5-9f68-307e005e3f7a',
    cookie: '',
    deliveryZones: '',
    forRiders: '',
  },
  referral: {
    code: 'SWIFFTUCDAVIS',
    discount: '15%',
    minBasket: '15',
    downloadAppLink: 'https://click.jiffy.shop/PZKk/f11c621',
  },
  points: {
    value: '1=1c.',
    pointsPerUnit: 15,
    icon: 'coins',
  },
  appStoreUrl: {
    ios: 'itms-apps://apps.apple.com/id/app/jiffy-fresh-grocery-delivery/id1556301576?l=en',
    android: 'market://details?id=com.jiffygrocery.jiffy',
  },
  apis: {
    freshChat: {
      locale: 'en',
      url: '//fw-cdn.com/6029118/3214673.js',
    },
    amplitude: {
      production: 'rAdgyc-tehjoq-1qargi',
      test: 'dbb67e8a6e181b6d07c30a49bc40e60d', // jiffy's one (just for test)
    },
  },
  langs: ['en-GB'],
};
