import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

type LineThroughWrapProps = {
  children: ReactNode;
  isWrapped: boolean;
};

export default observer(({ children, isWrapped }: LineThroughWrapProps) =>
  isWrapped ? <s>{children}</s> : <>{children}</>,
);
