import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import htmlClasses from 'html-classes';
import { mainStore } from '../stores/MainStore';
import { company } from '../company/Company';

interface IReferralBannerProps {
  className?: string;
  source?: string;
}

export default observer(({ className, source }: IReferralBannerProps) => {
  const { t } = useTranslation();
  let discount = company.config.referral.discount;

  if (!discount.includes('%')) discount = mainStore.addCurrencySymbol(discount);

  return (
    <Link
      className={htmlClasses('referral-banner', className)}
      to={{ pathname: '/referral', state: { source, fromMenu: source === 'sidebar banner' } }}
      dangerouslySetInnerHTML={{
        __html: t(`referral:bannerText${discount.includes('%') ? 'Percent' : ''}`, { discount }),
      }}
      onClick={() => source === 'sidebar banner' && mainStore.setIsVisibleMainMenu(false)}
    />
  );
});
