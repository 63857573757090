import { observer } from 'mobx-react-lite';
import { storiesStore } from '../../../stores/StoriesStore';
import { Link } from 'react-router-dom';
import htmlClasses from 'html-classes';
import React from 'react';

const StoriesList = observer(() => {
  if (!storiesStore.stories.length) return <></>;

  // TODO: JIF-2852 background color should be getted from stories
  return (
    <div className='scroll-list _16 hide-scroll-bar mb-5 py-2 story-list'>
      {storiesStore.stories.map((story) => (
        <Link
          className={htmlClasses('story-item', {
            _new: !storiesStore.isStoryViewed(story.id),
          })}
          style={{ backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${story.mainImage})` }}
          to={`/stories/${story.id}`}
          key={story.id}
        >
          <div className='pt-100p' />
          <div className='story-item__title'>{story.name}</div>
        </Link>
      ))}
    </div>
  );
});

export default StoriesList;
