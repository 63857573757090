import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import PageHeader from '../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import { userStore } from '../../stores/UserStore';
import { useHistory } from 'react-router-dom';
import PointsNotReceived from './PointsNotReceived/PointsNotReceived';
import PointsReceived from './PointsReceived/PointsReceived';
import { company } from '../../company/Company';

export default observer(() => {
  const { t } = useTranslation();
  const { replace } = useHistory();

  useEffect(() => {
    if (company.isDisableLoyaltyProgram) {
      replace('/');
    }

    userStore.requestBonuses().catch((error) => error && console.error(error));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageHeader title={t('companyPoints')} />
      <div className="scroll-layout d-flex flex-direction-column h-100p">
        {!userStore.bonuses ? (
          <PointsNotReceived />
        ) : (
          <PointsReceived />
        )}
        <div className="h-24 flex-shrink-0" />
      </div>
    </>
  );
});
