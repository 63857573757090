import { observer } from 'mobx-react-lite';
import Popover from '../../../../components/Popover';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { PointsPopoverProps } from '../interface';
import { userStore } from '../../../../stores/UserStore';
import { notAuthorizedDetails, authorizedDetails } from '../../../JiffyPoints/constants';
import { useHistory } from 'react-router-dom';
import PopoverInstructionItem from './PopoverInstructionItem/PopoverInstructionItem';
import Icon from '../../../../components/ui/Icon/Icon';
import { mainStore } from '../../../../stores/MainStore';
import { orderStore } from '../../../../stores/OrderStore';
import { company } from '../../../../company/Company';

const PointsPopover = observer((props: PointsPopoverProps) => {
  const { isShow, onDismiss } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const handleGoToJiffyPerkPage = () => {
    onDismiss();
    history.push({
      pathname: '/points',
    });
  };

  const handleClosePopup = () => {
    onDismiss();

    if (!userStore.isAuthorized) {
      history.push({
        pathname: '/auth',
        state: { redirectToPage: 'cart' },
      });
    }

    mainStore.sendAnalytics(['analytics'], {
      name: 'Loyalty: Log In',
      params: {
        source: 'cart',
      },
    });
  };

  return (
    <Popover isShow={isShow} onBackdropDismiss={onDismiss}>
      <div className="d-flex align-items-center">
        <div className="fs-21 fw-500 mb-16">{t('pointsPage:caption')}</div>
      </div>

      <div>
        {!userStore.isAuthorized &&
          notAuthorizedDetails.map((item, i) => (
            <PopoverInstructionItem
              key={i}
              title={t(`pointsPage:${item.firstLine}`)}
              subtitle={t(
                `pointsPage:${item.secondLine}`,
                {
                  amount: company.config.points?.pointsPerUnit,
                  currencySymbol: orderStore.currencySymbol,
                },
              )}
              seqNumber={i + 1}
            />
          ))}

        {userStore.isAuthorized &&
          authorizedDetails.map((item, i) => (
            <PopoverInstructionItem
              key={i}
              title={t(`pointsPage:${item.firstLine}`)}
              subtitle={t(
                `pointsPage:${item.secondLine}`,
                {
                  amount: company.config.points?.pointsPerUnit,
                  currencySymbol: orderStore.currencySymbol,
                  companyName: company.config.name,
                },
              )}
              seqNumber={i + 1}
            />
          ))}

        <div className="border-tc4 border-linegray my-24" />

        <div className="d-flex align-items-center mb-24 fw-500">
          <Icon type="attention" size={24} className="mr-12" />

          <span>
            <Trans
              i18nKey={'pointsPage:perksOrPromo'}
              components={{
                bold: <b />,
              }}
            />
          </span>
        </div>

        <div className="d-flex flex-direction-column">
          <div
            className="button h-40 fs-14 mb-8 c-bg-salad4 c-black"
            onClick={handleGoToJiffyPerkPage}
          >
            {t('pointsPage:learnMoreAboutPerks')}
          </div>

          <div className="button _primary h-40 fs-14" onClick={handleClosePopup}>
            {t(userStore.isAuthorized ? 'ok' : 'pointsPage:logInAndStart')}
          </div>
        </div>
      </div>
    </Popover>
  );
});

export default PointsPopover;
