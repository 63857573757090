import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { catalogStore } from '../../../stores/CatalogStore';
import { mainStore } from '../../../stores/MainStore';
import { HistoryAndPopularProps } from './interfaces';
import { IDEA_COLOR } from './constants';
import BadgesPlate from './BadgesPlate';
import { orderStore } from '../../../stores/OrderStore';
import { company } from '../../../company/Company';
import { CompanyName } from '../../../company/interface';

export default observer(
  ({ onSetSuggest, isLoading, showHistory = false }: HistoryAndPopularProps) => {
    const { t } = useTranslation();
    const [ideasList, setIdeasList] = useState<string[]>([]);

    const handleClearSearchHistory = () => {
      catalogStore.deleteSearchHistory().catch((error) => error && console.error(error));
    };

    useEffect(() => {
      if (!company.isIncludedIn([CompanyName.Jiffy])) return;

      if (!orderStore.etaCalculation?.warehouse.code) {
        setIdeasList(catalogStore.searchIdeasList);
        return;
      }

      if (orderStore.etaCalculation.warehouse.code === 'LROLT') {
        const ideas = [...catalogStore.searchIdeasList];
        ideas[1] = t('searchPage:freshPizza');
        setIdeasList(ideas);
        return;
      }

      setIdeasList(catalogStore.searchIdeasList);

    }, [orderStore.getWHCode]);

    const handleIdeaClick = (badgeName: string) => {
      mainStore.sendToRN('analytics', {
        name: 'Catalog: search idea clicked',
        params: {
          query: badgeName,
        },
      });

      onSetSuggest(badgeName, 'idea');
    };

    if (isLoading) return <div className="spinner mx-auto mt-20" />;

    if (!showHistory) return <></>;

    return (
      <div className="mx-n8">
        {catalogStore.searchHistory.length > 0 && (
          <BadgesPlate
            onClear={handleClearSearchHistory}
            title={t('searchPage:searchHistory')}
            badges={catalogStore.searchHistory}
            onBadgeClick={onSetSuggest}
            source="history"
          />
        )}

        {ideasList.length > 0 && (
          <BadgesPlate
            title={t('searchPage:popular')}
            badges={ideasList}
            onBadgeClick={handleIdeaClick}
            source="idea"
            badgesColors={IDEA_COLOR}
          />
        )}

        <div className="h-24" />
      </div>
    );
  },
);
