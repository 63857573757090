import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import IllustrationNeedUpdate from '../assets/img/need_update.svg';
import { mainStore } from '../stores/MainStore';
import { company } from '../company/Company';

export default observer(() => {
  const { t } = useTranslation();

  return (
    <div className="popover">
      <div className="popover-general-error">
        <div className="px-24 h-100p d-flex flex-direction-column justify-content-center overflow-auto">
          <img
            className="d-block mx-auto flex-shrink-0"
            src={IllustrationNeedUpdate}
            alt=""
          />
          <div className="fs-22 pt-40 text-center">
            {t('sorryForInconvenience')}
            <br />
            {t('pleaseUpdateYourAppNow')}
          </div>
          <div className="fs-14 lh-19 mt-16 text-center">
            {t('thankYouBonus')}
          </div>
          <div className="fs-16 mt-16 text-center">Promo code SORRY</div>
        </div>
        <a
          className="button _primary flex-shrink-0 mt-24"
          href={mainStore.appPlatform ? company.config.appStoreUrl[mainStore.appPlatform] : undefined}
        >
          {t('update')}
        </a>
        <div className="h-24 flex-shrink-0" />
      </div>
    </div>
  );
});
