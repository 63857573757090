import { CategoryListAPI } from '../../api/Catalog';
import { Category } from './interface';
import { mainStore } from '../../stores/MainStore';
import { Product } from '../Product/interface';
import { convertProduct } from '../Product';

/**
 * Convert CategoryAPI to Category type
 * */
export function convertCategoryList(list: CategoryListAPI[]): Category[] {
  if (!list.length) return [];
  return list.filter(validateCategory).map(convertCategory);
}

function validateCategory({ category, children = [] }: CategoryListAPI): boolean {
  const { id, parentId, active } = category;

  if (!active) return false;

  children = children.filter(validateCategory);

  if (parentId === null && !children.length) {
    mainStore.consoleLog(
      `VALIDATE CATEGORY LIST - Missing active subcategories in the first level category (the category ignored)\n\tcategoryId: ${id}`,
      'error',
    );
    return false;
  }

  return true;
}

function convertCategory({ category, children = [] }: CategoryListAPI): Category {
  const {
    backgroundColor,
    description,
    fontColor,
    id,
    name,
    parentId,
    previewImage,
    products = [],
    tileSize,
  } = category;
  const convertedProducts: Product[] = [];

  products.forEach((item) => {
    const product = convertProduct(item);
    if (!product) return;
    convertedProducts.push(product);
  });

  return {
    backgroundColor,
    description,
    fontColor,
    id,
    name,
    parentId,
    previewImage: previewImage?.url || '',
    products: convertedProducts,
    subcategory: children.filter(validateCategory).map(convertCategory),
    tileSize,
  };
}
