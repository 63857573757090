import * as Sentry from '@sentry/react';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe, Stripe, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Checkbox from '../components/Checkbox';
import InputPhone from '../components/InputPhone/InputPhone';
import InputText from '../components/InputText';
import PageHeader from '../components/PageHeader';
import Popover from '../components/Popover';
import Select from '../components/Select';
import { CartAwaiting } from './Cart';
import { mainStore } from '../stores/MainStore';
import { catalogStore } from '../stores/CatalogStore';
import { userStore } from '../stores/UserStore';
import { orderStore, RequestETAResponse } from '../stores/OrderStore';
import {
  checkoutStore,
  MobilePaymentMethod,
  PaymentMethod,
  AdditionalPaymentMethod,
} from '../stores/CheckoutStore';
import IconCard from '../assets/img/icon_card.svg';
import IconCardVisa from '../assets/img/logo_visa.png';
import IconCardMC from '../assets/img/logo_mastercard.png';
import IconCash from '../assets/img/icon_cash.svg';
import LogoApplePay from '../assets/img/logo_applepay.svg';
import LogoGooglePay from '../assets/img/logo_googlepay.png';
import ExternalLink from '../components/ExternalLink';
import useDebounce from '../components/DebounceHook';
import { PICKUP_ALCOHOL_COUPON_CODE } from '../stores/constants';
import { ETADeliveryMethodType, ETAPaymentMethod } from '../api/ETA';
import { OrderRequests, MyfatoorahInit } from '../api/Order';
import { PAYMENT_WAITING_TIME } from '../config';
import { company } from '../company/Company';
import { CompanyName } from '../company/interface';

interface DeliveryMethodsProps {
  handleMethodChange: (method: ETADeliveryMethodType) => void;
}

const CheckoutForm = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [deliveryPrices, setDeliveryPrices] = useState<RequestETAResponse | null>(null);
  const [myfatoorahInitData, setMyfatoorahInitData] = useState<MyfatoorahInit | null>(null);
  const updatedDeliveryPopoverDismiss = () => checkoutStore.setIsUpdatedDeliveryPopover(false);
  const handleAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    checkoutStore.setAddressVal(e.currentTarget.value);
  };
  const handleAddress2Change = (e: ChangeEvent<HTMLInputElement>) => {
    checkoutStore.setAddress2Val(e.currentTarget.value);
  };
  const handleResetPromocode = () => {
    catalogStore.resetPromocode();
    mainStore.setIsInvalidPromocodePopover(false);
  };
  const handleSubmit = async () => {
    mainStore.sendToRN('hapticFeedback', {
      count: 1,
    });

    if (
      checkoutStore.activePaymentMethod === ETAPaymentMethod.Card &&
      orderStore.paymentSystem === 'myfatoorah' &&
      window.myFatoorah
    ) {
      checkoutStore.setIsLoading(true);

      try {
        const { sessionId } = await window.myFatoorah.submit() as { sessionId: string };
        checkoutStore.setSessionId(sessionId);
      } catch (error) {
        console.error(error);
        if (error === 'SessionId is not valid or expired!') getMyfatoorahInitData();
        checkoutStore.setIsLoading(false);
        return;
      }
    }

    checkoutStore.orderCheckout(stripe, elements).catch((error) => error && console.error(error));
  };
  const isDisabledForm = (): boolean => {
    if (catalogStore.calculationProcess.isLoading || catalogStore.calculationProcess.isError) {
      return true;
    }

    if (!checkoutStore.isFormValid || checkoutStore.isLoading) return true;

    if (
      checkoutStore.activePaymentMethod !== ETAPaymentMethod.Cash &&
      (stripe === null || elements === null)
    ) return true;

    if (checkoutStore.activePaymentMethod === ETAPaymentMethod.Card && orderStore.paymentSystem !== 'myfatoorah') {
      return checkoutStore.isDisabled;
    }

    return false;
  };
  const getMyfatoorahInitData = () => {
    if (orderStore.paymentSystem !== 'myfatoorah') return;
    setMyfatoorahInitData(null);
    OrderRequests.myfatoorahInit().then((data) => setMyfatoorahInitData(data)).catch(undefined);
  };

  useEffect(() => {
    if (!stripe || !elements) {
      mainStore.pushAlert('error', t('errors:paymentSystemNotLoaded'));
      Sentry.captureMessage('[Checkout] Failed Stripe init', 'warning');
      history.replace('/');
      return;
    }
    checkoutStore
      .requestPayments()
      .catch((error) => error && console.error(error))
      .finally(() => {
        if (checkoutStore.isRefreshed && !isDisabledForm()) {
          checkoutStore.orderCheckout(stripe, elements).catch(
            (error) => error && console.error(error));
        }
      });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (checkoutStore.orderId) return;

    if (
      catalogStore.promocode.success &&
      mainStore.isZero(catalogStore.totalCartPrice.promocodeDiscount) &&
      catalogStore.promocode.coupon?.minimumPurchase
    ) {
      if (catalogStore.promocode.coupon?.code !== PICKUP_ALCOHOL_COUPON_CODE) {
        mainStore.setIsInvalidPromocodePopover(true);
      }
    }
    //eslint-disable-next-line
  }, [catalogStore.totalCartPrice]);

  useEffect(() => {
    if (checkoutStore.orderId || catalogStore.cart.length) return;
    history.replace('/');
    //eslint-disable-next-line
  }, [catalogStore.cart.length]);

  useEffect(() => {
    checkoutStore.setAddressVal(userStore.deliveryAddress?.address1 || '');
    checkoutStore.setAddress2Val(userStore.deliveryAddress?.address2 || '');
    //eslint-disable-next-line
  }, [userStore.deliveryAddress]);

  useEffect(() => {
    if (!checkoutStore.orderId) return;
    history.push({
      pathname: `/status/${checkoutStore.orderId}`,
      state: { linkBack: '/' },
    });
    //eslint-disable-next-line
  }, [checkoutStore.orderId]);

  useEffect(() => {
    setDeliveryPrices(checkoutStore.deliveryPrices);
    //eslint-disable-next-line
  }, [checkoutStore.isUpdatedDeliveryPopover]);

  useEffect(() => {
    if (orderStore.paymentSystem !== 'myfatoorah') return;
    getMyfatoorahInitData();
  }, [orderStore.paymentSystem]);

  useEffect(() => {
    if (!checkoutStore.error) return;
    getMyfatoorahInitData();
  }, [checkoutStore.error]);

  const handleDeliveryMethodChange = (method: ETADeliveryMethodType) => {
    if (checkoutStore.isLoading) return;
    if (method === checkoutStore.deliveryMethod) return;
    checkoutStore.setDeliveryMethod(method);
    orderStore.orderIdempotencyCancel().catch((error) => error && console.error(error));

    if (method === ETADeliveryMethodType.JiffyDelivery) {
      orderStore.getDeliveryCost().catch((error) => error && console.error(error));
    }
    mainStore.sendToRN('logAmplitudeEvent', {
      name: 'Delivery method: Selected',
      params: {
        property: (t('deliveryMethods', { returnObjects: true }) as Record<string, string>)[method],
      },
    });
  };

  return (
    <>
      <div className="scroll-layout h-100p">
        <div className="px-24 pt-12">
          <div className="d-flex justify-content-between">
            <div className="fs-14 fw-possibly-bold">{t('yourAddress')}</div>
            <div className="fs-14">
              {orderStore.etaCalculation?.duration.range || '-'} {t('min')}.
            </div>
          </div>
          <div
            className="d-flex flex-direction-row align-items-center justify-content-between flex-gap-20 position-relative h-48 br-8 border-teb c-bg-white px-12 py-7 mt-10"
            onClick={() => mainStore.setIsChangeAddressPopover(true)}
          >
            <div className="d-flex flex-direction-column overflow-hidden">
              <div className="text-truncate fs-13 c-udgray">
                {userStore.deliveryAddress?.shortAddress}
              </div>
              <div className="text-truncate fs-10 c-mgray">
                {userStore.deliveryAddress?.address1}
              </div>
            </div>
            <div className="icon icon-rtl icon-chevron-right s-24 pe-n fs-14 d-flex flex-center" />
          </div>
          {userStore.deliveryAddress?.address1.length === 0 && (
            <>
              <div className="fs-14 mt-16">
                {t('yourAddress')} <span className="c-red">*</span>
              </div>
              <InputText
                className="mt-10"
                placeholder={t('checkoutPage:addressExample')}
                value={checkoutStore.addressVal}
                onChange={handleAddressChange}
                maxLength={250}
              />
            </>
          )}
          {company.hasAddress2 &&
            <InputText
              className="mt-10"
              label={`${t('checkoutPage:address2Example')} <span class="c-red">*</span>`}
              value={checkoutStore.address2Val}
              onChange={handleAddress2Change}
              maxLength={250}
            />
          }
          <DeliveryMethods handleMethodChange={handleDeliveryMethodChange} />
          {!company.isIncludedIn([CompanyName.Swifft]) && <DeliveryInstructions />}
          <PersonalData />
          <PaymentMethods />
          {checkoutStore.activePaymentMethod === ETAPaymentMethod.Card && (
            <>
              <div className="fs-14 mt-20 fw-possibly-bold">{t('paymentDetails')}</div>
              {orderStore.paymentSystem === 'stripe' && <StripeCardPayment />}
              {orderStore.paymentSystem === 'myfatoorah' && myfatoorahInitData !== null && (
                <MyfatoorahCardPayment initData={myfatoorahInitData} />
              )}
            </>
          )}
          {(
            checkoutStore.activePaymentMethod === AdditionalPaymentMethod.KNET ||
            checkoutStore.activePaymentMethod === AdditionalPaymentMethod.VisaMastercard
          ) && (
            <>
              {checkoutStore.paymentIntent !== null && (
                <div className="fw-700 text-center mb-24">
                  {t('checkoutPage:awaiting3ds')}
                  {' '}
                  <Countdown duration={PAYMENT_WAITING_TIME} />
                </div>
              )}
              {checkoutStore.error && (
                <div className="alert _error mt-10 z-auto mb-24">{checkoutStore.error}</div>
              )}
            </>
          )}
          {(stripe === null || elements === null) && (
            <div className="alert _error mt-10 z-auto">{t('errors:paymentSystemNotLoaded')}</div>
          )}
          {checkoutStore.isUnableConfirmCardPayment && (
            <Link
              className="alert _warning mt-10 z-auto"
              to="/chat"
              dangerouslySetInnerHTML={{ __html: t('checkoutPage:unableConfirmCardPayment') }}
            />
          )}
          {checkoutStore.activePaymentMethod === ETAPaymentMethod.Cash ? (
            <div className="mt-10 fs-12 lh-16 c-dgray px-14">{t('checkoutPage:cashPayment')}</div>
          ) : (
            <div className="mt-10 fs-12 lh-16 c-dgray px-14">{t('checkoutPage:freezeAmount')}</div>
          )}
          <div className="mt-10 fs-12 lh-16 c-dgray px-14">
            <Trans i18nKey="checkoutPage:terms">
              By ordering, you accept our
              <ExternalLink
                className="c-dgray text-underline"
                to={{ pathname: '/inject', state: { page: 'terms' } }}
                href={company.config.links.terms}
              >
                Terms and Conditions
              </ExternalLink>
            </Trans>
          </div>
        </div>
        <div className="h-24" />
      </div>
      <div className="cart-board _expanded z-auto d-block">
        <button
          className="button _primary w-100p flex-gap-8"
          disabled={isDisabledForm()}
          onClick={handleSubmit}
        >
          {checkoutStore.isLoading || catalogStore.calculationProcess.isLoading ? (
            <>
              <span className="spinner" />
              <span className="c-tad fs-12 ml-6">{t('checkoutPage:tryingFreezeMoney')}</span>
            </>
          ) : (
            <>
              <span className="icon icon-rtl icon-security s-22 d-flex flex-center fs-20 mie-8" />
              <span>
                {t('toPay')} <strong>{mainStore.addCurrencySymbol(catalogStore.finalPrice)}</strong>
              </span>
            </>
          )}
        </button>
      </div>
      <PopoverTimeout />

      <Popover isShow={mainStore.isInvalidPromocodePopover}>
        <div className="fs-21  fw-500">{t('cartPage:promocodeNotFoundTitle')}</div>
        <div className="fs-14 lh-20 mt-20">
          {!catalogStore.isPromoCodeApplied ? t('errors:promocodePriceLess', {
              amountLeft: mainStore.addCurrencySymbol(
                catalogStore.totalCartPrice.amountLeftToDiscount),
              discount: catalogStore.formatPromocodeDiscountAmount,
            })
            : catalogStore.promocode.message}
        </div>
        <div className="d-flex align-items-center justify-content-end mt-24">
          <Link
            className="h-40 mie-20 px-10 lh-40 c-blue fs-14  fw-500"
            to="/cart"
            onClick={() => mainStore.setIsInvalidPromocodePopover(false)}
          >
            {t('cartPage:changePromocode')}
          </Link>
          <div className="button _primary h-40 fs-14" onClick={handleResetPromocode}>
            {t('checkout')}
          </div>
        </div>
      </Popover>

      <Popover isShow={mainStore.isOpenNewDeliveryMethodForPromocode}>
        <div className="fs-14 lh-20 mt-20">
          {t('errors:wrongDeliveryType:description', {
            discount: catalogStore.formatPromocodeDiscountAmount,
          })}
        </div>
        <div className="d-flex align-items-center justify-content-end mt-24">
          <div
            className="h-40 mie-20 px-10 lh-40 c-blue fs-14  fw-500"
            onClick={() => {
              handleDeliveryMethodChange(
                mainStore.newDeliveryMethodForPromocodePopover as ETADeliveryMethodType);
              mainStore.setNewDeliveryMethodForPromocodePopover('none');
            }}
          >
            {t('errors:wrongDeliveryType:chooseDelivery')}
          </div>

          <div className="button _primary h-40 fs-14" onClick={() => mainStore.setNewDeliveryMethodForPromocodePopover(
            null)}>
            {t('errors:wrongDeliveryType:keepPromo')}
          </div>
        </div>
      </Popover>

      <Popover isShow={checkoutStore.isUpdatedDeliveryPopover} onBackdropDismiss={updatedDeliveryPopoverDismiss}>
        <div className="fs-21  fw-500">{t('checkoutPage:updatedDeliveryTerms')}</div>
        <div className="d-flex align-items-start justify-content-between fs-12 mt-20">
          <div className="c-tad mie-20">{t('oldPrice')}:</div>
          <div className="c-udgray">
            {mainStore.addCurrencySymbol(deliveryPrices?.oldPrice || '0')}
          </div>
        </div>
        <div className="h-1 pe-n c-bg-tf2 my-8" />
        <div className="d-flex align-items-start justify-content-between fs-12">
          <div className="c-tad mie-20">{t('newPrice')}:</div>
          <div className="c-udgray">
            {mainStore.addCurrencySymbol(deliveryPrices?.newPrice || '0')}
          </div>
        </div>
        <div className="button _primary w-100p mt-20" onClick={updatedDeliveryPopoverDismiss}>
          {t('understood')}
        </div>
      </Popover>
    </>
  );
});

const PersonalData = observer(() => {
  const { t } = useTranslation();
  const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    checkoutStore.setCommentVal(e.currentTarget.value);
  };
  const handlePhoneRecipientChange = (val: string) => checkoutStore.setRecipientTempPhoneVal(val);
  const handlePhoneRecipientValidate = (flag: boolean) => {
    checkoutStore.setIsRecipientTempPhoneValid(flag);
  };
  const handleNameRecipientChange = (e: ChangeEvent<HTMLInputElement>) => {
    checkoutStore.setRecipientTempNameVal(e.currentTarget.value);
  };
  const handleEmailRecipientChange = (e: ChangeEvent<HTMLInputElement>) => {
    checkoutStore.setRecipientTempEmailVal(e.currentTarget.value);
  };
  const handlePopoverRecipientShow = () => checkoutStore.setIsShowRecipientPopover(true);
  const handlePopoverRecipientDismiss = () => checkoutStore.setIsShowRecipientPopover(false);
  const handlePopoverRecipientSave = () => {
    checkoutStore.setRecipient();
    checkoutStore.setIsShowRecipientPopover(false);
  };

  useEffect(() => {
    if (userStore.deliveryAddress?.comment) {
      checkoutStore.setCommentVal(userStore.deliveryAddress?.comment || '');
    }
    //eslint-disable-next-line
  }, [userStore.deliveryAddress?.addressId]);

  return (
    <>
      {!company.isIncludedIn([CompanyName.Swifft]) && (
        <InputText className="mt-24" value={checkoutStore.commentVal}>
        <textarea
          className="checkout-comment input-textarea"
          placeholder={t('leaveComment')}
          onChange={handleCommentChange}
          value={checkoutStore.commentVal}
          maxLength={250}
        />
        </InputText>
      )}
      <div className="fs-14 mt-20 fw-possibly-bold">{t('personalData')}</div>
      {checkoutStore.isPersonalDataValid && !checkoutStore.isPersonalDataChanged ? (
        <div
          className="position-relative h-48 br-8 border-teb c-bg-white px-12 py-7 mt-10 d-flex align-items-center overflow-hidden flex-gap-16"
          onClick={handlePopoverRecipientShow}
        >
          <div className="d-flex flex-row justify-content-between flex-grow-1">
            <div className="text-truncate fs-14 c-text mr-12">
              {checkoutStore.isRecipientValid ? checkoutStore.recipientNameVal : userStore.fullName}
            </div>
            <div className="text-right flex-shrink-0 w-max-50p">
              <div className="fs-13 c-udgray text-truncate ltr">
                {mainStore.prettyPhoneNumber(
                  checkoutStore.isRecipientValid
                  ? checkoutStore.recipientPhoneVal
                  : checkoutStore.phoneVal,
                  )}
              </div>
              <div className="fs-12 c-mgray text-truncate">
                {checkoutStore.isRecipientValid
                  ? checkoutStore.recipientEmailVal
                  : checkoutStore.emailVal}
              </div>
            </div>
          </div>
          <div className="icon icon-rtl icon-chevron-right s-24 pe-n fs-14 d-flex flex-center" />
        </div>
      ) : (
        <PersonalDataForm />
      )}
      <Popover
        isShow={checkoutStore.isShowRecipientPopover}
        onBackdropDismiss={handlePopoverRecipientDismiss}
      >
        <div className="fs-21  fw-500">{t('checkoutPage:changeRecipient')}</div>
        <InputPhone
          className="mt-24"
          label={`${t('phone')} <span class="c-red">*</span>`}
          value={checkoutStore.recipientTempPhoneVal}
          onChange={handlePhoneRecipientChange}
          onValidate={handlePhoneRecipientValidate}
        />
        <InputText
          className="mt-10"
          label={`${t('yourName')} <span class="c-red">*</span>`}
          value={checkoutStore.recipientTempNameVal}
          onChange={handleNameRecipientChange}
        />
        <InputText
          className="mt-10"
          label={`${t('email')} <span class="c-red">*</span>`}
          value={checkoutStore.recipientTempEmailVal}
          isValid={checkoutStore.isRecipientTempEmailValid}
          isError={
            !!(
              checkoutStore.recipientTempEmailVal.length && !checkoutStore.isRecipientTempEmailValid
            )
          }
          onChange={handleEmailRecipientChange}
        />
        <div
          className="fs-13 c-blue mt-12"
          onClick={() => checkoutStore.autofillRecipient()}
        >
          {t('checkoutPage:autofill')}
        </div>
        <div className="mt-10 c-tad fs-12 lh-16 px-14">{t('checkoutPage:storedData')}</div>
        <div className="d-flex align-items-center justify-content-end mt-24">
          <div
            className="h-40 mie-20 px-10 lh-40 c-blue fs-14"
            onClick={handlePopoverRecipientDismiss}
          >
            {t('cancel')}
          </div>
          <div
            className={htmlClasses('button _primary h-40 fs-14', {
              _disabled: !checkoutStore.isRecipientTempValid,
            })}
            onClick={handlePopoverRecipientSave}
          >
            {t('change')}
          </div>
        </div>
      </Popover>
    </>
  );
});

const PersonalDataForm = observer(() => {
  const { t } = useTranslation();
  const nameVal = useDebounce<string>(checkoutStore.nameVal, 600);
  const emailVal = useDebounce<string>(checkoutStore.emailVal, 800);
  const handlePhoneValidate = (flag: boolean) => checkoutStore.setIsPhoneValid(flag);
  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    checkoutStore.setIsPersonalDataChanged(true);
    checkoutStore.setIsPersonalDataSync(false);
    checkoutStore.setNameVal(e.currentTarget.value);
    userStore.setPersonalDataName(e.currentTarget.value);
  };
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    checkoutStore.setIsEmailSync(false);
    checkoutStore.setIsPersonalDataChanged(true);
    checkoutStore.setIsPersonalDataSync(false);
    checkoutStore.setEmailVal(e.currentTarget.value);
  };

  useEffect(() => {
    const { firstName, middleName, lastName } = userStore.splitFullName(nameVal);
    userStore.updatePersonalData({
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      email: checkoutStore.isEmailValid ? checkoutStore.emailVal.trim() : (userStore.personalData.email || ''),
    }).catch((error) => error && console.error(error)).finally(() => {
      checkoutStore.setIsPersonalDataSync(true);
    });
  }, [nameVal]);

  useEffect(() => {
    if (!checkoutStore.isEmailValid) return;
    userStore
      .updatePersonalEmail(emailVal.trim())
      .then((flag) => {
        checkoutStore.setIsEmailSync(flag);
        checkoutStore.setIsPersonalDataSync(true);
      })
      .catch((error) => error && console.error(error));
  }, [emailVal]);

  return (
    <>
      <InputPhone
        className="mt-10"
        label={`${t('phone')} <span class="c-red">*</span>`}
        value={checkoutStore.phoneVal}
        onValidate={handlePhoneValidate}
        disabled={true}
      />
      <InputText
        className="mt-10"
        label={`${t('yourName')} <span class="c-red">*</span>`}
        value={checkoutStore.nameVal}
        onChange={handleNameChange}
      />
      <InputText
        className="mt-10"
        label={`${t('email')} <span class="c-red">*</span>`}
        value={checkoutStore.emailVal}
        isValid={checkoutStore.isEmailValid && checkoutStore.isEmailSync}
        isError={!!(checkoutStore.emailVal.length && !checkoutStore.isEmailValid)}
        onChange={handleEmailChange}
      />
      <div className="mt-10 c-tad fs-12 lh-16 px-14">{t('checkoutPage:emailDesc')}</div>
    </>
  );
});

export const PaymentMethods = observer(() => {
  const { t } = useTranslation();
  const methodItemContent: Record<PaymentMethod, JSX.Element> = {
    [MobilePaymentMethod.ApplePay]:
      <img className="d-block" src={LogoApplePay} height="38" alt="" />,
    [MobilePaymentMethod.GooglePay]:
      <img className="d-block" src={LogoGooglePay} height="40" alt="" />,
    [ETAPaymentMethod.Card]: <div className="d-flex align-items-center">
      {orderStore.paymentSystem === 'myfatoorah' ?
        <>
          <img className="mie-3 flex-shrink-0" src={IconCardVisa} height="16" alt="" />
          <img className="mie-6 flex-shrink-0" src={IconCardMC} height="16" alt="" />
          {t('creditCard')}
        </> :
        <>
          <img className="mie-6 flex-shrink-0" src={IconCard} height="14" alt="" />
          {t('bankCard')}
        </>
      }
    </div>,
    [ETAPaymentMethod.Cash]: <div className="d-flex align-items-center">
      <img className="mie-6 flex-shrink-0" src={IconCash} height="14" alt="" />
      {t('cash')}
    </div>,
    [AdditionalPaymentMethod.KNET]: <div className="d-flex align-items-center">
      <img className="mie-6 flex-shrink-0" src={IconCard} height="14" alt="" />
      {t('knet')}
    </div>,
    [AdditionalPaymentMethod.VisaMastercard]: <div className="d-flex align-items-center">
      {orderStore.paymentSystem === 'myfatoorah' ?
        <>
          <img className="mie-3 flex-shrink-0" src={IconCardVisa} height="16" alt="" />
          <img className="mie-6 flex-shrink-0" src={IconCardMC} height="16" alt="" />
          {t('debitCard')}
        </> :
        <>
          <img className="mie-6 flex-shrink-0" src={IconCard} height="14" alt="" />
          {t('visaMastercard')}
        </>
      }
    </div>,
  };

  useEffect(() => {
    const activePaymentMethod = checkoutStore.activePaymentMethod;
    checkoutStore.initActivePaymentMethod();
    if (activePaymentMethod && !checkoutStore.availablePaymentMethods.includes(
      activePaymentMethod)) {
      mainStore.pushAlert('warning', t('checkoutPage:optionDisabled', {
        option: t(activePaymentMethod === ETAPaymentMethod.Cash ? 'cash' : 'bankCard'),
      }));
    }
  }, [checkoutStore.availablePaymentMethods]);

  if (
    !checkoutStore.availablePaymentMethods.length ||
    (
      checkoutStore.availablePaymentMethods.length === 1 &&
      checkoutStore.availablePaymentMethods.includes(ETAPaymentMethod.Card)
    )
  ) return <></>;

  return (
    <>
      <div className="fs-14 mt-20 fw-possibly-bold">{t('paymentMethod')}</div>
      <div className="d-flex flex-wrap mt-8 pt-8 mx-n24 px-24 checkout-instruction-wrapper">
        {checkoutStore.availablePaymentMethods.map((method) => {
          const isMobilePaymentMethod = Object.values(MobilePaymentMethod).includes(
            method as MobilePaymentMethod);

          return (
            <div
              className={htmlClasses('checkout-instruction _method', {
                _gpay: isMobilePaymentMethod,
                _active: checkoutStore.activePaymentMethod === method,
              })}
              onClick={() => checkoutStore.setActivePaymentMethod(method)}
              key={method}
            >
              {!isMobilePaymentMethod ? (
                <>
                  <div className="icon icon-rtl icon-check-box checkout-instruction__check s-18 d-flex flex-center flex-shrink-0 rounded-circle c-white fs-16" />
                  <div>{methodItemContent[method]}</div>
                </>
              ) : methodItemContent[method]}
            </div>
          );
        })}
      </div>
      {checkoutStore.activePaymentMethod !== ETAPaymentMethod.Cash && (
        <div className="d-flex flex-gap-6 align-items-center c-dgray mt-12 mb-24">
          <div className="icon icon-rtl icon-security c-dgray s-12 fs-12 d-flex flex-center" />
          <div className="fs-12 ff-manrope lh-14">{t('checkoutPage:securePaymentGuarantee')}</div>
        </div>
      )}
    </>
  );
});

const StripeCardPayment = observer(() => {
  const { t } = useTranslation();
  const [isCardEmpty, setIsCardEmpty] = useState('');
  const handleAgreeChange = () => checkoutStore.setAgreeSaveCard(!checkoutStore.agreeSaveCard);
  const handleNewCard = (flag: boolean) => checkoutStore.setIsAddNewCard(flag);
  const paymentsChange = (val: string) => {
    checkoutStore.setPaymentsMethodId(val);
    checkoutStore.setIsDisabled(false);
    orderStore.setDefaultPayment(val).catch((error) => error && console.error(error));
    mainStore.sendToRN('analytics', {
      name: 'Purchase: payment method filled in',
      params: {
        cart_id: undefined,
        payment_method: 'card',
        type: 'existing',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'add_payment_info',
      params: {
        currency: orderStore.currency.toUpperCase(),
        payment_type: 'credit card',
        value: mainStore.toFloat(catalogStore.finalPrice),
        items: catalogStore.cartForFirebase,
        coupon: catalogStore.promocode.value,
      },
    });
  };
  const handleChange = (e: StripeCardElementChangeEvent) => {
    setIsCardEmpty(e.empty ? '' : 'true');
    checkoutStore.setIsDisabled(!e.complete);
    checkoutStore.setError(e.error ? e.error.message : '');
    if (e.complete) {
      mainStore.sendToRN('analytics', {
        name: 'Purchase: payment method filled in',
        params: {
          cart_id: undefined,
          payment_method: 'card',
          type: 'new',
        },
      });
      mainStore.sendToRN('firebaseAnalytics', {
        name: 'add_payment_info',
        params: {
          currency: orderStore.currency.toUpperCase(),
          payment_type: 'credit card',
          value: mainStore.toFloat(catalogStore.finalPrice),
          items: catalogStore.cartForFirebase,
          coupon: catalogStore.promocode.value,
        },
      });
    }
  };

  return (
    <>
      {Array.isArray(checkoutStore.payments) &&
      checkoutStore.payments.length > 0 &&
      !checkoutStore.isAddNewCard ? (
        <>
          <Select
            className="_expand-up mt-10"
            initialValue={checkoutStore.payments[0].text}
            data={checkoutStore.payments}
            onChange={paymentsChange}
          />
          <div
            className="mt-10 ml-14 fs-14 c-blue"
            onClick={() => handleNewCard(true)}
          >
            {t('checkoutPage:addNewCard')}
          </div>
          {checkoutStore.error && (
            <div className="alert _error mt-10 z-auto">{checkoutStore.error}</div>
          )}
        </>
      ) : (
        <>
          <InputText className="mt-10 px-12 pt-15" value={isCardEmpty}>
            <CardElement
              className="z-3 position-relative"
              options={checkoutStore.cardStyle}
              onChange={handleChange}
            />
          </InputText>
          {checkoutStore.isAddNewCard &&
            Array.isArray(checkoutStore.payments) &&
            checkoutStore.payments.length && (
              <div
                className="mt-10 ml-14 fs-14 c-blue"
                onClick={() => handleNewCard(false)}
              >
                {t('checkoutPage:chooseCardInList')}
              </div>
            )}
          {checkoutStore.error && (
            <div className="alert _error mt-10 z-auto">{checkoutStore.error}</div>
          )}
          <div className="d-flex align-items-start mt-20 mb-20 ml-14" onClick={handleAgreeChange}>
            <Checkbox className="mr-10 flex-shrink-0" checked={checkoutStore.agreeSaveCard} />
            <div className="fs-13 c-gray">{t('checkoutPage:saveCardCheckbox')}</div>
          </div>
        </>
      )}
    </>
  );
});

const MyfatoorahCardPayment = observer(({ initData }: { initData: MyfatoorahInit }) => {
  const { t } = useTranslation();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!window.myFatoorah || isInitialized) return;

    window.myFatoorah.init({
      countryCode: initData.CountryCode,
      sessionId: initData.SessionId,
      cardViewId: 'card-element',
      style: {
        hideCardIcons: false,
        direction: 'ltr',
        cardHeight: '180',
        input: {
          color: 'black',
          fontSize: '13px',
          fontFamily: 'sans-serif',
          inputHeight: '32px',
          inputMargin: '0px',
          borderColor: 'c7c7c7',
          borderWidth: '1px',
          borderRadius: '8px',
          boxShadow: '',
          placeHolder: {
            holderName: 'Name On Card',
            cardNumber: 'Number',
            expiryDate: 'MM / YY',
            securityCode: 'CVV',
          },
        },
        label: {
          display: false,
        },
        error: {
          borderColor: 'red',
          borderRadius: '8px',
          boxShadow: '0px',
        },
      },
    });
    setIsInitialized(true);
  }, [window.myFatoorah, isInitialized]);

  return (
    <>
      <div id="card-element" className="mt-10"></div>

      {checkoutStore.paymentIntent !== null && (
        <div className="fw-700 text-center">
          {t('checkoutPage:awaiting3ds')}
          {' '}
          <Countdown duration={PAYMENT_WAITING_TIME} />
        </div>
      )}

      {checkoutStore.error && (
        <div className="alert _error mt-10 z-auto">{checkoutStore.error}</div>
      )}
    </>
  );
});

const DeliveryInstructions = observer(() => {
  const { t } = useTranslation();
  const [instructionList, setInstructionList] = useState<string[]>([]);
  const handleToggleInstruction = (val: string, index: number) => {
    if (checkoutStore.instructionList[index]) checkoutStore.deleteInstruction(index);
    else checkoutStore.addInstruction(val, index);
  };

  useEffect(() => {
    const list = (t('deliveryInstructionsList', { returnObjects: true }) as string[]) || [];
    if (list.length && catalogStore.isAdultItemInCart) list.shift();
    setInstructionList(list);
  }, [t]);

  useEffect(() => {
    if (!instructionList.length || !userStore.deliveryAddress?.instructions?.length) return;
    checkoutStore.resetInstructionList();
    for (let i = 0; i < instructionList.length; i++) {
      if (userStore.deliveryAddress.instructions.indexOf(instructionList[i]) !== -1) {
        handleToggleInstruction(instructionList[i], i);
      }
    }
    //eslint-disable-next-line
  }, [instructionList, userStore.deliveryAddress?.addressId]);

  useEffect(() => {
    if (checkoutStore.activePaymentMethod !== ETAPaymentMethod.Cash || instructionList.length < 3) {
      return;
    }

    if (checkoutStore.instructionList[0]) {
      handleToggleInstruction(instructionList[0], 0);
      mainStore.pushAlert('warning', t('checkoutPage:optionDisabled', {
        option: instructionList[0],
      }));
    }
  }, [checkoutStore.activePaymentMethod]);

  if (checkoutStore.deliveryMethod === ETADeliveryMethodType.ClickAndCollect) return <></>;

  return (
    <div className="d-flex flex-gap-8 text-nowrap overflow-auto mt-8 pt-8 hide-scroll-bar h-48 mx-n24 px-24">
      {instructionList.map((el, i) => (
        <div
          className={htmlClasses('checkout-instruction', {
            _disable: !i && instructionList.length > 2 && checkoutStore.activePaymentMethod === ETAPaymentMethod.Cash,
            _active: checkoutStore.instructionList[i],
          })}
          onClick={() => handleToggleInstruction(el, i)}
          key={i}
        >
          <div className="icon icon-rtl icon-check-box checkout-instruction__check s-18 d-flex flex-center flex-shrink-0 rounded-circle c-white fs-16" />
          <div>{el}</div>
        </div>
      ))}
    </div>
  );
});

const DeliveryMethods = observer(({ handleMethodChange }: DeliveryMethodsProps) => {
  const { t } = useTranslation();
  const onOpenPromocodeMethodChange = (method: ETADeliveryMethodType) => {
    if (catalogStore.promocode.deliveryMethod && mainStore.newDeliveryMethodForPromocodePopover !== 'none') {
      mainStore.setNewDeliveryMethodForPromocodePopover(method);
      return;
    }

    handleMethodChange(method);
  };

  useEffect(() => {
    if (catalogStore.promocode.deliveryMethod && mainStore.newDeliveryMethodForPromocodePopover !== 'none') {
      handleMethodChange(catalogStore.promocode.deliveryMethod);
    }
  }, []);

  if (
    !orderStore.etaCalculation ||
    (
      checkoutStore.availableDeliveryMethods.length <= 1 &&
      checkoutStore.availableDeliveryMethods[0] === ETADeliveryMethodType.JiffyDelivery
    )
  ) return <></>;

  return (
    <>
      <div className="d-flex pt-8">
        {checkoutStore.availableDeliveryMethods.map((method, i) => (
          <div
            className={htmlClasses('checkout-instruction w-100p justify-content-center', {
              _active: checkoutStore.deliveryMethod === method,
              _disable: checkoutStore.isLoading,
            })}
            onClick={() => onOpenPromocodeMethodChange(method)}
            key={i}
          >
            <div className="icon icon-rtl icon-check-box checkout-instruction__check s-18 d-flex flex-center flex-shrink-0 rounded-circle c-white fs-16" />
            <div className="text-nowrap">
              {(t('deliveryMethods', { returnObjects: true }) as Record<string, string>)[method]}
            </div>
          </div>
        ))}
      </div>
      {checkoutStore.deliveryMethod === ETADeliveryMethodType.ClickAndCollect && (
        <>
          <div className="mt-12 fs-14 lh-22">{t('orderReadyIn')}</div>
          {orderStore.etaCalculation.warehouse.address.length > 0 && (
            <div className="mt-12 fs-14 lh-22">
              <span className="c-gray">{t('pickupFrom')}</span>
              {' '}
              {orderStore.etaCalculation.warehouse.address}
              {orderStore.etaCalculation.distance > 0 ? ` (${orderStore.etaCalculation.distance}m)` : ''}
            </div>
          )}
        </>
      )}
    </>
  );
});

const PopoverTimeout = observer(() => {
  const { t } = useTranslation();
  const handleClick = () => {
    checkoutStore.setStateData();
    window.location.reload();
  };

  return (
    <Popover isShow={checkoutStore.isShowTimeoutPopover}>
      <div className="fs-21  fw-500">{t('errors:unknown')}</div>
      <div className="fs-12 lh-16 mt-10 c-tad">{t('checkoutPage:clickPayNow')}</div>
      <div className="button _primary mt-20 w-100p" onClick={handleClick}>
        {t('checkoutPage:payNow')}
      </div>
    </Popover>
  );
});

const Countdown = observer(({ duration }: { duration: number }) => {
  const [seconds, setSeconds] = useState(duration);
  const parseSeconds = (sec: number) => {
    if (sec <= 0) return '00:00';
    return `${mainStore.addLeadingZero(Math.floor((sec % 3600) / 60))}:${mainStore.addLeadingZero(
      Math.round(sec % 60))}`;
  };
  const [countdown, setCountdown] = useState(parseSeconds(seconds));

  useEffect(() => {
    if (seconds <= 0) return;

    const timeout = setTimeout(() => {
      const secondsLeft = seconds - 1;
      setCountdown(parseSeconds(secondsLeft));
      setSeconds(secondsLeft);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [seconds]);

  return <>{countdown}</>;
});

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const [stripePromise, setStripePromise] = useState<Stripe | null | undefined>(undefined);
  const [isReady, setIsReady] = useState(false);
  const init = useCallback(async () => {
    let stripePromise: Stripe | null = null;
    try {
      stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string, {
        locale: 'en-GB',
      });
      setStripePromise(stripePromise || null);
    } catch (error) {
      error && console.error(error);
    }
    if (!stripePromise) {
      mainStore.pushAlert('error', t('errors:paymentSystemNotLoaded'));
      Sentry.captureMessage('[Checkout] Failed Stripe loading', 'warning');
      history.replace('/');
      return;
    }

    try {
      checkoutStore.setIsUpdatedDeliveryPopover(false);
      await catalogStore.calculateCart().catch((error) => error && console.error(error));
      await userStore.requestPersonalData().catch((error) => error && console.error(error));
      if (!orderStore.freezeETAExpired) {
        await orderStore.getDeliveryCost().catch((error) => error && console.error(error));
      }
      checkoutStore.setPhoneVal(userStore.personalData.phone || '');
      checkoutStore.setNameVal(userStore.fullName);
      checkoutStore.setEmailVal(userStore.personalData.email || '');
      checkoutStore.initStateData();
    } catch (error) {
      checkoutStore.setNameVal('');
      checkoutStore.setEmailVal('');
      Sentry.captureMessage('[Checkout] Failed to load data', 'warning');
    }
    if (catalogStore.promocode.value) {
      catalogStore
        .applyPromocode(catalogStore.promocode.value, 'checkout')
        .then((e) => {
          if (!e) mainStore.setIsInvalidPromocodePopover(true);
        })
        .catch(() => {
          mainStore.setIsInvalidPromocodePopover(true);
        });
    }
    mainStore.sendAnalytics(['BI', 'analytics', 'yaMetrika'], {
      name: 'Purchase: start checkout',
      params: {
        cart_id: undefined,
        products_amount: catalogStore.cart.reduce((sum, item) => sum + item.count, 0),
        items_amount: catalogStore.cart.length,
        price: catalogStore.totalCartPrice.base,
        final_price: catalogStore.finalPrice,
        eta_min: orderStore.etaCalculation?.duration.min || 0,
        eta_max: orderStore.etaCalculation?.duration.max || 0,
        delivery_fee: orderStore.fee.shippingPounds || 0,
        threshold: orderStore.fee.thresholdPounds || 0,
        is_surger: orderStore.etaCalculation?.highDemand || false,
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'begin_checkout',
      params: {
        currency: orderStore.currency.toUpperCase(),
        value: mainStore.toFloat(catalogStore.finalPrice),
        items: catalogStore.cartForFirebase,
        coupon: catalogStore.promocode.value,
      },
    });
    setIsReady(true);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      !catalogStore.cart.length ||
      !orderStore.etaCalculation?.warehouse.availability.availableNow ||
      !userStore.deliveryAddress
    ) {
      history.replace('/');
      return;
    }
    if (orderStore.paymentSystem === undefined) return;

    init().catch((error) => error && console.error(error));

    return () => checkoutStore.resetStore();
  }, [orderStore.paymentSystem]);

  useEffect(() => {
    if (!orderStore.etaCalculation?.warehouse.availability.availableNow) history.replace('/');
    //eslint-disable-next-line
  }, [orderStore.etaCalculation?.warehouse.availability.availableNow]);

  useEffect(() => {
    if (!userStore.personalData.isAdult && catalogStore.isAdultItemInCart) {
      mainStore.setIsAgeRestrictionPopover(true);
    }
  }, [userStore.personalData.isAdult]);

  return (
    <>
      <PageHeader title={t('checkout')} />
      {stripePromise === undefined || !isReady ? (
        <div className="scroll-layout h-100p">
          <CartAwaiting />
          <div className="h-24" />
        </div>
      ) : (
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
});
