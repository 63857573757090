import { observer } from 'mobx-react-lite';
import htmlClasses from 'html-classes';
import React, { PropsWithChildren } from 'react';
import { BonusButtonProps } from '../interface';

export const BonusButton = observer((props: PropsWithChildren<BonusButtonProps>) => {
  const { isActive, onClick, children, buttonType } = props;

  const onClickButtonHandler = () => {
    onClick(buttonType);
  };

  return (
    <div
      data-testid={buttonType}
      className={htmlClasses('checkout-instruction _points w-100p justify-content-center', {
        _active: isActive,
      })}
      onClick={onClickButtonHandler}
    >
      <div className="icon icon-rtl icon-check-box checkout-instruction__check s-18 d-flex flex-center flex-shrink-0 rounded-circle c-white fs-16" />

      <div className="text-nowrap fs-14 fw-500 c-black d-flex align-items-center fw-possibly-bold">{children}</div>
    </div>
  );
});

export default BonusButton;
