/**
 * @prettier
 */

import { observer } from 'mobx-react-lite';
import React, { useLayoutEffect, useState } from 'react';
import GoogleMapReact, { ChangeEventValue } from 'google-map-react';
import { userStore } from '../../stores/UserStore';
import { mainStore } from '../../stores/MainStore';
import { DELIVERY_ZONES_STYLE } from './constants';
import { GOOGLE_API_KEY } from '../../config';
import { company } from '../../company/Company';
import store from './store';

const {
  map: { center: mapCenter, zoom: mapZoom },
} = company.config;

const GMap = observer(() => {
  const [defaultCenter, setDefaultCenter] = useState<GeoCoordinates | null>(
    null,
  );
  const handleMapChange = (e: ChangeEventValue) => {
    if (store.isActiveGeolocation) {
      return;
    }

    if (
      e.center.lat.toFixed(7) === mapCenter.lat.toFixed(7) &&
      e.center.lng.toFixed(7) === mapCenter.lng.toFixed(7)
    ) {
      return;
    }

    store.setMapCenter(e.center);
    store.setMapZoom(e.zoom);
  };

  const handleMapAction = (flag: boolean, shouldDropGeolocation: boolean) => {
    store.setIsMapBusy(flag);

    if (shouldDropGeolocation) {
      /**
       * Because "handleMapAction" is called when after zoom animation,
       * it is drop geolocation flag, and rewrite map center coordinates
       * To avoid it, we need to drop geolocation flag only when user drag map,
       * but not when map was just zoomed
       * */
      store.setIsActiveGeolocation(false);
    }
    store.setIsManuallyInput(false);
    store.setInputAddressFocused(false);
    store.setIsAddressNotCovered(false);
    store.setIsAddressNotExist(false);
  };

  const handleMapClick = () => {
    store.setIsManuallyInput(false);
    store.setInputAddressFocused(false);
    if (document.activeElement) {
      const activeEl: HTMLInputElement =
        document.activeElement as HTMLInputElement;
      activeEl.blur();
    }
  };

  const handleApiLoaded = async ({ map, maps }: { map: any; maps: any }) => {
    const coverages = await userStore.getDeliveryZones();

    if (!coverages.length) {
      return;
    }

    coverages.forEach((el) => {
      new Map(
        el.zones
          .sort((a, b) => a.priority - b.priority)
          .map((zone) => [zone.name, zone]),
      ).forEach((zone) => {
        const convert = mainStore.flatArray(
          zone.polygon.segments.map((segment) => {
            return Object.values(segment).map((coord) => ({
              lat: coord.latitude,
              lng: coord.longitude,
            }));
          }),
        );
        const style =
          DELIVERY_ZONES_STYLE[zone.priority as number] ||
          DELIVERY_ZONES_STYLE[100];

        const drawPolygons = new maps.Polygon({
          paths: convert,
          strokeWeight: 1,
          fillColor: zone.color,
          strokeColor: zone.color,
          fillOpacity: style.fillOpacity,
          strokeOpacity: style.strokeOpacity,
        });
        drawPolygons.setMap(map);
      });
    });
  };
  const center: GeoCoordinates =
    store.isActiveGeolocation && store.geolocationCoordinates
      ? store.geolocationCoordinates
      : store.deliveryAddress?.coordinates || store.mapCenter;

  useLayoutEffect(() => {
    setDefaultCenter(center);
    //eslint-disable-next-line
  }, []);

  if (defaultCenter === null) {
    return <></>;
  }

  return (
    <GoogleMapReact
      yesIWantToUseGoogleMapApiInternals
      bootstrapURLKeys={{
        key: GOOGLE_API_KEY,
        language: 'en-GB',
        region: 'GB',
      }}
      defaultCenter={defaultCenter}
      defaultZoom={mapZoom}
      zoom={store.mapZoom}
      center={center}
      options={{
        clickableIcons: false,
        disableDefaultUI: true,
        gestureHandling: 'greedy',
      }}
      onGoogleApiLoaded={handleApiLoaded}
      shouldUnregisterMapOnUnmount={true}
      onChange={handleMapChange}
      onClick={handleMapClick}
      onDrag={() => handleMapAction(true, true)}
      onDragEnd={() => handleMapAction(false, true)}
      onZoomAnimationStart={() => handleMapAction(true, false)}
      onZoomAnimationEnd={() => handleMapAction(false, false)}
      style={{
        pointerEvents: store.isChangeDeliveryAreaPopover ? 'none' : 'auto',
      }}
    />
  );
});

export default GMap;
