import { observer } from 'mobx-react-lite';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import useDebounce from '../../components/DebounceHook';
import InputText from '../../components/InputText';
import ScrollRestoration from '../../components/ScrollRestoration';
import { catalogStore } from '../../stores/CatalogStore';
import { mainStore } from '../../stores/MainStore';
import htmlClasses from 'html-classes';
import { scrollTo } from '../../UI';
import { SearchCatsResponse } from '../../api/Catalog';
import { SEARCH_PRODUCTS_STANDARD_LENGTH } from '../../config';
import EmptySearchResult from './EmptySearchResult';
import HistoryAndPopular from './HistoryAndPopular';
import CategoriesSuggestion from './CategoriesSuggestion';
import ProductList from '../../components/ProductList';

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation<{ source?: string }>();
  const refSearchField = useRef<HTMLInputElement>(null);
  const [searchVal, setSearchVal] = useState(catalogStore.searchQuery || '');
  const [isFocused, setIsFocused] = useState(!catalogStore.searchQuery);
  const [isBlur, setIsBlur] = useState(!!catalogStore.searchQuery);
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const debSearchVal = useDebounce<string>(searchVal, 500);
  const handleShowNotFindProductPopover = () => mainStore.setIsNotFindProductPopover(true);
  const handleScrollTop = () => scrollTo('scroll-layout', 0, 200);

  useEffect(() => {
    if (history.action === 'PUSH') catalogStore.resetProductsOutOfStockExpandedList();
    mainStore.sendToRN('analytics', {
      name: 'Catalog: search visited',
      params: {
        source: state?.source || 'direct',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'catalog_search_visited',
      params: {
        source: state?.source || 'direct',
      },
    });
  }, [state, history.action]);

  useEffect(() => {
    if (debSearchVal.length < 2 || !isFocused) return;
    setIsLoading(true);
    setShowAllProducts(false);
    catalogStore
      .requestSearch(debSearchVal, 'typein', SEARCH_PRODUCTS_STANDARD_LENGTH)
      .catch((error) => error && console.error(error))
      .finally(() => setIsLoading(false));
    //eslint-disable-next-line
  }, [debSearchVal]);

  useEffect(() => {
    if (!isBlur) return;
    const timeout = setTimeout(() => {
      setIsFocused(false);
    }, 100);
    return () => clearTimeout(timeout);
  }, [isBlur]);

  useEffect(() => {
    catalogStore.requestSearchHistory().catch((error) => error && console.error(error));
    if (!catalogStore.searchQuery) return;
    if (history.action === 'POP' && catalogStore.searchProductList.length) return;
    handleSearchSubmit();
  }, []);

  const search = () => {
    if (!searchVal.length) return;
    refSearchField.current?.blur();
    setShowAllProducts(true);
    setIsLoading(true);
    catalogStore.resetProductsOutOfStockExpandedList();
    catalogStore
      .requestSearch(searchVal, 'typein', 0)
      .catch((error) => error && console.error(error))
      .finally(() => setIsLoading(false));
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchVal(e.currentTarget.value);
    catalogStore.setSearchQuery(e.currentTarget.value);
  };
  const handleSearchClear = () => {
    setSearchVal('');
    catalogStore.setSearchQuery('');
    refSearchField.current?.focus();
  };
  const handleSearchFocus = () => {
    setIsFocused(true);
    setIsBlur(false);
  };
  const handleSelectCategory = (category: SearchCatsResponse) => {
    setIsFocused(false);
    if (category.parentCatID) {
      mainStore.sendToRN('analytics', {
        name: 'Catalog: category visited',
        params: {
          lvl3_category_id: category.id,
          source: 'search',
        },
      });
    }
    history.push({
      pathname: `/category/${category.parentCatID ? category.parentCatID : category.id}`,
      state: { scrollToId: category.parentCatID ? String(category.id) : null },
    });
  };
  const handleSetSuggestion = (value: string, source: 'idea' | 'hint' | 'history') => {
    setSearchVal(value);
    catalogStore.setSearchQuery(value);
    setIsFocused(false);
    setIsLoading(true);
    mainStore.setPageScrollY('search', 0);
    catalogStore.resetProductsOutOfStockExpandedList();
    catalogStore
      .requestSearch(value, source, SEARCH_PRODUCTS_STANDARD_LENGTH)
      .catch((error) => error && console.error(error))
      .finally(() => setIsLoading(false));
  };
  const handleSearchBlur = () => {
    setIsBlur(true);
  };
  const handleSearchSubmit = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    search();
  };

  return (
    <>
      <div className={
        htmlClasses(
          'd-flex search-bar__wrap flex-shrink-0 align-items-center pl-7 h-50',
          isFocused ? 'search-bar__wrap--focused' : '',
        )
      }>
        <div
          className="icon icon-rtl icon-arrow-back d-flex flex-center s-50 fs-20 c-black"
          onClick={() => (history.length === 1 ? history.push('/') : history.goBack())}
        />

        <form className="w-100p mr-16" onSubmit={handleSearchSubmit}>
          <InputText className="search-bar" value={searchVal}>
            <div className="d-flex justify-content-between align-items-center z-2 position-relative">
              <div className="icon icon-rtl icon-search search-bar__icon-search c-t99 d-flex flex-center fs-20 pe-n" />

              <input
                className="input-text"
                type="text"
                ref={refSearchField}
                placeholder={t('searchPage:inputPlaceholder')}
                onChange={handleSearchChange}
                autoFocus={!catalogStore.searchQuery}
                value={searchVal}
                onFocus={handleSearchFocus}
                onBlur={handleSearchBlur}
              />

              {searchVal.length > 0 && (
                <div
                  className="icon icon-rtl icon-close s-44 fs-18 c-t99 d-flex flex-center"
                  onClick={handleSearchClear}
                />
              )}
            </div>
          </InputText>
        </form>
      </div>
      <div className="h-16 flex-shrink-0" />
      <div className="scroll-layout h-100p px-24">
        {!searchVal ? (
          <HistoryAndPopular
            onSetSuggest={handleSetSuggestion}
            isLoading={isLoading}
            showHistory={!searchVal}
          />
        ) : (
          <>
            {isLoading ? (
              <div className="spinner mx-auto mt-20" />
            ) : catalogStore.searchProductList.length > 0 && searchVal.length > 1 ? (
              <>
                {isReady && <ScrollRestoration pageName="search" />}
                <CategoriesSuggestion
                  onSelectCategory={handleSelectCategory}
                  showHistory={!searchVal}
                />
                <ProductList
                  productList={catalogStore.searchProductList}
                  source="search"
                  onReady={() => setIsReady(true)}
                  showThreeInLine
                />
                {!showAllProducts && catalogStore.searchProductList.length >= 30 && (
                  <div className="button _bordered w-100p mt-22" onClick={search}>
                    {t('seeAllProducts')}
                  </div>
                )}
                {showAllProducts && (
                  <>
                    <div
                      className="button _bordered w-100p mt-22"
                      onClick={handleShowNotFindProductPopover}
                    >
                      {t('categoryPage:canNotFindItems')}
                    </div>
                    <div className="button _bordered w-100p mt-10" onClick={handleScrollTop}>
                      {t('backToTop')}
                    </div>
                  </>
                )}
                <div className="h-24" />
              </>
            ) : (
              <EmptySearchResult />
            )}
          </>
        )}
      </div>
    </>
  );
});
