import { observer } from 'mobx-react-lite';
import { useState, ChangeEvent, useEffect, ReactText } from 'react';
import { useTranslation } from 'react-i18next';
import Popover from './Popover';
import InputText from './InputText';
import htmlClasses from 'html-classes';
import { mainStore } from '../stores/MainStore';
import { orderStore, RateInfo } from '../stores/OrderStore';
import { userStore } from '../stores/UserStore';
import { IMaskInput, AnyMasked } from 'react-imask';
import { checkoutStore } from '../stores/CheckoutStore';

type RateStarsProps = {
  rate: number;
  onChange: (val: number) => void;
  className?: string;
};

// Only "rating" is presented here, because only general rating required
const DEFAULT_RATE_INFO_STATE: RateInfo = {
  rating: -1,
};

export default observer(() => {
  const { t } = useTranslation();
  const [rateInfo, setRateInfo] = useState(DEFAULT_RATE_INFO_STATE);
  const [orderId, setOrderId] = useState('');
  const [isShowSecondForm, setIsShowSecondForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowTip, setIsShowTip] = useState(false);

  const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
    handleChangeRateInfo(e.currentTarget.value, 'comment');

  const onDismiss = () => {
    mainStore.setIsRateOrderPopover(false);
    orderStore.setLastRatedOrderID(orderStore.activeOrderID);

    if (rateInfo.rating > 0) {
      if (
        rateInfo.rating >= 4 &&
        !userStore.personalData.isRateApp
      ) {
        mainStore.sendToRN('showRatePopup', null, (e) => {
          if (e.answer === 'NotNow') return;
          userStore.setIsRateApp(true);
        });
      }
      setRateInfo({ ...DEFAULT_RATE_INFO_STATE });
      setIsShowSecondForm(false);
      setOrderId('');
      orderStore.setOrderStatus('none');
      orderStore.setActiveOrderID('');

      if (!orderStore.tipAmount) {
        mainStore.pushAlert('success', t('ratePopover:thanksTitleFeedback'));
      }
    }

    orderStore.setTipAmount('');
  };

  const handleChangeRateInfo = (value: ReactText, field: keyof RateInfo) => {
    setRateInfo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSubmit = () => {
    if (isLoading) return;

    if (rateInfo.rating === -1 && orderStore.tipAmount) {
      mainStore.setIsPayTipPopover(true);
      onDismiss();

      return;
    }

    if (rateInfo.rating === -1) {
      /**
       * In case when user did not set rate and click on submit button
       * just close feedback by dismiss function
       * */
      onDismiss();

      return;
    }

    if (rateInfo.rating > -1 && !isShowSecondForm) {
      /**
       * Code below will be run when user set general rate and click on "continue" button
       * in such case we need to show additional form to him
       * */
      setIsShowSecondForm(true);

      return;
    }

    /**
     * Code below will be called when user will click on "submit" button in the second form
     *
     * "addFeedback" aggregate inside two functions:
     *  - "newFeedback" - will be called, because user filled rate on first screen at least
     *  - "fillFeedback" - will be called in case when user filled additional fields from second form
     * */
    setIsLoading(true);
    orderStore.addFeedback(orderId, rateInfo)
      .then((success) => {
        if (!success) {
          return;
        }

        if (orderStore.tipAmount) {
          mainStore.setIsPayTipPopover(true);
        }
        onDismiss();
      })
      .catch((error) => error && console.error(error))
      .finally(() => setIsLoading(false));
  };

  const handleGeneralRate = (val: number) => {
    if (!orderId || isLoading) return;

    mainStore.sendToRN('sendTags', {
      last_order_feedback_score: val,
    });
    mainStore.sendToRN('setUserProperties', {
      'Commerce: last order rating': val,
    });

    handleChangeRateInfo(val, 'rating');
  };

  useEffect(() => {
    if (!orderStore.activeOrderID) return;
    setOrderId(orderStore.activeOrderID);
    //eslint-disable-next-line
  }, [orderStore.activeOrderID]);

  useEffect(() => {
    if (!mainStore.isRateOrderPopover) return;
    orderStore.checkPayedTip(orderId).then((e) => {
      setIsShowTip(!e);
    }).catch((error) => error && console.error(error));
    checkoutStore.requestPayments().catch((error) => error && console.error(error));
    //eslint-disable-next-line
  }, [mainStore.isRateOrderPopover]);

  const {
    rating,
    deliveryRating = -1,
    productQualityRating = -1,
    productVarietyRating = -1,
    comment = '',
  } = rateInfo;

  return (
    <Popover isShow={mainStore.isRateOrderPopover} onBackdropDismiss={!isShowSecondForm ? onDismiss : undefined}>
      <div className='fs-21 lh-25'>{t('ratePopover:title')}</div>

      <div className='fs-14 lh-20 mt-10 '>{t('ratePopover:announce')}</div>

      <div className='d-flex mt-20'>
        <RateStars
          className={htmlClasses('s-40 fs-40 mx-7', { 'pe-n': isLoading })}
          rate={rating}
          onChange={handleGeneralRate}
        />
      </div>

      {isShowSecondForm && (
        <>
          <div className=' fs-19 mt-35'>{t('delivery')}</div>

          <div className='d-flex mt-14'>
            <RateStars
              className={htmlClasses('s-40 fs-40 mx-7', { 'pe-n': isLoading })}
              rate={deliveryRating}
              onChange={(val) => handleChangeRateInfo(val, 'deliveryRating')}
            />
          </div>

          <div className=' fs-19 mt-24'>{t('productVariety')}</div>

          <div className='d-flex mt-14'>
            <RateStars
              className={htmlClasses('s-40 fs-40 mx-7', { 'pe-n': isLoading })}
              rate={productVarietyRating}
              onChange={(val) => handleChangeRateInfo(val, 'productVarietyRating')}
            />
          </div>

          <div className=' fs-19 mt-24'>{t('productQuality')}</div>

          <div className='d-flex mt-14'>
            <RateStars
              className={htmlClasses('s-40 fs-40 mx-7', { 'pe-n': isLoading })}
              rate={productQualityRating}
              onChange={(val) => handleChangeRateInfo(val, 'productQualityRating')}
            />
          </div>

          <InputText className='input-textarea-feedback mt-30' value={comment}>
            <textarea
              className='input-textarea'
              rows={3}
              placeholder={t('optionalComment')}
              onChange={handleCommentChange}
              value={comment}
              maxLength={250}
              disabled={isLoading}
            />
          </InputText>
        </>
      )}

      {/* TODO: JIF-2675: should be uncomment when backend will be able to provide truly information about earned bonuses in tips
        orderStore.activeOrderBonuses && (
        <div className="d-flex align-items-start py-12 px-13 mt-22 br-8 border-tf2 mb-24">
          <div className="fw-500 c-text lh-20 fs-14">{t('ratePopover:companyPointsFrom')}</div>
          <div className="ml-auto c-black fs-14 lh-20 fw-500 d-flex">
            <div className="icon icon-rtl icon-jiffy-point c-black s-19 fs-19 d-flex flex-center mr-7" />

            {orderStore.activeOrderBonuses}
          </div>
        </div>
      )
      */}

      {isShowTip && <ChooseTipAmount />}

      <div className='d-flex align-items-center justify-content-end mx-n4 mt-30'>
        <button
          className='button _primary w-100p mx-4 flex-shrink-1'
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? <span className='spinner' /> : t(
            orderStore.tipAmount || (!isShowSecondForm && rating !== -1) ? 'continue' : 'submit')}
        </button>
      </div>
    </Popover>
  );
});

const RateStars = observer(({ rate, onChange, className }: RateStarsProps) => {
  return (
    <>
      {[1, 2, 3, 4, 5].map((item, i) => (
        <div
          className={htmlClasses(
            'icon icon-rtl icon-star d-flex flex-center rate-star',
            className,
            { _active: i < rate },
          )}
          key={i}
          onClick={() => onChange(item)}
        />
      ))}
    </>
  );
});

const ChooseTipAmount = observer(() => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [customTip, setCustomTip] = useState('');
  const values = ['1', '2', '3', '4'];
  const handleSetTip = (val: string) => {
    orderStore.setTipAmount(orderStore.tipAmount === val ? '' : val);
  };
  const handleChangeCustomTip = (value: string, mask: AnyMasked) => {
    let val = value.slice(1);
    if (val) val = mainStore.toFloat(val).toString();
    setCustomTip(val);
    orderStore.setTipAmount(val);
    if (!val) mask.value = '';
  };
  const handleBlurCustomTip = (e: ChangeEvent<HTMLInputElement>) => {
    let val = e.currentTarget.value.slice(1);
    if (val) val = mainStore.toFloat(val).toString();
    setCustomTip(val);
    orderStore.setTipAmount(val);
  };
  const handleFocusCustomTip = () => {
    if (customTip) orderStore.setTipAmount(customTip);
  };

  useEffect(() => {
    setCustomTip('');
    //eslint-disable-next-line
  }, [mainStore.isRateOrderPopover]);

  useEffect(() => {
    if (!orderStore.tipAmount) {
      setActiveIndex(-1);
      return;
    }
    if (customTip && orderStore.tipAmount === customTip) {
      setActiveIndex(values.length);
      return;
    }
    for (let i = 0; i < values.length; i++) {
      if (orderStore.tipAmount === values[i]) {
        setActiveIndex(i);
        break;
      }
    }
    //eslint-disable-next-line
  }, [orderStore.tipAmount]);

  return (
    <>
      <div className='fs-21  mt-30'>{t('ratePopover:addTip')}</div>
      <div className='d-flex pt-8 mt-8'>
        {values.map((value, i) => (
          <div
            className={htmlClasses('checkout-instruction flex-shrink-0', {
              _active: i === activeIndex,
            })}
            onClick={() => handleSetTip(value)}
            key={i}
          >
            <div className='icon icon-rtl icon-check-box checkout-instruction__check s-18 d-flex flex-center flex-shrink-0 rounded-circle c-white fs-16' />
            <div>{mainStore.addCurrencySymbol(value)}</div>
          </div>
        ))}
        <div
          className={htmlClasses('checkout-instruction flex-shrink-0 p-0', {
            _active: activeIndex === values.length,
          })}
        >
          <div className='icon icon-rtl icon-check-box checkout-instruction__check s-18 d-flex flex-center flex-shrink-0 rounded-circle c-white fs-16' />
          <IMaskInput
            className='input-text w-85 text-center px-14 h-40'
            mask={'`' + orderStore.currencySymbol + '#[.00]'}
            definitions={{ '#': /[1-9]/ }}
            value={customTip}
            type='tel'
            placeholder={t('custom')}
            onAccept={handleChangeCustomTip}
            onBlur={handleBlurCustomTip}
            onFocus={handleFocusCustomTip}
          />
        </div>
      </div>
    </>
  );
});
