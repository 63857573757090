import { useEffect } from 'react';
import { userStore } from '../stores/UserStore';
import { mainStore } from '../stores/MainStore';
import { company } from '../company/Company';

const EL_SCRIPT_ID = 'Freshchat-js-sdk';
let isReady = false;
let prevAuthorizeState: boolean | null = null;

export const useFCWidget = () => {
  const initFCWidget = () => {
    if (!window.fcWidget) return;

    window.fcWidget && window.fcWidget.close();
    window.fcWidget && window.fcWidget.hide();

    if (userStore.isAuthorized) setFCWidgetUserProperties();
  };
  const setFCWidgetUserProperties = () => {
    if (!userStore.isAuthorized || !window.fcWidget) return;
    if (userStore.personalData.phone) window.fcWidget.setExternalId(userStore.personalData.phone);
    window.fcWidget.user.setProperties({
      firstName: userStore.personalData.firstName || undefined,
      lastName: userStore.personalData.lastName || undefined,
      email: userStore.personalData.email || undefined,
      phone: userStore.personalData.phone || undefined,
      locale: company.config.apis.freshChat.locale || 'en',
    });
  };
  const placeFCWidget = () => {
    if (
      company.isDisableFreshChat ||
      document.getElementById(EL_SCRIPT_ID) ||
      !company?.config?.apis?.freshChat
    ) return;

    const script = document.createElement('script');
    script.id = EL_SCRIPT_ID;
    script.src = company?.config?.apis?.freshChat?.url || '';
    script.setAttribute('chat', 'true');
    if (company?.config?.apis?.freshChat?.widgetId) script.setAttribute('widgetId', company.config.apis.freshChat.widgetId);
    script.onload = initFCWidget;
    document.head.appendChild(script);
  };

  useEffect(() => {
    if (!mainStore.isAllStoresSynchronized) return;
    prevAuthorizeState = userStore.isAuthorized;
  }, [mainStore.isAllStoresSynchronized]);

  useEffect(() => {
    if (!isReady) {
      isReady = true;
      placeFCWidget();
    } else {
      if (
        !window.fcWidget ||
        prevAuthorizeState === null ||
        !mainStore.isAllStoresSynchronized ||
        userStore.isAuthorized === prevAuthorizeState
      ) return;
      prevAuthorizeState = userStore.isAuthorized;
      if (!userStore.isAuthorized) {
        window.fcWidget.close();
        window.fcWidget.hide();
        window.fcWidget.user.clear().catch(() => void 0);
      }
    }
  }, [mainStore.isAllStoresSynchronized, userStore.isAuthorized]);

  useEffect(() => {
    if (!window.fcWidget || !mainStore.isAllStoresSynchronized) return;
    setFCWidgetUserProperties();
  }, [mainStore.isAllStoresSynchronized, JSON.stringify(userStore.personalData)]);
};
