import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { mainStore } from '../stores/MainStore';
import { Path, LocationDescriptorObject } from 'history';
import { Link } from 'react-router-dom';

type ExternalLinkProps = {
  className: string;
  href: string;
  children: ReactNode;
  to?: Path | LocationDescriptorObject;
  targetBlank?: boolean;
  onClick?: () => void;
};

export default observer(
  ({ className, href, children, to, targetBlank, onClick }: ExternalLinkProps) => {
    const handleOnClick = () => {
      mainStore.sendToRN('openInAppBrowser', { url: href });
      onClick?.();
    };

    return (
      <>
        {window.ReactNativeWebView ? (
          <span
            className={className}
            onClick={handleOnClick}
          >
            {children}
          </span>
        ) : to !== undefined ? (
          <Link className={className} to={to} onClick={handleOnClick}>
            {children}
          </Link>
        ) : (
          <a
            className={className}
            onClick={handleOnClick}
            href={href}
            target={targetBlank ? '_blank' : undefined}
            rel="noreferrer"
          >
            {children}
          </a>
        )}
      </>
    );
  });
