import { RequestAPI } from './Requests';
import { ENDPOINT } from './constants';

export interface ETACalculation {
  cost: Partial<Record<ETADeliveryMethodType, ETACost>>;
  paymentMethods: Partial<Record<ETADeliveryMethodType, ETAPaymentMethod[]>>;
  distance: number;
  duration: {
    min: number;
    max: number;
    range: string;
  };
  highDemand: boolean;
  meta: {
    todayAsIndex: number;
    serverTime: string;
  };
  warehouse: ETAWarehouse;
}

interface ETAWarehouse {
  id: string;
  code: string;
  address: string;
  location: {
    latitude: number;
    longitude: number;
  };
  availability: {
    availableNow: boolean;
    closing: string;
    nextAvailabilityDay: number;
    opening: string;
  };
  deliveryMethods: ETADeliveryMethod[];
}

export enum ETAPaymentMethod {
  Card = 'CARD',
  Cash = 'CASH',
}

interface ETADeliveryMethod {
  cost: ETACost,
  type: ETADeliveryMethodType,
  sellers: string[];
  paymentMethods: ETAPaymentMethod[];
}

export enum ETADeliveryMethodType {
  JiffyDelivery = 'JIFFY_DELIVERY',
  ClickAndCollect = 'CLICK_AND_COLLECT',
}

export interface ETACost {
  shipping: number;
  threshold: number;
  serviceFee: number;
  serviceFeeThreshold: number;
  shippingPounds: string;
  thresholdPounds: string;
  serviceFeeShippingPounds: string;
  serviceFeeThresholdPounds: string;
}

interface ETAResponse {
  data: ETACalculation;
}

interface DeliveryCostResponse {
  shipping: number;
  threshold: number;
  ttl: string;
}

interface ETARequestsInterface {
  getETA(data: { pos: string; seller: string; }): Promise<ETAResponse>;

  getDeliveryCost(data: {
    wh_code: string,
    latitude: string | number,
    longitude: string | number,
    seller: string,
    force: boolean,
    delivery_type: number,
  }): Promise<DeliveryCostResponse>;
}

export const ETARequests: ETARequestsInterface = {
  getETA: (data) =>
    RequestAPI.get(ENDPOINT.eta.get, data),

  getDeliveryCost: (data) =>
    RequestAPI.post(ENDPOINT.eta.getDeliveryCost, data),
};
