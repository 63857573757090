import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import Popover from '../components/Popover';
import { userStore } from '../stores/UserStore';
import { mainStore } from '../stores/MainStore';

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isShowPopoverRemoveData, setIsShowPopoverRemoveData] = useState(false);
  const handleLogout = async () => {
    try {
      await userStore.logout().catch((error) => error && console.error(error));
      history.replace('/');
    } catch (e) {
      console.error(e);
    }
  };
  const handlePopoverRemoveDataDismiss = () => setIsShowPopoverRemoveData(false);
  const handleDeleteProfile = async () => {
    try {
      const isDeletedSuccessfully = await userStore.deleteProfile();
      if (isDeletedSuccessfully) {
        handleLogout().catch(() => undefined);
        mainStore.pushAlert('success', t('alertDeleteProfile'));
        mainStore.sendToRN('logAmplitudeEvent', {
          name: 'Profile: deleted',
        });
        mainStore.sendToRN('firebaseAnalytics', {
          name: 'profile_deleted',
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    userStore.requestPersonalData().catch((error) => error && console.error(error));
  }, []);

  return (
    <>
      <PageHeader title={t('myProfile')} />
      <div className="scroll-layout h-100p pt-12 px-24">
        <div className="fs-14 lh-22">{t('personalData')}</div>
        <div className="d-flex mt-12 align-items-start justify-content-between fs-14 ">
          <div className="c-gray mie-24 text-nowrap">{t('phone')}</div>
          <div className="text-right ltr">
            {mainStore.prettyPhoneNumber(userStore.personalData.phone || '') || t('unknown')}
          </div>
        </div>
        <div className="d-flex mt-12 align-items-start justify-content-between fs-14 ">
          <div className="c-gray mie-24 text-nowrap">{t('yourName')}</div>
          <div className="text-right">{userStore.fullName || t('unknown')}</div>
        </div>
        <div className="d-flex mt-12 align-items-start justify-content-between fs-14 ">
          <div className="c-gray mie-24 text-nowrap">{t('email')}</div>
          <div className="text-right">{userStore.personalData.email || t('unknown')}</div>
        </div>
        <div
          className="fs-14  c-blue mt-20"
          onClick={() => setIsShowPopoverRemoveData(true)}
        >
          {t('profilePage:deletePersonalData')}
        </div>
        <div className="h-1 my-16 c-bg-tf2" />
        <div className="fs-14  c-blue" onClick={handleLogout}>
          {t('logout')}
        </div>
      </div>

      <Popover isShow={isShowPopoverRemoveData} onBackdropDismiss={handlePopoverRemoveDataDismiss}>
        <h2>{t('profilePage:deletePersonalData')}</h2>
        <div className="fs-14 lh-20 mt-20  c-mgray">{t('profilePage:warning')}</div>
        <div className="d-flex align-items-center justify-content-end mt-20">
          <div
            className="h-40 mie-20 px-10 lh-40 c-blue fs-14 fw-500"
            onClick={handlePopoverRemoveDataDismiss}
          >
            {t('cancel')}
          </div>
          <button
            className="button _primary w-180 h-40 fs-14"
            onClick={handleDeleteProfile}
          >
            {t(`profilePage:deletePersonalAccount`)}
          </button>
        </div>
      </Popover>
    </>
  );
});
