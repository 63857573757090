import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IllustrationGeneralError from '../assets/img/general_error.svg';
import PopoverZoom from './PopoverZoom';

type PopoverGeneralErrorProps = {
  isShow: boolean;
  errorType: string;
  onDismiss?: () => void;
  onRetry?: () => Promise<any>;
};

type ErrorContent = {
  src: string;
  title: string;
  text: string;
  buttonText: string;
};

export default observer(({ isShow, onDismiss, errorType, onRetry }: PopoverGeneralErrorProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const setErrorContent = (): ErrorContent => {
    switch (errorType) {
      case 'accessDenied':
        return {
          src: IllustrationGeneralError,
          title: t('errors:oldDeviceTitle'),
          text: t('errors:oldDeviceText'),
          buttonText: '',
        };
      case 'internet':
        return {
          src: IllustrationGeneralError,
          title: t('errors:noInternetTitle'),
          text: t('errors:unableToLoadPage'),
          buttonText: t('errors:retry'),
        };
    }
    return {
      src: IllustrationGeneralError,
      title: t('errors:oops'),
      text: t('errors:tryRefreshingPage'),
      buttonText: t('close'),
    };
  };
  const handlerDismiss = () => {
    if (onDismiss) {
      onDismiss();
      return;
    }
    if (!onRetry) return;
    setIsLoading(true);
    onRetry().catch(() => {
      setIsLoading(false);
    });
  };
  const content = setErrorContent();

  return (
    <>
      {errorType !== 'accessDenied' && <img className="d-none" src={content.src} alt="" />}
      <PopoverZoom isShow={isShow}>
        <div className="popover-general-error">
          <div className="px-24 h-100p d-flex flex-direction-column justify-content-center overflow-auto">
            <img className="d-block mx-auto flex-shrink-0" src={content.src} alt="" />
            <div className="fs-32 lh-36 pt-40 text-center">{content.title}</div>
            <div className="fs-14 lh-19 mt-16 text-center">{content.text}</div>
          </div>
          {content.buttonText.length > 0 && (
            <button
              className="button _primary flex-shrink-0 mt-24"
              onClick={handlerDismiss}
              disabled={isLoading}
            >
              {isLoading ? <span className="spinner" /> : content.buttonText}
            </button>
          )}
          <div className="h-24 flex-shrink-0" />
        </div>
      </PopoverZoom>
    </>
  );
});
