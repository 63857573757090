import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Popover from './Popover';
import { mainStore } from '../stores/MainStore';

export default observer(() => {
  const { t } = useTranslation();
  const onDismiss = () => mainStore.setIsShowGiftAlreadyReceivedPopover(false);

  return (
    <Popover isShow={mainStore.isShowGiftAlreadyReceivedPopover} onBackdropDismiss={onDismiss}>
      <div className="fs-21 fw-500">{t('cartPage:sweetHarmful')}</div>
      <div className="fs-14 lh-20 mt-20">{t('cartPage:alreadyReceivedGift')}</div>
      <div className="button _primary w-100p mt-32" onClick={onDismiss}>
        {t('understood')}
      </div>
    </Popover>
  );
});
