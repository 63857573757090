import { SearchCatsResponse } from '../../../api/Catalog';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { catalogStore } from '../../../stores/CatalogStore';
import { SEARCH_CATEGORY_STANDARD_LENGTH } from '../../../config';

interface CategoriesSuggestionProps {
  showHistory: boolean;
  onSelectCategory: (category: SearchCatsResponse) => void;
}

export default observer(({ showHistory = false, onSelectCategory }: CategoriesSuggestionProps) => {
    const { t } = useTranslation();
    const [showAll, setShowAll] = useState(false);

    const handleToggle = () => {
      setShowAll((prev) => !prev);
    };

    if (showHistory) return <></>;

    if (!catalogStore.searchCatsList.length) return <></>;

    return (
      <div className="mx-n8 mb-24 overflow-hidden">
        {catalogStore.searchCatsList
          .slice(0, showAll ? undefined : SEARCH_CATEGORY_STANDARD_LENGTH)
          .map((item, i) => (
            <React.Fragment key={i}>
              <div
                className="d-flex align-items-center fs-14"
                onClick={() => onSelectCategory(item)}
              >
                <span className="text-nowrap">{item.name}</span>
                {item.parentCatName && (
                  <span className="ml-2 c-gray text-truncate">
                    {'• '}
                    {item.parentCatName}
                  </span>
                )}
              </div>
              <div className="h-1 c-bg-tf2 my-12" />
            </React.Fragment>
          ))}
        {catalogStore.searchCatsList.length > SEARCH_CATEGORY_STANDARD_LENGTH && (
          <div className="text-center c-blue fs-14" onClick={handleToggle}>
            {t(`show${showAll ? 'Less' : 'More'}`)}
          </div>
        )}
      </div>
    );
  },
);
