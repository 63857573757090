export enum ProductBadgeType {
  DiscountPercent = 'discountPercent',
  DiscountQuantity = 'discountQuantity',
  Trademark = 'trademark',
  Bakery = 'bakery',
  Bundle = 'bundle',
  LimitedStock = 'limited_stock',
}


