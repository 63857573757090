import { RequestAPI } from './Requests';
import { ENDPOINT } from './constants';

export interface AutocompleteSuggestion {
  address: string;
  postcode: string;
  location: {
    latitude: number;
    longitude: number;
  };
  country?: string;
  city?: string;
  place_id: string | null;
}

export interface Address {
  city: string;
  comment: string | null;
  customer_id: string;
  id: string;
  instructions: string[] | null;
  latitude: number;
  longitude: number;
  place_id: string;
  postcode: string;
  street_address_1: string | null;
  street_address_2: string | null;
}

interface AddressesResponse {
  data: Address[];
}

interface DeliveryZoneWarehouse {
  code: string;
  id: string;
}

interface DeliveryZoneSegment {
  start: {
    latitude: number;
    longitude: number;
  };
  end: {
    latitude: number;
    longitude: number;
  };
}

interface DeliveryZone {
  name: string;
  color: string;
  priority: number;
  polygon: {
    segments: DeliveryZoneSegment[];
  };
}

export interface DeliveryZoneCoverage {
  warehouse: DeliveryZoneWarehouse;
  zones: DeliveryZone[];
}

interface DeliveryZonesResponse {
  data: {
    coverages: DeliveryZoneCoverage[];
  };
}

export interface AddAddressRequest {
  street_address_1: string;
  city: string;
  postcode: string;
  latitude: number;
  longitude: number;
  country: string;
  street_address_2?: string;
  comment?: string;
  instructions?: string[];
}

export interface UpdateAddressRequest {
  city?: string;
  comment?: string;
  instructions?: string[];
  latitude?: number;
  longitude?: number;
  postcode?: string;
  street_address_1?: string;
  street_address_2?: string;
}

export interface AutocompleteRequest {
  sessionToken: string;
  input: string;
  placeId: string;
}

export interface AutocompletePrediction {
  description: string;
  place_id: string;
}

export interface Location {
  latitude: number;
  longitude: number;
}

export interface AutocompletePlace {
  address: string;
  postcode: string | null;
  location: Location;
}

export interface AutocompleteResponse {
  data: {
    result: AutocompletePlace | null;
    predictions: AutocompletePrediction[];
  };
}

export const DeliveryRequests = {
  autocomplete: (data: AutocompleteRequest): Promise<AutocompleteResponse> =>
    RequestAPI.post(ENDPOINT.deliveryAddress.autocomplete, data),

  fetchDeliveryZones: (): Promise<DeliveryZonesResponse> =>
    RequestAPI.get(ENDPOINT.deliveryAddress.zones.request),

  getAddresses: (): Promise<AddressesResponse> => RequestAPI.get(ENDPOINT.customer.address.request),

  addAddress: (data: AddAddressRequest): Promise<{ data: Address }> =>
    RequestAPI.post(ENDPOINT.customer.address.add, data),

  updateAddress: (id: string, data: UpdateAddressRequest): Promise<AddressesResponse> =>
    RequestAPI.patch(ENDPOINT.customer.address.update + id, data),

  deleteAddress: (id: string): Promise<AddressesResponse> =>
    RequestAPI.delete(ENDPOINT.customer.address.delete + id),
};
