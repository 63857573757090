import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import React, { useRef, useEffect } from 'react';
import { userStore } from '../../../stores/UserStore';
import ExternalLink from '../../../components/ExternalLink';
import jiffyPointsTc from '../../../assets/img/jiffy-points-tc.png';
import { Link } from 'react-router-dom';
import htmlClasses from 'html-classes';
import { format } from 'date-fns';
import { HAS_BONUSES_DETAILS } from '../constants';
import { orderStore } from '../../../stores/OrderStore';
import { company } from '../../../company/Company';

const PointsReceived = observer(() => {
  const { t } = useTranslation();
  const { isObservableElementVisible, stopObserver, runObserver } = useIntersectionObserver();
  const observedElement = useRef(null);
  const page = useRef(1);
  const fetchTransactionsItems = (page_: number, reset?: boolean) => {
    if (reset) page.current = 1;

    userStore
      .requestBonusesTransactions(page_, reset)
      .then(total => {
        if (total === 0 || total <= userStore.bonusesTransactionsList.length) {
          stopObserver();
        }
      })
      .catch((error) => error && console.error(error));
  };

  useEffect(() => {
    fetchTransactionsItems(page.current, true);
  }, []);

  useEffect(() => {
    if (observedElement.current === null) return;

    runObserver(observedElement);
  }, [observedElement.current]);

  useEffect(() => {
    if (!isObservableElementVisible) return;

    page.current += 1;
    fetchTransactionsItems(page.current);
  }, [isObservableElementVisible]);

  return (
    <>
      <div className="c-bg-corange h-min-124 br-8 mx-24 d-flex justify-content-between flex-direction-column align-items-start mb-24">
        <div className="d-flex justify-content-between w-100p">
          <div className="c-white pl-18 pt-12 lh-19 flex-grow-1">
            {t('pointsPage:yourWallet')}
          </div>

          <ExternalLink
            className="jiffy-points__terms"
            to={{ pathname: '/inject', state: { page: 'terms' } }}
            href={company.config.links.terms}
          >
            <img src={jiffyPointsTc} alt="" className="h-100p w-100 d-block" />
          </ExternalLink>
        </div>

        <div className="d-flex align-items-center pl-18 pb-15">
          <div className="icon icon-rtl icon-jiffy-point s-35 fs-35 d-flex flex-center c-white mr-12" />
          <div className="fs-40 c-white lh-40">
            {userStore.bonuses}
          </div>
        </div>
      </div>

      <div className="scroll-list _24 hide-scroll-bar h-min-120">
        {HAS_BONUSES_DETAILS.map((item, i) => (
          <div className="jiffy-points__list-item" key={i}>
            <div className="icon__number s-22 mb-9">{i + 1}</div>
            <div className="mb-4 lh-19 c-black">
              {t(`pointsPage:${item.firstLine}`)}
            </div>
            <div className="lh-14 c-black fs-12">
              {t(
                `pointsPage:${item.secondLine}`,
                {
                  amount: company.config.points?.pointsPerUnit,
                  currencySymbol: orderStore.currencySymbol,
                  companyName: company.config.name,
                },
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="mx-24 fs-16 lh-24 mb-16">
        {t('pointsPage:transactionHistory')}
      </div>
      <div className="flex-shrink-1">
        {userStore.bonusesTransactionsList.map((item) => (
          <Link
            className={htmlClasses('mx-24 py-16 border-teb br-8 mb-8 d-flex align-items-start', {
              'border-semantic-success': item.type === 'add',
              'border-semantic-error': item.type === 'charge',
              'pe-n': !item.order_id,
            })}
            to={`/order/${item.order_id}`}
            key={item.id}
          >
            <div className="ml-12 fs-14 c-black mr-16 d-flex align-items-center">
              <div className="icon icon-rtl icon-jiffy-point s-20 fs-20" />
              <div className="ml-4 lh-24 h-24">
                {item.type === 'charge' ? '-' : '+'}
                {item.amount}
              </div>
            </div>
            <div>
              <div className="ff-manrope c-black lh-19 fs-14">
                {t(`pointsPage:${item.reason}`)}
              </div>

              {item.created_at && (
                <div className="ff-manrope c-mgray lh-14 fs-12">{format(
                  new Date(item.created_at), 'dd.MM.yyyy')}</div>
              )}
            </div>
            {item.order_id && (
              <div className="ml-auto mr-16 d-flex align-items-center align-self-center">
                <div className="icon icon-rtl icon-chevron-right s-12 d-flex flex-center fs-10 c-black" />
              </div>
            )}
          </Link>
        ))}
      </div>
      <div ref={observedElement} />
    </>
  );
});

export default PointsReceived;
